import React from 'react'
// import { EpochRow } from './EpochRow'
import Tag from 'antd/es/tag'
import dayjs from 'dayjs'
import { formatNumberToCurrency } from 'src/utils/formatNumber'


export const EpochsExpanded = ({ epochs }) => {
    return (
        <div className="last:text-blue-600">
            {epochs.map((e, index) => (
                <div
                    key={e.id}
                    className={` ${
                        index === 0 ? 'pt:0' : 'pt-3'
                    } ${index === epochs.length -1 ? 'pb-0' : 'pb-3'} last:border-0 border-b border-palette-gray-500`}
                >
                    <div className=" mb-4 flex justify-between">
                        <Tag color="#716235" className="mb-3">
                            {/* Epoch {epochs[0].id} */}
                            Epoch {e.id}
                        </Tag>
                        <div className="text-gray-100 font-oxanium font-medium text-sm">
                            <span className="text-palette-gray-700 font-semibold text-xs mr-2">
                                ENDS ON
                            </span>
                            {dayjs(e.periodEnd).format('MMM DD,YYYY')}
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="pr-2 flex flex-col flex-1">
                            <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                                Initial Amount
                            </div>
                            <div className="tracking-tighter font-medium font-oxanium text-palette-gray-100 mt-auto text-base leading-none">
                                {formatNumberToCurrency(e.initialAmount)}
                            </div>
                        </div>
                        <div className="flex flex-col pr-4 flex-1 border-l-2 pl-6 border-[#2B2B2B]">
                            <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                                Actual Balance
                            </div>
                            <div className="text-[#B2955F] text-base font-medium font-oxanium mt-auto leading-none">
                                {formatNumberToCurrency(
                                    e.initialAmount + e.epochPnl
                                )}
                                <span className="text-xs leading-none">
                                    {' '}
                                    USD
                                </span>{' '}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
