/* eslint-disable no-unused-vars */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/store/services/axiosConfig";

export const epochQuantitySelect = createAction('customer/epochQuantity')

export const getCustomerEpochBalances = createAsyncThunk(
    'customer/getEpochBalances',
    async ({ epochQuantity }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('epochs/customerepochsbalance/', {
                quantity: Number(epochQuantity),
            })
            return res.data.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)
        }
    }
)