import { useCallback, useEffect } from 'react'
import { useContractFunction } from '@usedapp/core'
import {
    useERC721StakingContract,
    useERC721IgniiteContract,
} from '../useContract'
import {
    STAKING_ADDRESS,
    NFT_ADDRESS_IGNIITE,
} from '../../../constants/addressConstants'
import { sleep } from './../../../utils/promise'

import useIsApprovedForAll from './useIsApprovedForAll'

const useStake = (tokenId) => {
    const contract = useERC721StakingContract(STAKING_ADDRESS)
    const ignContract = useERC721IgniiteContract(NFT_ADDRESS_IGNIITE)

    const isApprovedForAll = useIsApprovedForAll(
        NFT_ADDRESS_IGNIITE,
        STAKING_ADDRESS
    )

    const {
        state: stateApprove,
        send: sendApprove,
        resetState: resetApprove,
    } = useContractFunction(ignContract, 'setApprovalForAll')

    const {
        state: stateStake,
        send: sendStake,
        resetState: resetStake,
    } = useContractFunction(contract, 'stake')

    const approveAll = useCallback(async () => {
        try {
            await sendApprove(STAKING_ADDRESS, true)
        } catch (error) {
            console.log('Error Approve', { error })
        }
    }, [sendApprove])

    const stake = useCallback(async () => {
        try {
            await sendStake(tokenId)
        } catch (error) {
            console.log('Error Stake', { error })
        }
    }, [sendApprove, tokenId])

    const initStake = useCallback(() => {
        if (!isApprovedForAll) {
            approveAll()
        } else {
            stake()
        }
    }, [approveAll, stake, isApprovedForAll])

    const resetState = useCallback(() => {
        resetApprove()
        resetStake()
    }, [])

    useEffect(() => {
        const _stake = async () => {
            await sleep(600)
            stake()
        }
        if (stateApprove.status === 'Success') {
            _stake()
        }
    }, [stateApprove])

    return { initStake, stateStake, stateApprove, resetState }
}

export default useStake
