import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDebounce from '../../../hooks/useDebounce'
import {
    getWalletBalance,
    placeOrder,
    setLeverage,
    changeCross,
} from './exchange.actions'

const selectSelf = (state) => state.exchangeReducer

export const useExchange = () => {
    return useSelector(selectSelf)
}

export const useGetBalance = (keyId) => {
    const dispatch = useDispatch()

    const fetch = useCallback(() => {
        if (keyId > 0) dispatch(getWalletBalance(keyId))
    }, [dispatch, keyId])

    useDebounce(
        () => {
            fetch()
        },
        1000,
        [keyId]
    )
}

export const usePlaceOrder = () => {
    const dispatch = useDispatch()

    return useCallback((data) => dispatch(placeOrder(data)), [dispatch])
}

export const useSetLeverage = () => {
    const dispatch = useDispatch()

    return useCallback((data) => dispatch(setLeverage(data)), [dispatch])
}

export const useChangeCross = () => {
    const dispatch = useDispatch()
    return useCallback(
        ({ keyId, isIsolated, symbol, longLeverage, shortLeverage }) =>
            dispatch(
                changeCross({
                    keyId,
                    isIsolated,
                    symbol,
                    longLeverage,
                    shortLeverage,
                })
            ),
        [dispatch]
    )
}
