import React from 'react'
import Stats5 from 'src/components/DataComponents/Stats5'

import {
    useFetchReferralhUserTrades,
    useReferralUserTradesState,
} from 'src/store/reducers/referralUserTradesReducer/referralUserTrades.hooks'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from 'src/components/Buttons/Button'
import { TbArrowLeft } from 'react-icons/tb'
import TableTrades from 'src/components/AdminComponents/TableTrades'
import TableCloseTrades from 'src/components/AdminComponents/TableCloseTrades'
import Stats from '../../components/DataComponents/Stats'
import { convertToUSD } from 'src/utils/formatter'

const UserInfo = ({ userName, userEmail }) => {
    return (
        <div className="bg-palette-black-800 px-4 pt-5 pb-8">
            <div className="flex flex-row">
                <div>
                    <div className="text-palette-gray-700 text-xs font-semibold">
                        USERNAME:
                    </div>
                    <div className="text-palette-brown-901 text-2xl font-bold font-oxanium uppercase">
                        {userName}
                    </div>
                </div>
                <div className="ml-auto"></div>
            </div>
            <div className="flex flex-row items-center mt-3">
                <div className="mr-4 ont-semibold text-xs text-palette-gray-700">
                    Email
                </div>
                <div className="bg-palette-black-725 px-4 py-2 text-palette-gray-100 font-semibold text-xs">
                    {userEmail}
                </div>
            </div>
        </div>
    )
}

const ReferralsTrades = (props) => {
    const params = useParams()
    useFetchReferralhUserTrades(params?.idUser)
    const {
        pnl,
        profitFee,
        closedTrades,
        activeTrades,
        userUsername,
        userEmail,
    } = useReferralUserTradesState()
    const location = useLocation()
    const navigation = useNavigate()

    return (
        <div className="w-full flex flex-col gap-3 xl:gap-5">
            <div>
                <Button
                    variant="light"
                    onClick={() => {
                        if (location.pathname.includes('admintrader')) {
                            navigation('/trader/admin')
                        } else {
                            navigation('/admin')
                        }
                    }}
                >
                    <TbArrowLeft
                        size={'1rem'}
                        className="text-palette-brown-901 mr-1"
                    />
                    BACK
                </Button>
                <div className="flex flex-row gap-4 w-full mt-2">
                    <div className="flex flex-row w-full  overflow-x-auto gap-8">
                        <div className="w-3/12">
                            <UserInfo
                                userEmail={userEmail}
                                userName={userUsername}
                            />
                        </div>
                        <div className="flex flex-row gap-4 w-9/12">
                            <Stats
                                title={'Balance'}
                                value={convertToUSD(pnl)}
                            />
                            <Stats5
                                title={'PNL'}
                                value={Number(pnl)}
                                hidePercent
                            />
                            <Stats5
                                title={'Profit Fee'}
                                value={Number(profitFee)}
                                hidePercent
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="text-palette-gray-100 font-medium text-sm">
                    ACTIVE TRADES
                </div>
                <TableTrades dataSource={activeTrades} />
            </div>
            <div>
                <div className="text-palette-gray-100 font-medium text-sm">
                    CLOSE TRADES
                </div>

                <TableCloseTrades dataSource={closedTrades} />
            </div>

            <div></div>
            <div></div>
        </div>
    )
}

export default ReferralsTrades
