import { useMemo } from 'react'
import {
    useActiveKey,
    useKeysBybit,
} from 'src/store/reducers/dashboardReducer/dashboard.hooks'

const useGetAccount = () => {
    const keysbybit = useKeysBybit()
    const activekey = useActiveKey()

    return useMemo(() => {
        if (keysbybit.length === 0 || activekey === 0) return {}
        return keysbybit?.find((item) => item.id === activekey)
    }, [keysbybit, activekey])
}

export default useGetAccount
