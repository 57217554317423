import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { Switch, Tooltip } from 'antd'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import SubscribeKey from 'src/components/Modals/SubscribeModals/SubscribeKey'

const SwitchSubscribeKey = ({ item }) => {
    const [showModal, setShowModal] = useState(false)

    const keysbybit = useSelector((state) => state.dashboardReducer.keysbybit)

    const enableTrading = useMemo(() => {
        return keysbybit?.find((v) => v.id === item?.id)?.subscribed
    }, [keysbybit])

    return (
        keysbybit?.length > 0 && (
            <div className="flex flex-row gap-3 items-center">
                <div className="text-xs lg:text-sm text-palette-gray-700 font-semibold">
                    {!enableTrading ? 'ACTIVE TRADING' : 'STOP TRADING'}
                </div>
                <SubscribeKey
                    visible={showModal}
                    id={item.id}
                    enableTrading={enableTrading}
                    onClose={() => {
                        setShowModal((state) => !state)
                    }}
                />
                <Switch
                    checked={enableTrading}
                    onChange={() => {
                        setShowModal((state) => !state)
                    }}
                    className="bg-palette-brown-700 ring-1 ring-palette-beigde-200"
                />
                <div className="sm:text-xs md:text-base lg:text-lg text-palette-gray-700 font-semibold hover:cursor-pointer">
                    <Tooltip
                        placement="topLeft"
                        color={'#747474'}
                        title={
                            !enableTrading
                                ? `ACTIVE ${item.name.toUpperCase()}`
                                : `STOP ${item.name.toUpperCase()}`
                        }
                    >
                        <IoIosHelpCircleOutline />
                    </Tooltip>
                </div>
            </div>
        )
    )
}

export default SwitchSubscribeKey
