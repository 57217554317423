import dayjs from 'dayjs'
import React from 'react'

export const EpochStatus = ({ closed, periodEnd }) => {
    if (!closed) {
        return (
            <div className=" px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-xs text-palette-beigde-100">
                Paid
            </div>
        )
    } else {
        if (dayjs() > dayjs(periodEnd)) {
            return (
                <div className=" px-3 py-1 w-fit rounded-full bg-[#702130] font-oxanium text-xs text-palette-beigde-100">
                    Pending
                </div>
            )
        }
        return (
            <div className=" px-3 py-1 w-fit rounded-full bg-[#AEADAD] font-oxanium text-xs text-palette-black-725">
                Active
            </div>
        )
    }
}
