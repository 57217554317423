/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import Button from 'src/components/Buttons/Button'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import AddEpochModal from 'src/views/AdminView/AddEpochModal'
import { BiPlus } from 'react-icons/bi'
import { EpochCard } from 'src/components/EpochsComponents/EpochCard'
import CloseEpochConfirm from 'src/components/EpochsComponents/CloseEpochConfirm'
import PayPeriodicity from 'src/components/EpochsComponents/PayPeriodicity'
import { useDispatch } from 'react-redux'
import { createEpochPayment, createEpochPayPeriodicity, getPayPeriodicity } from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import { notification } from 'antd'

const EpochsAdmin = () => {
    const initDataModalPayPeriodicity = {
        id: 0, 
        periodicity: '',
        epoch_start: '',
        epoch_end: '',
        periodicity_start: '',
        periodicity_end: '',
        users: {}
    }

    const {epochs} = useHistoryTradesState()
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalPayPeriodicity, setVisibleModalPayPeriodicity] = useState(false)
    const [modalPayPeriodicityData, setModalPayPeriodicityData] = useState(initDataModalPayPeriodicity)
    const [epochId, setEpochId] = useState()
    const dispatch = useDispatch()
    const buttonSize = useResponsiveValue({
        base: 'small',
        lg: 'normal',
    })

    const handleEpochClick = (id) => {       
        setVisibleModal(true)
        setEpochId(id)
    }
    const handleEpochPayClick = async (id) => {
        const data = await getPayPeriodicity(id)
        setModalPayPeriodicityData(data)
        setVisibleModalPayPeriodicity(true)
        setEpochId(id)
    }
    const handleConfirm = () => {
        dispatch(createEpochPayment(epochId))
        setVisibleModal(false)
    }

    const handleConfirmPayPeriodicity = async () => {
        const result = await dispatch(createEpochPayPeriodicity(epochId))
        setVisibleModalPayPeriodicity(false)
        if (!isNaN(result.payload))
            notification.success({
                message: 'Paid periodicitity',
                description: 'You successfully paid periodicity',
                placement: 'bottomRight',
            })
        else
            notification.error({
                message: 'Paid periodicitity',
                description: result.payload.message,
                placement: 'bottomRight',
            })
    }

    return (
        <div className="pl-6 h-full overflow-y-auto overflow-x-hidden scroll-generic pb-6 pr-6">
            <CloseEpochConfirm
                visible={visibleModal}
                onClose={() => setVisibleModal((state) => !state)}
                onConfirm={() => handleConfirm()}
                id={epochId}
            />
            <PayPeriodicity
                visible={visibleModalPayPeriodicity}
                onClose={() => setVisibleModalPayPeriodicity((state) => !state)}
                onConfirm={() => handleConfirmPayPeriodicity()}
                data={modalPayPeriodicityData}
            />
            <div className="flex justify-between mb-4">
                <div className="text-palette-gray-100 text-xl font-regular tracking-wider">
                    EPOCHS
                </div>
                <Button
                    size={buttonSize}
                    onClick={() => setVisible(true)}
                    variant="light"
                    className="w-full lg:w-auto text-sm lg:text-lg h-8 "
                >
                    <BiPlus />
                    <div className="font-semibold text-base px-3">ADD</div>
                </Button>
            </div>
            <AddEpochModal
                visible={visible}
                onClose={() => setVisible(false)}
            />

            <div className="grid grid-cols-3 gap-4 auto-cols-fr	">
                {epochs?.map((epoch, index) => (
                    <EpochCard
                        key={index}
                        epoch={epoch}
                        handleEpochClick={handleEpochClick}
                        handleEpochPayClick={handleEpochPayClick}
                    />
                ))}
            </div>
        </div>
    )
}

export default EpochsAdmin