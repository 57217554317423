import { useMemo } from 'react'
import { useCall } from '@usedapp/core'
import { STAKING_ADDRESS } from '../../../constants/addressConstants'
import { useERC721StakingContract } from '../useContract'

const useStakesNFtId = (account) => {
    const contract = useERC721StakingContract(STAKING_ADDRESS)

    const { value } =
        useCall(
            account && {
                contract,
                method: 'stakes',
                args: [account],
            }
        ) ?? {}

    return useMemo(() => {
        return value?.[0] ? Number(parseInt(value?.[0])) : undefined
    }, [value])
}

export default useStakesNFtId
