import {  useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllEpochs,
    getEpochsByUser,
    getTradesHistory,
    getAllTrades,
    addNewEpoch,
    refresh,
    getClientEpochDetails,
    getUserPayments,
} from './historyTrades.actions'
import { YEARLY } from 'src/constants/temporality.constants'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'
import { notification } from 'antd'
import { sleep } from 'src/utils/promise'

const selectSelf = state => state.historyTrades

export const useHistoryTradesState = () => {
    
    return useSelector(selectSelf)
}


export const useGetAllEpochs = () => {
    const dispatch = useDispatch()
    const { refresh } = useHistoryTradesState()
    const fetch = useCallback(async () => {
        try {
            await dispatch(getAllEpochs()).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    useDeepCompareEffect(() => {
        fetch()
    }, [refresh])
}

export const useAddNewEpoch = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const action = useCallback(
        async ({ minimumAmount, periodStart, periodEnd, description, periodicity }) => {
            setLoading(true)
            if (loading) return

            try {
                await sleep(600)
                await dispatch(
                    addNewEpoch({
                        minimumAmount,
                        periodEnd,
                        periodStart,
                        description,
                        periodicity,
                    })
                ).unwrap()
                dispatch(refresh())
                notification.success({
                    message: 'Create new Epoch',
                    description: 'You successfully created anew Epoch',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw error
            } finally {
                setLoading(false)
            }
        },
        [loading, dispatch]
    )
    return { createEpoch: action, loading, error }
}

export const useGetEpochsByUser = () => {
    const dispatch = useDispatch()
    const fetch = useCallback(async () => {
        try {
            await dispatch(getEpochsByUser()).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    useDeepCompareEffect(() => {
        fetch()
    }, [])
}

export const useGetTradesHistory = ({
    temporality = YEARLY,
    rangeDate,
    initialBalance,
}) => {
    const dispatch = useDispatch()
    const {refresh, selectedEpoch} = useHistoryTradesState()

    const fetch = useCallback(async () => {
        try {
            const { startDate, endDate } = rangeDate
            await dispatch(
                getTradesHistory({
                    start: startDate,
                    end: endDate,
                    temporality,
                    initialBalance: initialBalance,
                    selectedEpoch,
                })
            ).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch, temporality, rangeDate])

    useDeepCompareEffect(() => {
        if(selectedEpoch){
            fetch()   
        }
    }, [temporality, rangeDate, refresh, selectedEpoch])
}

export const useGetAllTrades = () => {
    const dispatch = useDispatch()
    const {refresh} = useHistoryTradesState()

    const fetch = useCallback(async () => {
        try {
            await dispatch(getAllTrades()).unwrap()
        } catch (error) {
            console.log(error)
        }
    },[dispatch])
    useDeepCompareEffect(() => {
        fetch()
    }, [refresh])
}

export const useGetClientEpochDetails = () => {
    const dispatch = useDispatch()
    const fetch = useCallback(async () => {
        try {
            await dispatch(getClientEpochDetails())
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    useDeepCompareEffect(() => {
        fetch()
    }, [])
}

export const useGetUserPayments = () => {
    const dispatch = useDispatch()
    const [ data, setData] = useState([])
    const fetch = useCallback(async () => {
        try {
            const res = await dispatch(getUserPayments()).unwrap()
            console.log(res)
            setData(res)
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    useEffect(() => {
        fetch()
    }, [])
    return data
}