import React from 'react'
import ConfirmModal from '../GenericModals/ConfirmModal'

const PasswordUpdate = ({ visible, onClose, onConfirm, loading = false }) => {
    return (
        <ConfirmModal
            visible={visible}
            onClose={onClose}
            onConfirm={onConfirm}
            loading={loading}
        >
            <div className="flex flex-col gap-2 text-center">
                <div className="mx-auto text-xl text-palette-gray-700 font-semibold ">
                    Are you sure you want to update your password?
                </div>
            </div>
        </ConfirmModal>
    )
}

export default PasswordUpdate
