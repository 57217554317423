import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import dayjs from 'dayjs'

export const getStatsAccount = createAsyncThunk(
    'stats/accountdataset',
    async (
        { start, end = dayjs().format('YYYY-MM-DD'), keyId },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/dashboard/accountdataset/`,
                {
                    id: keyId,
                    start,
                    end,
                }
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getStatsAccountAll = createAsyncThunk(
    'stats/allaccountdataset',
    async (
        { start, end = dayjs().format('YYYY-MM-DD') },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/dashboard/cumdataset/`,
                {
                    start,
                    end,
                }
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getWalletsBalance = createAsyncThunk(
    'stats/getWalletsBalance',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/getwalletbalanceallbyuser/`
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
