const STOP_LIMIT = 'StopLimit'
const LIMIT = 'Limit'
const MARKET = 'Market'

export const ORDER_TYPE = { STOP_LIMIT, LIMIT, MARKET }

const CROSS = 'cross'
const ISOLATED = 'isolated'

export const MARGIN_TYPE = { CROSS, ISOLATED }
