/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Drawer } from 'antd'
import SideBar from './LayoutsComponents/SideBar'
import { AiOutlineBell, AiOutlineUser, AiOutlineLogout } from 'react-icons/ai'
import logoIgniite from 'src/assets/images/logo_full.png'
import { TbArrowRight, TbArrowLeft, TbArrowsRightLeft } from 'react-icons/tb'
import { BiMenu } from 'react-icons/bi'
import {
    MdDashboard,
    MdOutlinePeopleOutline,
    MdOutlineSettings,
} from 'react-icons/md'
import useMediaQuery from '../hooks/useMediaQuery'

// import * as actions from 'src/store/reducers/userReducer/paymentStatusReducer'

import classNames from 'classnames'
import LogoutConfirm from './Modals/LogoutConfirm'
import { useIsStaked } from '../store/reducers/auth/auth.slice'

const menus = [
    { menuName: 'HOME', path: '/' },
    { menuName: 'LEARN', path: '/learn' },
    { menuName: 'MINT', path: '/mint' },
    { menuName: 'GET STARTED', path: '/login' },
    { menuName: 'HELP', path: '/help' },
]

const DefaultNavbarMenuItem = ({ path, menuName }) => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div
            className="text-center pt-4 cursor-pointer"
            onClick={() => navigate(path)}
        >
            <span
                className={classNames(
                    'px-2 pb-2  border-transparent hover:border-b-palette-brown-901  border-2 ',
                    {
                        'border-b-palette-brown-901':
                            (location.pathname.includes(path) &&
                                location.pathname !== '/') ||
                            location.pathname === path,
                    }
                )}
            >
                {menuName}
            </span>
        </div>
    )
}

const DefaultNavbarMenu = () => {
    return (
        <div className="flex-row justify-center grid grid-cols-5 gap-2">
            {menus.map((v) => (
                <DefaultNavbarMenuItem
                    key={`default-menu-${v.menuName}`}
                    {...v}
                />
            ))}
        </div>
    )
}

const ContextMenuItem = ({ path, menuName }) => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div
            className=" flex flex-row pl-[25%]  pt-4 cursor-pointer"
            onClick={() => navigate(path)}
        >
            <span
                className={classNames(
                    'px-2 pb-2 border-transparent hover:border-b-palette-brown-901 border-2 ',
                    {
                        'border-b-palette-brown-901':
                            (location.pathname.includes(path) &&
                                location.pathname !== '/') ||
                            location.pathname === path,
                    }
                )}
            >
                {menuName}
            </span>
        </div>
    )
}

const ContextMenu = () => {
    return (
        <div className="text-palette-beigde-100">
            {menus.map((v) => (
                <ContextMenuItem
                    key={`context-menu-item-${v.menuName}`}
                    {...v}
                />
            ))}
        </div>
    )
}

const LeftMenu = () => {
    const [visible, setVisible] = useState(false)
    const handleShowDrawer = () => setVisible((state) => !state)

    return (
        <React.Fragment>
            <div
                className="flex items-center px-2 cursor-pointer text-palette-yellow-800 text-xl"
                onClick={handleShowDrawer}
            >
                <BiMenu />
            </div>{' '}
            <Drawer
                drawerStyle={{ backgroundColor: '#8D793F' }}
                headerStyle={{
                    backgroundColor: '#1A1A1A',
                    border: 0,
                    margin: 0,
                    paddingTop: '1.2rem',
                }}
                bodyStyle={{
                    backgroundColor: '#1A1A1A',
                    color: '8D793F',
                    paddingRight: '2.5rem',
                }}
                title={
                    <button onClick={handleShowDrawer} className=" float-right">
                        <TbArrowLeft
                            size={'1.6rem'}
                            className="text-palette-brown-901"
                        />
                    </button>
                }
                width={320}
                placement="left"
                closable={false}
                onClose={handleShowDrawer}
                visible={visible}
            >
                <ContextMenu />
            </Drawer>
        </React.Fragment>
    )
}

const Brand = () => {
    const navigate = useNavigate()
    return (
        <div className="flex items-center justify-center bg-palette-black-800 h-full mr-2">
            <a onPointerDown={() => navigate('/')} className="h-[50%]">
                <img
                    src={logoIgniite}
                    className="rounded-full h-full"
                    alt="Logo-Igniite"
                />
            </a>
        </div>
    )
}

const DefaultNavbar = ({ acountName, paymentStatusId }) => {
    return (
        <div className="flex w-full h-full mx-auto max-w-[1340px] text-palette-beigde-100">
            <Brand />
            <DefaultNavbarMenu />
        </div>
    )
}

const MobileNavbar = ({ acountName }) => {
    return (
        <div className="flex w-full h-full mx-auto max-w-[1340px]">
            <LeftMenu />
            <Brand />
        </div>
    )
}

const NoLoggedHeader = () => {
    const isMobile = useMediaQuery('(max-width: 1023px)')

    return (
        <header className="flex h-14 bg-palette-black-800 text-palette-beigde-100 text-sm font-medium lg px-3 xl:px-2 2xl:px-0">
            {!isMobile ? <DefaultNavbar /> : <MobileNavbar />}
        </header>
    )
}

export default NoLoggedHeader
