import { createSelector } from '@reduxjs/toolkit'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStartDateTemporality } from '../../../utils/temporalityServices'
import useDebounce from '../../../hooks/useDebounce'
import { CUSTOM, WEEKLY } from 'src/constants/temporality.constants'
import {
    getWalletsBalance,
    getStatsAccountAll,
    getStatsAccount,
} from './stats.actions'

export const useFetchWalletsBalance = () => {
    const dispatch = useDispatch()

    const fetch = useCallback(async () => {
        try {
            await dispatch(getWalletsBalance()).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])

    useEffect(() => {
        fetch()
    }, [])
}

export const useFetchStats = ({
    temporality = WEEKLY,
    account = 'all',
    rangeDate,
}) => {
    const dispatch = useDispatch()

    const fetch = useCallback(async () => {
        try {
            if (temporality === CUSTOM) {
                const [start, end] = rangeDate

                if (account === 'all') {
                    await dispatch(
                        getStatsAccountAll({
                            start: start.format('YYYY-MM-DD'),
                            end: end.format('YYYY-MM-DD'),
                        })
                    ).unwrap()
                } else
                    await dispatch(
                        getStatsAccount({
                            start: start.format('YYYY-MM-DD'),
                            end: end.format('YYYY-MM-DD'),
                            keyId: account,
                        })
                    ).unwrap()
                return
            }
            const start = getStartDateTemporality(temporality)
            if (account === 'all') {
                await dispatch(getStatsAccountAll({ start })).unwrap()
            } else
                await dispatch(
                    getStatsAccount({ start, keyId: account })
                ).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch, temporality, account, rangeDate])

    useEffect(() => {
        fetch()
    }, [])

    useDebounce(
        () => {
            fetch()
        },
        600,
        [temporality, account, rangeDate]
    )
}
const selectSelf = (state) => state.statsReducer

export const statsSelect = createSelector(selectSelf, (self) => self.stats)
export const tradeHistorySelect = createSelector(
    selectSelf,
    (self) => self.tradeHistory
)

export const graphicDataSelect = createSelector(
    selectSelf,
    (self) => self.graphicData
)

export const walletsBalanceDataSelect = createSelector(
    selectSelf,
    (self) => self.walletsBalance
)

export const useStats = () => {
    const stats = useSelector(statsSelect)

    return stats
}

export const useTradeHistory = () => {
    const stats = useSelector(tradeHistorySelect)

    return stats
}

export const useGraphicData = () => {
    const stats = useSelector(graphicDataSelect)

    return stats
}

export const useWalletsBalance = () => {
    const stats = useSelector(walletsBalanceDataSelect)

    return stats
}
