import numeral from 'numeral'

// Create a function that will format a number to a currency format based on the current locale
export const formatNumberToCurrency = (number, format = '0,000.000') => {
    if (!number || number === 0) return `$${formatNumber(0, format)}`
    return `$${formatNumber(number, format)}`
}

// Create a function that will format a number 0,000.00 decimals will be removed if they are 0 and if just 1 decimal is present just 1 will be shown (0,000.0)
export const formatNumber = (number, format = '0,000.000') => {
    if (!number || number === 0) return numeral(0).format(format)
    const maxLength = format.split('.')[1]?.length
    let decimalsLength = number.toString().split('.')[1]?.length
    decimalsLength = decimalsLength > maxLength ? maxLength : decimalsLength

    let $format = '0,000'
    if (decimalsLength === 0 || !decimalsLength) {
        return numeral(number).format($format, Math.floor)
    }
    $format += '.'
    for (let i = 0; i < decimalsLength; i++) {
        $format += '0'
    }

    return numeral(number).format($format, Math.floor)
}

/**
 * Format a number to a percentage and add + or - sign based on the number value
 */
export function formatNumberToPercentage(number, sign = true) {
    if (!number || number === 0) return '0%'

    const formattedNumber = formatNumber(number)
    if(!sign) return `${formattedNumber}%`
    const $sign = number > 0 ? '+' : ''

    return `${$sign}${formattedNumber}%`
}
