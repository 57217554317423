import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
    subscribe,
    unsuscribe,
} from 'src/store/reducers/dashboardReducer/dashboard.actions'

const useSubscribeKey = () => {
    const dispatch = useDispatch()

    const $subscribe = useCallback(
        (value) => dispatch(subscribe(value)),
        [dispatch]
    )

    const $unsubscribe = useCallback(
        (value) => dispatch(unsuscribe(value)),
        [dispatch]
    )

    return { subscribe: $subscribe, unsubscribe: $unsubscribe }
}

export default useSubscribeKey
