import { useCall, useEthers } from '@usedapp/core'
import { useERC721Contract } from '../useContract'

const useIsApprovedForAll = (contractAddress, operator) => {
    const contract = useERC721Contract(contractAddress)
    const { account } = useEthers()

    const { value } =
        useCall(
            account && {
                contract,
                method: 'isApprovedForAll',
                args: [account, operator],
            }
        ) ?? {}

    return !!value?.[0]
}

export default useIsApprovedForAll
