import numeral from 'numeral'
import symbols from 'src/JSONS/symbols.json'

/* eslint-disable no-unused-vars */
export const convertToUSD = (someNumber) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'code',
    }).format(someNumber)
}

export const formatNumberDigits = (someNumber, digits = 2) => {
    return new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: digits,
        useGrouping: false,
    }).format(someNumber)
}

export const formatDate = (someDate) => {
    return new Intl.DateTimeFormat('fr-CA', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format(new Date(someDate))
}

export const formatDate2 = (someDate) => {
    return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).format(new Date(someDate))
}

export function isInteger(n) {
    return Number.isInteger(parseFloat(n))
}

export const removeZerosIfInteger = (value) => {
    const _isInteger = isInteger(value)

    if (_isInteger) {
        const arrValue = value.split('')

        const index = arrValue.findIndex((v) => v > 0)
        return arrValue
            .slice(index, arrValue.length)
            .reduce((acc, v) => acc + v, '')
    }
    return value
    // 1 Si tiene decimales y tiene mas de dos 0 quitar uno
    // 2 Si no tiene decimales y tiene un zero al inicio quitarlo
    // if (hasDecimals) for (let index = 0; index < value.length; index++) {}
}

export const removeExtraDecimals = (value, decimals = 1) => {
    const arrValue = value.split('')

    const index = arrValue.findIndex((v) => v === '.')

    if (index === -1) return value

    if (index + decimals < arrValue.length)
        return arrValue
            .slice(0, index + 1 + decimals)
            .reduce((acc, v) => acc + v, '')

    return value
}

const getExactDecimals = (value = '') => {
    if (value.indexOf('.') === -1) return 2
    const afterDot = value.substring(value.indexOf('.') + 1)

    return afterDot.length
}

const checkIfOnlyZeros = (value, max) => {
    if (value.indexOf('.') === -1) return false
    const afterDot = value.substring(value.indexOf('.') + 1)

    let onlyZeros = true
    for (let index = 0; index < max; index++) {
        const c = afterDot[index]

        if (c && c !== '0') {
            onlyZeros = false
            break
        }
    }
    return onlyZeros
}

const setMoneyFormat = (value, format, min = 2, max = 5) => {
    if (!value) return '0.00'
    let _value = typeof value === 'number' ? value.toString() : value
    const onlyZeros = checkIfOnlyZeros(_value, max)
    if (onlyZeros) _value = Math.floor(Number(_value)).toString()
    let decimalsCount = getExactDecimals(_value)

    if (decimalsCount < min) decimalsCount = min
    if (decimalsCount > max) decimalsCount = max

    return `${format}.${Array(decimalsCount)
        .fill({})
        .reduce((acc) => acc + '0', '')}`
}

export const formatMoneyNumber = (value, min, max) => {
    return numeral(value).format(
        setMoneyFormat(value, '$0,000', min, max),
        Math.floor
    )
}

export const formatAmountBySymbol = (amount, symbol) => {
    const _symbol = symbols.find(
        (s) =>
            s.symbol.toUpperCase() === symbol.toUpperCase() ||
            s.baseCurrency.toUpperCase() === symbol
    )

    if (!_symbol) return numeral(amount).format('$0,000.00')
    return numeral(amount).format(`$${_symbol.format}`, Math.floor)
}

// Create a function that will format the number into a currency format
// Decimals will be added if the number is not an integer
// We will use numeral.js to format the number into a currency format
export const formatCurrency = (value) => {
    if (!value) return '0.00'
    const $value = typeof value === 'number' ? value.toString() : value
    // Check if value has decimals and if it is an integer
    const hasDecimals = $value.indexOf('.') !== -1

    const isInteger = hasDecimals ? false : Number.isInteger(parseFloat($value))

    if (isInteger) return numeral(value).format('$0,000', Math.floor)

    return numeral(value).format('$0,000.00', Math.floor)
}

// Create a function that will format a number 0,000.00 decimals will be removed if they are 0 and if just 1 decimal is present just 1 will be shown (0,000.0)
export const formatNumber = (number) => {
    const decimalsLength = number.toString().split('.')[1]?.length
    let format = '0,000'
    if (decimalsLength === 0 || !decimalsLength) {
        return numeral(number).format(format)
    } else if (decimalsLength === 1) {
        format += '.0'
        return numeral(number).format(format)
    }

    format += '.00'
    return numeral(number).format('0,0.00')
}

/**
 * Format a number to a percentage and add + or - sign based on the number value
 */
export function formatNumberToPercentage(number) {
    if (!number || number === 0) return '0%'

    const formattedNumber = formatNumber(number)
    const sign = number > 0 ? '+' : ''

    return `${sign}${formattedNumber}%`
}
