import ReactPlayer from 'react-player'
// import { registerToken } from './../../../service/walletServices'
import { useNavigate } from 'react-router-dom'

const MintDisplayNft = ({
    nftId,
    image,
    name,
    edition,
    attributes,
    description,
}) => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col lg:flex-row lg:pl-4 py-2">
            <div className="w-full lg:w-4/12 flex flex-col items-center">
                <div className="w-4/6 md:w-2/6 lg:w-full bg-palette-black-700 border-2 border-palette-gray-600 relative before:content-[''] before:block before:pt-[100%] text-white">
                    <div className="absolute inset-0">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            controls={false}
                            playing={true}
                            url={image}
                            playsinline
                            muted={true}
                            loop
                        />
                        {/* <img
                            src={image}
                            alt={image}
                            className="h-full w-full object-contain"
                        /> */}
                    </div>
                </div>
                <div className="uppercase mt-6 w-full font-oxanium font-bold text-xl text-center text-palette-brown-901">
                    {name}
                </div>
            </div>
            <div className="flex flex-col w-full lg:w-8/12 md:px-3 lg:pl-8 lg:pr-6 ">
                <div>
                    <div className="font-medium text-lg text-palette-brown-901">
                        About
                    </div>
                    <p className="mt-4 text-palette-gray-100">{description}</p>
                </div>

                <div className="flex flex-row gap-4 mt-auto">
                    <button
                        onClick={() => navigate('/register')}
                        className="w-64 h-10 text-base border border-palette-brown-901 rounded-sm font-oxanium text-palette-brown-901"
                    >
                        GO TO SIGN UP
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MintDisplayNft
