import { createSlice } from '@reduxjs/toolkit'
import { addToArray, updateArrayPosition } from './../../../utils/arrays'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useMintRequirement } from './mintRequirementsReducer'

export const initialState = []

const mintRequirementsBackUpSlice = createSlice({
    name: 'backupMintRequirements',
    initialState,
    reducers: {
        saveBackUp(state, { payload }) {
            return addToArray(state, payload)
        },
        updateBackUp(state, { payload: { id, data } }) {
            return updateArrayPosition(state, id, data)
        },
    },
})

export const { saveBackUp, updateBackUp } = mintRequirementsBackUpSlice.actions

const selectSelf = (state) => state.mintRequirementsBackup

export const useMintRequirementBackUp = () => {
    return useSelector(selectSelf)
}

export const useSaveBackUp = () => {
    const dispatch = useDispatch()
    const mintR = useMintRequirement()
    const mintRB = useMintRequirementBackUp()

    const _saveBackUp = useCallback(
        (data) => dispatch(saveBackUp(data)),
        [dispatch]
    )
    const _updateBackUp = useCallback(
        (index, data) => dispatch(updateBackUp({ index, data })),
        [dispatch]
    )

    const handleSaveBackUp = useCallback(() => {
        const index = mintRB.findIndex((v) => v.account === mintR.account)
        console.log({ index, mintR })
        if (index !== -1) {
            const backup = mintRB[index]

            if (JSON.stringify(backup) !== JSON.stringify(mintR))
                _updateBackUp(index, mintR)
        } else if (mintR.account) {
            _saveBackUp(mintR)
        }
    }, [mintR, mintRB])

    useEffect(() => {
        handleSaveBackUp()
    }, [mintR])
}

export default mintRequirementsBackUpSlice.reducer
