import React from 'react'
import { Table } from 'antd'
import {
    formatNumber,
    formatNumberToCurrency,
    formatNumberToPercentage,
} from 'src/utils/formatNumber'

const TableTrades = ({ dataSource }) => {
    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
        },
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
        },
        {
            title: 'Leverage',
            dataIndex: 'leverage',
            key: 'leverage',
            render: (v) => <span>{formatNumber(v)}</span>,
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (v) => <span>{formatNumber(v)}</span>,
        },
        {
            title: 'Position Value',
            dataIndex: 'positionValue',
            key: 'positionValue',
            render: (v) => <span>{formatNumberToCurrency(v)}</span>,
        },
        {
            title: 'Entry Price',
            dataIndex: 'entryprice',
            key: 'entryprice',
            render: (v) => <span>{formatNumberToCurrency(v)}</span>,
        },
        {
            title: 'Liq. Price',
            dataIndex: 'liqPrice',
            key: 'liqPrice',
            render: (v) => <span>{formatNumberToCurrency(v)}</span>,
        },
        {
            title: 'PnL',
            render: (_, values) => {
                return (
                    <div
                        className={`${
                            Number(values.pnl) > 0
                                ? 'text-palette-green-500'
                                : 'text-palette-pink-700'
                        }`}
                    >
                        <div>{formatNumberToCurrency(values.pnlusd)}</div>
                        <div>{formatNumberToPercentage(values.pnl)}</div>
                    </div>
                )
            },
        },
        {
            title: 'TP/SL',
            key: 'tpsl',
            render: (_, values) => {
                return (
                    <span>
                        {formatNumberToCurrency(values.tp)} /{' '}
                        {formatNumberToCurrency(values.sl)}
                    </span>
                )
            },
        },
    ]
    return (
        <Table
            className="mt-2"
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: '600px', x: '1000px' }}
        />
    )
}

export default TableTrades
