import React from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    formatNumberToCurrency,
    formatNumberToPercentage,
} from 'src/utils/formatNumber'


export const SellerCard = ({ name, customers, roi, initialBalance }) => {
    return (
        <div className="text-white flex flex-col px-5 pb-3 pt-4 rounded-sm border border-[#2B2B2B] bg-[#1f1f1f]">
            <div className="mb-4 relative">
                <div className=" font-medium text-palette-beigde-100 text-lg font-oxanium leading-none ">
                    {name}
                </div>
                <div className="absolute right-0 translate-x-1/2 rounded-full cursor-pointer hover:bg-gray-500/50 text-palette-gray-700  hover:text-white top-1/2 -translate-y-1/2 h-7 w-7 flex transition delay-150 duration-300 ease-in-out">
                    <BiDotsVerticalRounded className="text-xl m-auto" />
                </div>
            </div>
            <div className="flex flex-row w-full gap-4">
                <div className="border-r border-[#2B2B2B] pr-2 flex flex-col">
                    <div className="font-medium font-maven text-xs text-palette-gray-700 mb-3">
                        Customers
                    </div>
                    <div className="tracking-tighter font-medium font-oxanium text-palette-gray-100 mt-auto text-base leading-none">
                        {customers}
                    </div>
                </div>
                <div className="flex-1 flex flex-row">
                    <div className="flex flex-col pr-4">
                        <div className="font-medium font-maven text-xs text-palette-gray-700 ">
                            ROI
                        </div>
                        <div className="text-[#B2955F] text-base font-medium font-oxanium mt-auto leading-none whitespace-nowrap">
                            {formatNumberToCurrency(roi)}{' '}
                            <span className="text-xs"> USD</span>{' '}
                        </div>
                    </div>
                    <div className="grow flex items-start justify-center">
                        <div className=" px-5 h-5 flex items-center w-fit rounded-full bg-[#716235] font-oxanium text-xs text-palette-beigde-100">
                            {/* {Number(epochDetails.pnlPerc.toFixed(3))} % */}
                            {formatNumberToPercentage(
                                (roi * 100) / initialBalance,
                                false
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
