import React from 'react'
import { Outlet } from 'react-router-dom'
import logoIgniite from 'src/assets/images/logo.png'

const StepsLayout = (props) => {
    return (
        <div className="h-screen overflow-auto w-full bg-palette-black-800 min-w-[425px] ">
            <div className="flex flex-col lg:flex-row h-full w-full max-w-[450px] lg:max-w-none mx-auto lg:mx-0">
                <div className="lg:flex-1 w-full lg:bg-palette-black-700 pl-5 pt-16">
                    <div className="w-full flex justify-center mx-auto lg:max-w-[380px] xl:pt-0 xl:ml-auto xl:mr-[25%] lg:mt-[18%]">
                        <img
                            src={logoIgniite}
                            className="w-4/6 lg:w-full h-auto"
                            alt="LOGO-IGNIITE"
                        />
                    </div>
                </div>
                <div className="lg:flex-1 w-full px-5 xl:pr-5 pt-16">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default StepsLayout
