import React from 'react'
import { HiOutlineArrowDown, HiOutlineArrowUp } from 'react-icons/hi'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

const StatsProfitValue = ({ value }) => {
    return (
        <div className="flex my-4 text-lg lg:text-2xl">
            {value >= 0 ? (
                <HiOutlineArrowUp className="text-palette-green-500 relative -mt-[0.2rem]" />
            ) : (
                <HiOutlineArrowDown className="text-palette-pink-700 relative -mt-[0.2rem]" />
            )}

            <div className="ml-4 text-palette-gray-100 font-oxanium font-bold leading-none">
                {value ? formatNumberToCurrency(value) : '$0.0'}{' '}
                <span className="font-maven font-medium text-sm">USD</span>
            </div>
        </div>
    )
}

export default StatsProfitValue
