/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import ButtonTemporality from './ButtonTemporality'

import {
    MONTHLY,
    WEEKLY,
    CUSTOM,
    // DAYS_90,
    YEARLY
} from '../../constants/temporality.constants'

import locale from 'antd/es/date-picker/locale/en_US'

import dayjs from 'dayjs'
import DatePicker from '../AntWithDaysJs/DatePicker'

const { RangePicker } = DatePicker

const Temporality = ({ className, selected, onSelect }) => {
    const [dates, setDates] = useState(null)
    const [hackValue, setHackValue] = useState(null)
    const [value, setValue] = useState(null)
    
    const handlePickDate = useCallback(
        (v) => {
            onSelect(CUSTOM, v)
            setValue(v)
        },
        [onSelect]
    )
    const disabledDate = (current) => {
        if (!dates) {
            return false
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 180
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 180

        return (
            !!tooEarly ||
            !!tooLate ||
            (current && current > dayjs().endOf('day')) ||
            current.isBefore('2023-05-02')
        )
    }

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null])
            setDates([null, null])
        } else {
            setHackValue(null)
        }
    }
    return (
        <div className={'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto'}>
            <div
                className={`w-[300px] ${
                    selected === CUSTOM ? 'flex' : 'hidden'
                }`}
            >
                <RangePicker
                    value={hackValue || value}
                    className="w-full  py-1"
                    size="small"
                    locale={locale}
                    onCalendarChange={(val) => setDates(val)}
                    onChange={handlePickDate}
                    onOpenChange={onOpenChange}
                    // style={{ height: '35.95px' }}
                    disabledDate={disabledDate}
                />
            </div>
            <div className={'space-x-2 flex flex-row w-full lg:w-auto'}>
                <div className={`w-full ${selected === CUSTOM ? 'hidden' : 'block'}`}>
                    <ButtonTemporality
                        onPointerDown={() => onSelect(CUSTOM)}
                        selected={CUSTOM === selected}
                    >
                        DATE
                    </ButtonTemporality>
                </div>

                <ButtonTemporality
                    onPointerDown={() => onSelect(WEEKLY)}
                    selected={WEEKLY === selected}
                >
                    WEEKLY
                </ButtonTemporality>
                <ButtonTemporality
                    onPointerDown={() => onSelect(MONTHLY)}
                    selected={MONTHLY === selected}
                >
                    MONTHLY
                </ButtonTemporality>

                <ButtonTemporality
                    onPointerDown={() => onSelect(YEARLY)}
                    selected={YEARLY === selected}
                >
                    YTD
                </ButtonTemporality>
            </div>
        </div>
    )
}

export default Temporality
