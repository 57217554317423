import { useCallback, useEffect } from 'react'
import { useContractFunction, useCall, useEthers } from '@usedapp/core'
import { useERC721IgniiteContract, useERC20Contract } from '../useContract'
import {
    NFT_ADDRESS_IGNIITE,
    THETER_ADDRESS,
} from '../../../constants/addressConstants'

// import { utils } from 'ethers'
import { sleep } from './../../../utils/promise'

const useMintNftUSDT = () => {
    const erc20Contract = useERC20Contract(THETER_ADDRESS)
    const contract = useERC721IgniiteContract(NFT_ADDRESS_IGNIITE)
    const { account } = useEthers()
    const {
        state: stateApprove,
        send: sendApprove,
        resetState: resetApprove,
    } = useContractFunction(erc20Contract, 'approve')
    const {
        state: stateMint,
        send: sendMint,
        resetState: resetMint,
    } = useContractFunction(contract, 'mintUSDT')

    const { value: allowance } =
        useCall(
            account && {
                contract: erc20Contract,
                method: 'allowance',
                args: [account, NFT_ADDRESS_IGNIITE],
            }
        ) ?? {}

    const approve = useCallback(async () => {
        try {
            await sendApprove(NFT_ADDRESS_IGNIITE, 2000000000)
        } catch (error) {
            console.log('Error', { error })
        }
    }, [sendApprove])

    const mint = useCallback(async () => {
        try {
            await sendMint(1)
        } catch (error) {
            console.log('Error', { error })
        }
    }, [sendMint])

    const initMint = useCallback(() => {
        const availableAllowance = parseInt(Number(allowance?.[0])) > 0

        if (!availableAllowance) {
            approve()
        } else {
            mint()
        }
    }, [allowance, approve, mint])

    const resetState = useCallback(() => {
        resetApprove()
        resetMint()
    }, [])

    useEffect(() => {
        const _mint = async () => {
            await sleep(600)
            mint()
        }
        if (stateApprove.status === 'Success') {
            _mint()
        }
    }, [stateApprove])

    return { initMint, stateMint, stateApprove, resetState }
}

export default useMintNftUSDT
