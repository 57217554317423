import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import { ORDER_TYPE } from 'src/constants/positionConstants'
import {
    reloadActiveOrders,
    reloadActiveTrades,
} from '../positionResolver/position.slice'

export const getWalletBalance = createAsyncThunk(
    'exchange/getWalletBalance',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/manager/getwalletbalance/${payload}`
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

/**
 * If Body is ORDER_TYPE.MARKET then params is:
 * {
 * keyId: number,
 * side: 'Buy' | 'Sell',
 * symbol: string,
 * qty: number,
 * percent: number,
 * }
 * If Body is ORDER_TYPE.LIMIT then params is:
 * {
 * keyId: number,
 * side: 'Buy' | 'Sell',
 * symbol: string,
 * qty: number,
 * percent: number,
 * entryPrice: number,
 * }
 */
export const placeOrder = createAsyncThunk(
    'exchange/placeorder',
    async ({ orderType, ...body }, { rejectWithValue, dispatch }) => {
        try {
            let url = ''
            if (ORDER_TYPE.LIMIT === orderType) {
                url = `bybit/manager/repurchaselimit/`
            } else {
                url = `bybit/manager/placemarketorder/`
            }

            const data = await axiosInstance.post(url, body).then((res) => {
                return res.data
            })

            dispatch(reloadActiveTrades())
            dispatch(reloadActiveOrders())

            return data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const setLeverage = createAsyncThunk(
    'exchange/setleverage',
    async (
        { keyId, symbol, longLeverage, shortLeverage },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(`bybit/manager/setleverage/`, {
                keyId,
                symbol,
                longLeverage,
                shortLeverage,
            })
            dispatch(reloadActiveTrades())
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const settakeprofit = createAsyncThunk(
    'exchange/settakeprofit',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(`bybit/manager/settakeprofit/`)
            dispatch(reloadActiveTrades())
            dispatch(reloadActiveOrders())
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const setStopLoss = createAsyncThunk(
    'exchange/setstoploss',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(`bybit/manager/setstoploss/`)
            dispatch(reloadActiveTrades())
            dispatch(reloadActiveOrders())
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getPosition = createAsyncThunk(
    'exchange/getposition',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(`bybit/manager/getposition/`)
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const changeCross = createAsyncThunk(
    'exchange/changecross',
    async (
        { keyId, isIsolated, symbol, longLeverage, shortLeverage },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(`bybit/manager/changecross/`, {
                keyId,
                isIsolated,
                symbol,
                longLeverage,
                shortLeverage,
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

// export const cancelOrder = createAsyncThunk(
//     'exchange/cancelorder',
//     async (payload, { rejectWithValue, dispatch }) => {
//         try {
//             const res = await axiosInstance.get(`bybit/manager/cancelorder/`)
//             dispatch(reloadActiveOrders())
//             return res.data
//         } catch (err) {
//             if (!err.response) throw err

//             return rejectWithValue(err.response.data)
//         }
//     }
// )
