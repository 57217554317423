import { createReducer } from '@reduxjs/toolkit'
import {
    deleteTrade,
    getAllEpochs,
    getAllTrades,
    getEpochsByUser,
    getTradesHistory,
    refresh,
    selectEpoch,
    createEpochPayment,
    getClientEpochDetails,
} from './historyTrades.actions'

export const BYBIT = 'BYBIT'
export const CLIENTS = 'CLIENTS'

export const initialState = {
    tradesHistory: [],
    allTrades: [],
    dailyPnL: [],
    details: {
        tradesNum: 0,
        wins: 0,
        losses: 0,
        biggestWin: 0,
        biggestLoss: 0,
        totalWins: 0,
        totalLosses: 0,
        actualBalance: 0,
    },
    refresh: 0,
    refreshEpochs: 0,
    epochs: [],
    epochsByUser: [],
    selectedEpoch: null,
    epochDetails: null,
    clientEpochDetails: []
}

const tradesHistoryReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(
            getTradesHistory.fulfilled,
            (
                state,
                { payload: { trades, dailyPnL, details, epochDetails } }
            ) => {
                state.tradesHistory = trades
                state.dailyPnL = dailyPnL
                state.details = details
                state.epochDetails = epochDetails
            }
        )
        .addCase(deleteTrade.fulfilled, (state, { payload }) => {
            return {
                ...state,
                allTrades: state.allTrades.filter((e) => e.id !== payload),
            }
        })
        .addCase(refresh, (state, action) => {
            state.refresh++
        })
        .addCase(getAllEpochs.fulfilled, (state, { payload }) => {
            state.epochs = payload
        })
        .addCase(getEpochsByUser.fulfilled, (state, { payload }) => {
            state.epochsByUser = payload
            if (!state.selectedEpoch && payload.length > 0) {
                state.selectedEpoch = payload[0].epoch
            }
        })
        .addCase(selectEpoch, (state, { payload }) => {
            state.selectedEpoch = payload
        })
        .addCase(getAllTrades.fulfilled, (state, { payload }) => {
            state.allTrades = payload
        })
        .addCase(createEpochPayment.fulfilled, (state, { payload }) => {
            return {
                ...state,
                epochs: state.epochs.map((e) => ({
                    ...e,
                    status: e.id === payload ? false : e.status,
                })),
            }
        })
        .addCase(getClientEpochDetails.fulfilled, (state, { payload }) => {
            state.clientEpochDetails = payload
        })
})

export default tradesHistoryReducer
