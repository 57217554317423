/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Select, Form } from 'antd'
import classNames from 'classnames'
import useResponsiveValue from './../../hooks/useResponsiveValue'

const FormItem = Form.Item

const FormikAntSelectAdmin = ({
    formik,
    name,
    placeholder = '',
    value,
    className = '',
    showError = true,
    size,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched
    // const values = formik?.values

    // useEffect(() => {
    //     if (value) formik.setValues({ ...formik.values, [name]: value ?? '' })
    // }, [name, value, values])

    const _value = value ?? formik.values?.[name]

    const Validate = showError ? FormItem : React.Fragment

    const inputSize =
        size ?? useResponsiveValue({ base: 'small', xl: 'middle' })
    const validateProps = showError
        ? {
              validateStatus:
                  submittedError || touchedError ? 'error' : 'success',
              help:
                  showError && (submittedError || touchedError)
                      ? hasError
                      : false,
          }
        : {}

    return (
        <Validate {...validateProps}>
            <Select
                id={name}
                name={name}
                placeholder={placeholder}
                value={_value}                
                className={className}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size={inputSize}
                {...props}
            />
        </Validate>
    )
}

export default FormikAntSelectAdmin
