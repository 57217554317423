import { useCall, useEthers } from '@usedapp/core'
import { useERC721IgniiteContract } from '../useContract'
import { NFT_ADDRESS_IGNIITE } from '../../../constants/addressConstants'

const useAccountAlreadyMinted = () => {
    const contract = useERC721IgniiteContract(NFT_ADDRESS_IGNIITE)
    const { account } = useEthers()

    const { value } =
        useCall(
            account && {
                contract,
                method: 'minted',
                args: [account],
            }
        ) ?? {}

    return value?.[0]
}

export default useAccountAlreadyMinted
