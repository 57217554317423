/* eslint-disable no-unused-vars */
// filters response with accounts

import axiosInstance from 'src/store/services/axiosConfig'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { YEARLY } from 'src/constants/temporality.constants'
import { notification } from 'antd'

export const getAllEpochs = createAsyncThunk(
    'tradeHistory/epochs',
    async(
        _,
        {rejectWithValue}
    )=>{
        try{
            const res = await axiosInstance.get('epochs/getallepochs/')
            return res.data
        }catch(error){
            if (!error.message) throw error
            return rejectWithValue(error.response.data)  

        }

    }
)
export const addNewEpoch = createAsyncThunk(
    'admin/createnewepoch',
    async (
        { minimumAmount, periodEnd, periodStart, description, periodicity },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post('epochs/createepoch/', {
                minimum_amount: minimumAmount,
                period_start: periodStart,
                period_end: periodEnd,
                description,
                periodicity,
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)
        }
    }
)

export const getEpochsByUser = createAsyncThunk(
    'tradesHistory/epochsByUser',
    async(
        _,
        {rejectWithValue}
    ) => {
        try {
            const res = await axiosInstance.get('epochs/getepochs/')
            return res.data.data
        } catch (error) {
            if (!error.message) throw error
            return rejectWithValue(error.response.data)              
        }
    }
)

export const deleteTrade = createAsyncThunk(
    'tradeHistory/deleteTrade',
    async(
        {id},
        {rejectWithValue}
    ) => {
        try {
            const res = await axiosInstance.delete(
                `epochs/pooltradeshistory/${id}/`
            )
            return id
        } catch (error) {
            if (!error.message) throw error
            return rejectWithValue(error.response.data)  
        }
    }
)
export const refresh = createAction('tradesHistory/refresh')
export const selectEpoch = createAction('tradesHistory/selectEpoch')
export const refreshEpochs = createAction('tradesHistory/refreshEpochs')

export const getAllTrades = createAsyncThunk(
    'tradesHistory/getAllTrades',
    async(_, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.get('epochs/getalltrades/')
            return res.data.data
        } catch (error) {
            if (!error.message) throw error
            return rejectWithValue(error.response.data)     
        }
    }
)

export const getTradesHistory = createAsyncThunk(
    'tradesHistory/getTradesHistory',
    async (
        {
            start,
            end = dayjs().format('YYYY-MM-DD'),
            initialBalance,
            temporality,
            selectedEpoch,
        },
        { rejectWithValue }
    ) => {
        try {
            if (temporality === YEARLY) {
                // when YTD is clicked. Fixed start date defined by admins
                start = '2023-05-02'
            }
            const res = await axiosInstance.post(`epochs/pooltradeshistory/`, {
                start,
                end,
                selectedEpoch,
            })


            return {
                trades: res.data.data.trades,
                dailyPnL: res.data.data.dailyPnL,
                details: res.data.data.details,
                epochDetails: res.data.data.epochDetails,
            }
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const bulkCreateTrades = createAsyncThunk(
    'tradesHistory/bulkCreate',
    async (array , { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`epochs/bulkcreate/`, {
                array,
            })
            notification.success({
                message: 'Create Trades',
                description: `You successfully created ${array.length} trades`,
                placement: 'bottomRight',
            })
            return res.data
        } catch (err) {
            const errorArgs = {
                // icon: customAntNotifications.error,
                message: 'Error',
                description:
                    err?.error === 'true'
                        ? err?.message
                        : 'Something went wrong. Please Check your file',
                placement: 'bottomRight',
            }
            notification.error(errorArgs)
        } 
    }
)

export const createEpochPayment = createAsyncThunk(
    'tradesHistory/createEpochPayment',
    async(epochId, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.post('epochs/createepochpayment/',{
                epoch: epochId
            })
            return epochId
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const createEpochPayPeriodicity = createAsyncThunk(
    'tradesHistory/createEpochPaymentPeriodicity',
    async(epochId, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.post('epochs/epochpaymentperiodicity/',{
                epoch: epochId
            })
            return epochId
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getClientEpochDetails = createAsyncThunk(
    'tradesHistory/getClientEpochDetails',
    async(_, {rejectWithValue})=> {
        try {
            const res = await axiosInstance.get('epochs/clientepochdetails/')
            return res.data.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)            
        }
    }
)

export const getPayPeriodicity = async function (epochId) {
    try {
        const res = await axiosInstance.get('epochs/epochpaymentperiodicity/', {
            params: {
                epoch: epochId
            }
        })
        return res.data.data
    } catch (error) {
        if (!error.response) throw error
        return error.response.data
        // return rejectWithValue(error.response.data)            
    }
}

export const getUserPayments = createAsyncThunk(
    'tradesHistory/getUserPayments',
    async(_, {rejectWithValue})=> {
        try {
            const res = await axiosInstance.get('epochs/getuserpayments/')
            return res.data.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)            
        }
    }
    
) 
