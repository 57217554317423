import React from 'react'
import { Modal } from 'antd'
import Button from 'src/components/Buttons/Button'

const CustomModal = ({
    onCancel,
    onOk,
    visible = false,
    title = '',
    buttonCancelText = 'Cancel',
    buttonOkText = 'Save',
    width = 520,
    loading = false,
    children,
}) => {
    return (
        <Modal
            centered
            visible={visible}
            title={title}
            onCancel={onCancel}
            onOk={onOk}
            footer={
                <div className="flex flex-row lg:justify-end space-x-2 ">
                    <Button
                        key={'custom-btn-cancel'}
                        onClick={onCancel}
                        disabled={loading}
                        className="w-4/12 lg:w-auto"
                        variant="lightDanger"
                    >
                        {buttonCancelText}
                    </Button>
                    <Button
                        disabled={loading}
                        loading={loading}
                        key={'custom-btn-ok'}
                        className="w-8/12 lg:w-36"
                        onClick={onOk}
                    >
                        {buttonOkText}
                    </Button>
                </div>
            }
            width={width}
        >
            {children}
        </Modal>
    )
}

export default CustomModal
