import React, { useRef } from 'react'
import CardContainer from 'src/components/Cards/CardContainer'
import { RiLock2Line, RiLockUnlockLine } from 'react-icons/ri'
import { Progress } from 'antd'
import dayjs from 'dayjs'
import Button from 'src/components/Buttons/Button'
import {formatNumberToCurrency} from "../../utils/formatNumber";

export const EpochCard = ({ epoch = {}, handleEpochClick, handleEpochPayClick }) => {
    // const [iconSize] = useResponsive({ base: '20px', xl: '30px' })
    const cardRef = useRef(null)
    // const dispatch = useDispatch()

    const calculateProgress = (initialDate, endDate, id) => {
        const date1 = dayjs(initialDate)
        const date2 = dayjs(endDate)
        const now = dayjs()
        const initialDifference = date1.diff(date2, 'day')
        const actualDifference = date1.diff(now, 'day')
        let progress = Math.abs((actualDifference * 100) / initialDifference)
        if (progress < 0) {
            progress = 100
        }
        return progress
    }

    return (
        <CardContainer ref={cardRef} className="pt-5 xl:pt-8">
            <div className="flex flex-row w-full px-4 lg:px-5 xl:px-8">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between items-end">
                        <span className="md:text-base lg:text-lg xl:text-2xl font-medium leading-none text-primary font-oxanium text-palette-brown-700 tracking-widest">
                            Epoch {epoch.id}
                        </span>

                        <div className=" px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-xs text-palette-beigde-100">
                            {Number(epoch.growth).toLocaleString()} %
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 lg:px-5 xl:px-8">
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-t border-[#41403D] flex flex-row justify-between mt-6 py-2  text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        Initial Amount
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.initial_amount).toLocaleString()}
                    </div>
                </div>
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-[#41403D] flex flex-row justify-between py-2 text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        Actual Balance
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.actual_balance).toLocaleString()}
                    </div>
                </div>
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-[#41403D] flex flex-row justify-between py-2 text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        ROI (PNL)
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.pnl).toLocaleString()}
                    </div>
                </div>
            </div>
            <div className="relative flex justify-center items-end text-gray-5 dark:text-gray-2 py-3 before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[80%] before:bg-gradient-to-t before:from-palette-gold-100/20 before:to-transparent">
                <div className="relative mr-2 md:text-base lg:text-lg xl:text-xl text-[#B2955F]">
                    {epoch.status ? <RiLockUnlockLine /> : <RiLock2Line />}
                </div>
                <span className="md:text-sm lg:text-sm xl:text-base leading-5 font-maven font-medium text-[#878787] text-xs">
                    {epoch.status ? 'Open' : 'Closed'}
                </span>
            </div>
            <div className="dark:bg-gray-15 border-t border-[#41403D] pb-3 md:pb-2  xl:pb-3">
                <div className="px-4 lg:px-5 xl:px-8">
                    <div className="flex flex-row justify-between leading-none mt-4">
                        <div className="flex flex-col">
                            <div className="font-maven font-medium text-[#747474] text-xs mb-2 ">
                                {dayjs() < dayjs(epoch.period_end)
                                    ? 'Ends on'
                                    : 'Ended on'}
                            </div>
                        </div>
                        <div className="flex flex-col items-end mb-2">
                            <div className="text-xxs md:text-xs xl:text-md text-gray-100 font-oxanium font-medium text-end mb-2">
                                {dayjs(epoch.period_end).format(
                                    'MMMM DD, YYYY'
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Progress
                            percent={calculateProgress(
                                epoch.period_start,
                                epoch.period_end,
                                epoch.id
                            )}
                            showInfo={false}
                            trailColor={'#2A2A2A'}
                            strokeColor={'#716235'}
                        />
                    </div>
                    {epoch.status && (
                        <div className="flex justify-center py-2">
                            <Button 
                                onClick={(e) => handleEpochPayClick(epoch.id)}
                                disabled={!epoch.status}
                                className="mr-2">
                                <div>Pay</div>
                            </Button>
                            <Button
                                onClick={(e) => handleEpochClick(epoch.id)}
                                disabled={!epoch.status}
                            >
                                <div>Close</div>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </CardContainer>
    )
}
