/* eslint-disable no-unused-vars */
import React, {useMemo, useState} from 'react'
import { CustomerCard } from './CustomerCard'
import { Input } from 'antd'
import {
    useFetchStaffClients,
    useStaffState,
} from 'src/store/reducers/staffReducer/staff.hooks'
const { Search } = Input

export const CustomersGrid = () => {
    const [search, setSearch] = useState('')

    useFetchStaffClients()
    const { staffClients } = useStaffState()

    const customers = useMemo(() => {
        return staffClients.filter((client) => client?.name.indexOf(search) !== -1)
    }, [search, staffClients]) 

    return (
        <div className="flex flex-col mt-4">
            <div className="flex justify-between mb-4">
                <div className="text-palette-gray-100 text-sm font-medium">
                    Customers
                </div>
                <Search
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: 200 }}
                />
            </div>
            <div className="grid grid-cols-3 gap-y-3 gap-x-4">
                {customers.map((client, index) => (
                    <CustomerCard
                        key={index}
                        name={client.name}
                        initialAmount={client.initialBalance}
                        epochs={client.epochs}
                        pnl={client.pnl}
                    />
                ))}
            </div>
        </div>
    )
}
