import {
    StatsTitle,
    StatsContainer,
    StatsProfitValue,
} from '../StatsComponents'
import StatsLegend from '../StatsComponents/StatsLegend'
import { formatNumberToPercentage } from 'src/utils/formatNumber'

const Stats3 = ({ value, title, total, percent, positive = 'green' }) => {
    return (
        <StatsContainer>
            <div className="flex flex-col justify-evenly ">
                <div className="flex flex-row items-end">
                    <StatsTitle title={title} />
                    <div
                        className={`ml-auto  font-semibold text-xs lg:text-sm ${
                            value >= 0
                                ? 'text-palette-green-500'
                                : 'text-palette-pink-700'
                        }`}
                    >
                        {' '}
                        {percent ? formatNumberToPercentage(percent) : '0.0%'}
                    </div>
                </div>
                <div className="flex flex-row items-end">
                    <StatsProfitValue value={value} />
                </div>
                {total ? <StatsLegend value={total} /> : null}
            </div>
        </StatsContainer>
    )
}

export default Stats3
