import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTradesByUserId } from './referralUserTrades.actions'

const selectSelf = (state) => state.referralUserTrades

export const useReferralUserTradesState = () => {
    return useSelector(selectSelf)
}

export const useFetchReferralhUserTrades = (userId) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (userId > 0) dispatch(getTradesByUserId(userId))
    }, [userId])
}
