import { createSelector } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'src/hooks/useRefresh'
import { useEffect, useCallback } from 'react'
import { useIsAuth } from 'src/store/reducers/auth/auth.hooks'
import { getUserExtraInfo } from './userInfo.actions'

const selectSelf = (state) => state.userInfoReducer

export const userInfoSelector = createSelector(selectSelf, (self) => self)

export const useUserInfo = () => {
    return useSelector(userInfoSelector)
}

export const useFetchUserInfo = () => {
    const dispatch = useDispatch()
    const isAuth = useIsAuth()
    const { slowRefresh } = useRefresh()

    const _getUserExtraInfo = useCallback(
        () => dispatch(getUserExtraInfo()),
        [dispatch]
    )

    useEffect(() => {
        if (isAuth) _getUserExtraInfo()
    }, [isAuth, slowRefresh])
}
