import React from 'react'
import ButtonTemporality from '../Temporality/ButtonTemporality'

export const EpochsSelector = ({classname, selected, onSelect}) => {
  return (
      <div
          className={'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto'}
      >
          
          <div className={'space-x-2 flex flex-row w-full lg:w-auto h-8 whitespace-nowrap'}>
              
              <ButtonTemporality
                  onPointerDown={() => onSelect('ALL')}
                  selected={selected === 'ALL'}
              >
                  ALL
              </ButtonTemporality>
              <ButtonTemporality
                  onPointerDown={() => onSelect('10')}
                  selected={selected=== '10'}
              >
                  Last 10 Epochs
              </ButtonTemporality>

              <ButtonTemporality
                  onPointerDown={() => onSelect('30')}
                  selected={selected === '30'}
              >
                  Last 30 Epochs
              </ButtonTemporality>
          </div>
      </div>
  )
}
