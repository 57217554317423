import { rectanglePath } from "src/constants/rectanglePath"

export const categories = [
        // Number of Trades
        {
            symbol: 'circle',
            symbolSize: 100,
            itemStyle: {
                color: '#000000',
                borderColor: '#8D793F',
            },

            label: {
                formatter: function (d) {
                    return [
                        '{a| Number \n of Trades}',
                        `{b|${d.data.name}}`,
                    ].join('\n')
                },
                rich: {
                    a: {
                        align: 'center',
                        color: '#747474',
                        fontSize: 12,
                        lineHeight: 15,
                        fontFamily: "'Maven Pro', 'sans-serif'",
                        fontWeight: 500,
                    },
                    b: {
                        fontFamily: 'Oxanium',
                        fontWeight: 700,
                        align: 'center',
                        color: '#8D793F',
                        fontSize: 24,
                        lineHeight: 35,
                    },
                },
            },
        },
        // Wins
        {
            symbol: rectanglePath,
            symbolSize: [100, 50],
            itemStyle: {
                color: '#000000',
                borderColor: '#8D793F',
            },
            label: {
                color: '#D8D1C3',
                fontWeight: 500,
                fontSize: 18,
                fontFamily: 'Oxanium',
            },
        },
        // Losses
        {
            symbol: rectanglePath,
            symbolSize: [100, 50],
            itemStyle: {
                color: '#000000',
                borderColor: '#747474',
            },
            label: {
                color: '#D8D1C3',
                fontWeight: 500,
                fontSize: 18,
                fontFamily: 'Oxanium',
            },
        },
        // Biggest Win & Total Wins
        {
            symbol: rectanglePath,
            symbolSize: [100, 50],
            itemStyle: {
                color: '#000000',
                borderColor: '#8D793F',
            },
            label: {
                formatter: function (d) {
                    return [`{a|${d.data.name}}`, '{b|USDT}'].join(
                        '\n'
                    )
                },
                rich: {
                    a: {
                        fontFamily: 'Oxanium',
                        align: 'center',
                        color: '#D8D1C3',
                        fontSize: 18,
                        lineHeight: 27,
                    },
                    b: {
                        fontWeight: 500,
                        fontFamily: 'Oxanium',
                        align: 'center',
                        color: '#BFBFBF',
                        fontSize: 12,
                        lineHeight: 17,
                    },
                },
            },
        },
        // Biggest Loss & Total Losses
        {
            symbol: rectanglePath,
            symbolSize: [100, 50],
            itemStyle: {
                color: '#000000',
                borderColor: '#747474',
            },
            label: {
                formatter: function (d) {
                    return [`{a|${d.data.name}}`, '{b|USDT}'].join(
                        '\n'
                    )
                },
                rich: {
                    a: {
                        fontFamily: 'Oxanium',
                        align: 'center',
                        color: '#D8D1C3',
                        fontSize: 18,
                        lineHeight: 27,
                    },
                    b: {
                        fontWeight: 500,
                        fontFamily: 'Oxanium',
                        align: 'center',
                        color: '#BFBFBF',
                        fontSize: 12,
                        lineHeight: 17,
                    },
                },
            },
        },
    ]

export const links = [
    {
        source: 'Number of trades',
        target: 'WINS',
        lineStyle: {
            color: '#8D793F',
            width: 2,
        },
    },
    {
        source: 'WINS',
        target: 'Biggest Win',
        lineStyle: {
            color: '#8D793F',
            width: 2,
        },
    },
    {
        source: 'Biggest Win',
        target: 'totalWins',
        lineStyle: {
            color: '#8D793F',
            width: 2,
        },
    },

    {
        source: 'Number of trades',
        target: 'LOSSES',
        lineStyle: {
            width: 2,
        },
    },
    {
        source: 'LOSSES',
        target: 'Biggest Loss',
        lineStyle: {
            width: 2,
        },
    },
    {
        source: 'Biggest Loss',
        target: 'Total Losses USDT',
        lineStyle: {
            width: 2,
        },
    },
]

export const titles = [
    {
        text: 'Wins',
        left: '33%',
        top: '5%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Biggest Win',
        left: '58%',
        top: '5%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Total Wins',
        left: '85%',
        top: '5%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Losses',
        left: '33%',
        top: '60%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Biggest Loss',
        left: '58%',
        top: '60%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Total Losses',
        left: '85%',
        top: '60%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
]

export const mobileTitles = [
    // {
    //     text: 'WINS x LOSSES DETAILS',
    //     left: 20,
    //     textStyle: {
    //         color: '#fff',
    //         fontWeight: 500,
    //         fontSize: 16,
    //         fontFamily: "'Maven Pro', 'sans-serif'",
    //     },
    // },
    {
        text: 'Wins',
        left: '75%',
        top: '30%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Biggest Win',
        left: '69%',
        top: '50%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Total Wins',
        left: '70%',
        top: '70%',
        textStyle: {
            color: '#8D793F',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Losses',
        left: '13%',
        top: '30%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Biggest Loss',
        left: '10%',
        top: '50%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
    {
        text: 'Total Losses',
        left: '10%',
        top: '70%',
        textStyle: {
            color: '#747474',
            fontWeight: 500,
            fontSize: 12,
            fontFamily: "'Maven Pro', 'sans-serif'",
        },
    },
]



