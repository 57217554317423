import { useState, useCallback } from 'react'
import { MARGIN_TYPE } from 'src/constants/positionConstants'

import { Modal } from 'antd'
import Button from 'src/components/Buttons/Button'
import classNames from 'classnames'
import { useChangeCross } from 'src/store/reducers/exchangeReducer/exchange.hooks'

export const MarginMode = ({ visible, onClose }) => {
    const changeCross = useChangeCross()
    const [selected, setSelected] = useState(MARGIN_TYPE.ISOLATED)

    const handleChangeCross = useCallback(() => {
        changeCross({
            keyId: 6,
            symbol: 'ETHUSDT',
            shortLeverage: 20,
            longLeverage: 10,
            isIsolated: true,
        })
    }, [selected])

    return (
        <Modal
            centered
            // closable={false}
            visible={visible}
            maskClosable={false}
            footer={null}
            onCancel={onClose}
        >
            <div className="pb-4">
                <div className="text-3xl font-bold text-center text-palette-brown-700">
                    Margin Mode
                </div>
                <div className="flex flex-row gap-3 mt-4">
                    <Button
                        onClick={() => {
                            setSelected(MARGIN_TYPE.ISOLATED)
                        }}
                        className={classNames('rounded-none w-full', {
                            'outline outline-offset-2 outline-palette-yellow-700 focus:ring-0':
                                selected === MARGIN_TYPE.ISOLATED,
                        })}
                    >
                        ISOLATED
                    </Button>
                    <Button
                        onClick={() => {
                            setSelected(MARGIN_TYPE.CROSS)
                        }}
                        className={classNames('rounded-none w-full', {
                            'outline outline-offset-2 outline-palette-yellow-700 focus:ring-0':
                                selected === MARGIN_TYPE.CROSS,
                        })}
                    >
                        CROSS
                    </Button>
                </div>

                <div className="text-justify text-palette-gray-700 font-medium mt-4">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Unde ab quibusdam voluptates, suscipit eius dolor cupiditate
                    nobis temporibus sapiente ducimus, ratione consequuntur
                    officia facere minus aperiam aliquid veniam reiciendis, ea
                    similique dicta sed soluta. Est ex a quam fugit vel.
                    <a className="underline ml-2">Read Terms and Conditions</a>
                </div>

                <Button
                    variant="light"
                    type="submit"
                    className="mt-6 w-full"
                    onClick={handleChangeCross}
                >
                    CONFIRM
                </Button>

                {/* <button
                    onClick={handleChangeCross}
                    className="py-1 w-full mt-6 text-sm rounded-sm text-palette-brown-901 font-semibold border border-palette-brown-901 flex justify-center items-center"
                >
                    CONFIRM
                </button> */}
            </div>
        </Modal>
    )
}
