import WalletConnectProvider from '@walletconnect/web3-provider'

const walletConnect = new WalletConnectProvider({
    infuraId: '403f9db137594a5ba26fee776accc143',
})

const WALLET_CONNECT = 'WALLET_CONNECT'

export const CONNECTORS_NAME = {
    walletConnect: WALLET_CONNECT,
}

export const connectorsByName = {
    [CONNECTORS_NAME.walletConnect]: walletConnect,
}
