import React, { useRef, useCallback } from 'react'

import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import AddSellerForm from './AddSellerForm'
// import { useReloadAdmin } from 'src/store/reducers/adminReducer/adminReducer'
import { useRegisterStaff } from 'src/store/reducers/staffReducer/staff.hooks'

const AddSellerModal = ({ visible = false, onClose, onCreate }) => {
    // const reloadAdmin = useReloadAdmin()
    const formRef = useRef(null)
    const { signUp, loading } = useRegisterStaff()

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }
    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    const handleSubmit = useCallback(
        async (values) => {
            await  signUp({ ...values })           
            handleClose()
        },
        [signUp]
    )

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'ADD NEW SELLER'}
            width={450}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <AddSellerForm ref={formRef} onSubmit={handleSubmit} />
        </CustomModal>
    )
}

export default AddSellerModal
