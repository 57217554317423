/* eslint-disable no-unused-vars */
import { useEthers, Mainnet } from '@usedapp/core'
import { useCallback } from 'react'
import { connectorsByName } from './../../utils/web3React'

const useAuthW3 = (props) => {
    const {
        error,
        activate,
        activateBrowserWallet,
        deactivate,
        switchNetwork,
        chainId,
    } = useEthers()

    const login = useCallback(async (connectorName) => {
        try {
            const connector = connectorsByName[connectorName]
            connector.enable()
            await activate(connector)
        } catch (err) {
            console.log({ err })
        }
    }, [])

    const loginMetamask = useCallback(async () => {
        try {
            await activateBrowserWallet()
        } catch (err) {
            console.log({ err })
        }
    }, [chainId])

    const logout = useCallback(async () => {
        await deactivate()
    }, [])

    return { login, loginMetamask, logout }
}

export default useAuthW3
