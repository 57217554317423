import { useEffect, useState } from 'react'

// Create a new hook
// Args: mediaQuerys: object with the media querys (e.g. { '2xl': '1536px', xl: '1280px', lg: '1024px', md: '768px', sm: '640px' })
// Then it will return the greatest breakpoint that matches the media querys (e.g. if the screen is 1024px wide, it will return 'lg') or null if none of the media querys matches
// It will also update the breakpoint when the screen size changes
// And it will calculate at the beginning the breakpoint that matches the media querys (e.g. if the screen is 1024px wide, it will return 'lg') or null if none of the media querys matches

export const useBreakpoint = (mediaQuerys) => {
    const [breakpoint, setBreakpoint] = useState(null)

    useEffect(() => {
        const mediaQueryLists = {}
        const keys = Object.keys(mediaQuerys)
        let isAttached = false

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc, media) => {
                acc[media] = !!mediaQueryLists[media].matches
                return acc
            }, {})

            const currentBreakpoint = keys.find((key) => updatedMatches[key])

            if (currentBreakpoint !== breakpoint) {
                setBreakpoint(currentBreakpoint)
            }
        }

        if (window && window.matchMedia) {
            const matches = {}
            keys.forEach((media) => {
                if (typeof mediaQuerys[media] === 'string') {
                    mediaQueryLists[media] = window.matchMedia(
                        mediaQuerys[media]
                    )
                    matches[media] = mediaQueryLists[media].matches
                } else {
                    matches[media] = false
                }
            })
            const currentBreakpoint = keys.find((key) => matches[key])
            setBreakpoint(currentBreakpoint)
            isAttached = true
            keys.forEach((media) => {
                if (typeof mediaQuerys[media] === 'string') {
                    mediaQueryLists[media].addListener(handleQueryListener)
                }
            })
        }

        return () => {
            if (isAttached) {
                keys.forEach((media) => {
                    if (typeof mediaQuerys[media] === 'string') {
                        mediaQueryLists[media].removeListener(
                            handleQueryListener
                        )
                    }
                })
            }
        }
    }, [mediaQuerys, breakpoint])

    return breakpoint
}
