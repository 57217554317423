import React from 'react'
import { Input, Form, Tooltip } from 'antd'
import classNames from 'classnames'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

const FormItem = Form.Item

const classes = {
    base: 'rounded-sm w-full hover:outline-none focus:ring-1 ',
    size: {
        small: 'h-10 text-xs',
        middle: 'h-[60px] text-xs',
    },
    variant: {
        primary:
            'bg-palette-black-725 focus:ring-palette-gray-700 placeholder:text-palette-gray-700',
        secondary:
            'bg-palette-black-700 focus:ring-white placeholder:text-palette-gray-700',
    },
}

const defaultTransform = {
    input: (value) => value,
    output: (value) => value,
    blur: null,
}

const FormikAntInput = ({
    formik,
    name,
    placeholder,
    value,
    size = 'middle',
    variant = 'primary',
    tooltip = false,
    tooltipProps = {},
    className,
    showError = true,
    bordered = false,
    transform = defaultTransform,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched

    const placement = useResponsiveValue({
        base: 'topLeft',
        lg: 'rightTop',
    })

    
    const $transform = {
        ...defaultTransform,
        ...transform,
    }
    
    const $value = value ?? formik.values?.[name]
    const Wrapper = tooltip ? Tooltip : React.Fragment
    const defaultTooltipProps = tooltip ?{
        trigger: ['focus'],
        title: 'EXAMPLE',
        placement,
        autoAdjustOverflow: true,
        color: '#1c1c1c',
    } : {}
    const Validate = showError ? FormItem : React.Fragment
    const inputSize =
        size ?? useResponsiveValue({ base: 'small', xl: 'middle' })

    return (
        <Wrapper {...defaultTooltipProps} {...tooltipProps}>
            <Validate
                validateStatus={
                    submittedError || touchedError ? 'error' : 'success'
                }
                help={
                    showError && (submittedError || touchedError)
                        ? hasError
                        : false
                }
            >
                {' '}
                <Input
                    id={name}
                    name={name}
                    bordered={bordered}
                    // prefix={prefix}
                    placeholder={placeholder}
                    value={$transform.input($value)}
                    className={classNames(
                        classes.base,
                        {
                            [classes.variant[variant]]:
                                !!classes.variant[variant] !== 'none',
                            [classes.size[size]]: size !== 'none',
                            [className]: !!className,
                        }
                        // 'outline'
                    )}
                    onChange={(e) => {
                        const {
                            target: { value, name },
                        } = e

                        formik.setValues({
                            ...formik.values,
                            [name]: $transform.output(
                                value,
                                formik.values[name]
                            ),
                        })
                    }}
                    onBlur={(e) => {
                        const {
                            target: { value, name },
                        } = e

                        formik.setTouched({ ...formik.touched, [name]: true })

                        if (!$transform.blur) return

                        formik.setValues({
                            ...formik.values,
                            [name]: $transform.blur(value, formik.values[name]),
                        })
                    }}
                    size={inputSize}
                    {...props}
                    // style={{ fontSize: '12px' }}
                />
            </Validate>
        </Wrapper>
    )
}

export default FormikAntInput
