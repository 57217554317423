/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import React from 'react'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

export const CustomerProfits = () => {
    const { clientEpochDetails } = useHistoryTradesState()
    // initial balance from all epochs
    const initialAmountSummary = clientEpochDetails
        .map((e) => e.initial_amount)
        .reduce((acc, curr) => acc + Number(curr), 0)

    // pnl from closed epochs
    const paidSummary = clientEpochDetails
        .map((e) => {
            if (e.status) {
                return Number(e.paid)
            }
            return 0
        })
        .reduce((acc, curr) => acc + Number(curr), 0)

    // pnl from non closed epochs which end period has already expired
    const roiPending = clientEpochDetails
        .map((e) => {
            // if (dayjs() > dayjs(e.period_end)) {
            return Number(e.pnl_current)
            // }
            // return 0
        })
        .reduce((acc, curr) => acc + curr, 0)

    // const totalProfit = roiPaid + roiPending - roiFee
    const roiFee = roiPending * 0.30
    const roiEstimatedPending = roiPending * 0.70
    return (
        <div className="flex flex-col bg-[#0A0A0A] border border-palette-gray-500 rounded h-full">
            <div className="relative px-3 py-4 flex flex-row justify-between before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[50%] before:bg-gradient-to-t before:from-palette-gold-200 ">
                <div className="text-palette-beigde-100 text-sm font-semibold flex items-center">
                    MY PROFITS
                </div>
            </div>

            <div className="px-5 py-5 space-y-7 flex flex-col justify-center flex-auto">
                <div className="flex flex-row justify-between">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        Total contributions
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency(initialAmountSummary)}
                    </div>
                </div>
                <div className="flex flex-row justify-between ">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        ROI Paid
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency(paidSummary)}
                    </div>
                </div>
                <div className="flex flex-row justify-between ">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        ROI CURRENT PERIOD AFTER FEE
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency((roiEstimatedPending))}
                    </div>
                </div>
                <div className="flex flex-row justify-between ">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        ROI FEE CURRENT PERIOD (30%)
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency(roiFee)}
                    </div>
                </div>
            </div>

            <div className="bg-[#141414] border-t border-palette-gray-500 relative flex flex-col items-center py-3 before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[50%] before:bg-gradient-to-t before:from-palette-gold-200 ">
                <div className="mb-6 space-y-2">
                    <div className="text-center text-palette-beigde-100 font-semibold text-sm">
                        TOTAL ESTIMATED PROFIT IN CURRENT PERIOD
                    </div>
                    <div className="text-center text-[#B2955F] font-oxanium font-medium text-3xl">
                        {formatNumberToCurrency(roiPending)}{' '}
                        <span className="text-base">USD</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
