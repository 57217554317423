import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useUpdatedStaked } from 'src/store/reducers/auth/auth.hooks'
import { login, logout, setAuth } from 'src/store/reducers/auth/auth.actions'
import { getUserExtraInfo } from '../store/reducers/userInfoReducer/userInfo.actions'

// import stakingContractAbi from '../abi/IgniiteStaking.json'
// import { STAKING_ADDRESS } from './../constants/addressConstants'
// import { useEthers } from '@usedapp/core'
// import { Contract } from '@ethersproject/contracts'
// import ethers from 'ethers'

const useAuth = () => {
    // const { library } = useEthers()
    const updateStaked = useUpdatedStaked()

    const dispatch = useDispatch()
    const _login = useCallback(
        async (data) => {
            try {
                await dispatch(login(data)).unwrap()
                const res = await dispatch(getUserExtraInfo()).unwrap()

                const { is_superuser: isSuperuser } = res

                if (isSuperuser) {
                    updateStaked(true)
                } else {
                    // const contract = new Contract(
                    //     STAKING_ADDRESS,
                    //     stakingContractAbi,
                    //     library
                    // )

                    // const isStaked = await contract
                    //     .stakes(address)
                    //     .then((v) => {
                    //         return v[0] ? Number(parseInt(v[0])) > 0 : false
                    //     })

                    updateStaked(true)
                }

                dispatch(setAuth())
                localStorage.setItem('verified', true)
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        [dispatch]
    )

    const _logout = useCallback((data) => dispatch(logout(data)), [dispatch])

    return { login: _login, logout: _logout }
}

export default useAuth
