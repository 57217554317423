import { createSlice } from '@reduxjs/toolkit'
import { removeDuplicates } from 'src/utils/arrays'
import {
    getUserActiveTrades,
    getUserTrades,
    getUserOrders,
} from './position.actions'

export const initialState = {
    symbols: [],
    users: [],
    activeTrades: {},
    activeOrders: {},
    reloadActiveTrades: false,
    reloadActiveOrders: false,
}

const positionResolverSlice = createSlice({
    name: 'positionResolver',
    initialState,
    reducers: {
        reloadActiveTrades: (state) => {
            state.reloadActiveTrades = !state.reloadActiveTrades
        },
        reloadActiveOrders: (state) => {
            state.reloadActiveOrders = !state.reloadActiveOrders
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getUserActiveTrades.fulfilled,
                (state, { payload: { users, symbols } }) => {
                    state.users = users.map(({ keyname, user, ...v }) => ({
                        ...v,
                        keyname: keyname?.toUpperCase() ?? '',
                        user: user?.toUpperCase() ?? '',
                    }))
                    state.symbols = removeDuplicates(symbols)
                }
            )
            .addCase(
                getUserTrades.fulfilled,
                (state, { payload: { keyId, data } }) => {
                    return {
                        ...state,
                        activeTrades: {
                            ...state.activeTrades,
                            [keyId]: data,
                        },
                    }
                }
            )
            .addCase(
                getUserOrders.fulfilled,
                (state, { payload: { keyId, data } }) => {
                    return {
                        ...state,
                        activeOrders: {
                            ...state.activeOrders,
                            [keyId]: data,
                        },
                    }
                }
            )
    },
})

export const { reloadActiveTrades, reloadActiveOrders } =
    positionResolverSlice.actions
export default positionResolverSlice.reducer
