import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import * as actions from 'src/store/reducers/dashboardReducer/dashboard.slice'
import { setActiveAccount } from 'src/store/reducers/dashboardReducer/dashboard.actions'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import SwitchSubscribeKey from '../TradeComponents/SwitchSubscribeKey'
import Button from 'src/components/Buttons/Button'

const ActiveKey = ({ item, main }) => {
    const activekeysbybit = useSelector(
        (state) => state.dashboardReducer.activekeysbybit
    )
    const dispatch = useDispatch()
    const setActiveAccountAction = useCallback(
        (account) => dispatch(setActiveAccount(account)),
        [dispatch]
    )

    const [showDetails, setShowDetails] = useState(false)

    const accountName = useMemo(() => {
        return main ? `Main: ${item.name}` : `Sub Account: ${item.name}`
    }, [item, main])

    return (
        <div className="mt-4 p-5 text rounded-md shadow-md bg-palette-black-700 border border-palette-brown-901">
            <div className="flex justify-end ">
                <div className="font-bold flex flex-row items-center px-1 text-palette-gray-700 cursor-pointer">
                    <SwitchSubscribeKey
                        key={`switch-key-${item.id}`}
                        item={item}
                    />

                    <div
                        className="relative mt-[1px] ml-2"
                        onClick={() => {
                            setShowDetails(!showDetails)
                        }}
                    >
                        {showDetails ? (
                            <CaretUpOutlined />
                        ) : (
                            <CaretDownOutlined />
                        )}
                    </div>
                </div>
            </div>
            <div className="mb-5 flex justify-start ">
                <div
                    className={`text-base ${
                        item.id === activekeysbybit
                            ? "text-palette-brown-901 font-semibold before:content-[''] before:inline-block before:w-2 before:h-2 before:rounded-full before:mr-2 before:relative before:-mt-[0.05rem] before:bg-palette-brown-901"
                            : 'text-palette-beigde-100'
                    }`}
                >
                    {accountName}
                </div>
            </div>
            <form>
                <div className="grid grid-cols-5">
                    <div className="col-span-2 mr-10">
                        <input
                            className="w-full  pl-5 h-10 border-palette-black-700 border bg-palette-black-725"
                            placeholder="*********"
                            type="password"
                            disabled={true}
                        ></input>
                    </div>
                    <div className="col-span-2 mr-10">
                        <input
                            className="w-full pl-5 h-10 border-palette-black-700 border bg-palette-black-725 "
                            placeholder="*****************"
                            type="password"
                            disabled={true}
                        ></input>
                    </div>
                    <div className="">
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setActiveAccountAction(item.id)
                            }}
                            variant="secondary"
                            type="submit"
                            className="w-full h-10 font-semibold"
                        >
                            SELECT ACCOUNT{' '}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const ActiveKeys = ({ emisorId }) => {
    const keysbybit = useSelector((state) => state.dashboardReducer.keysbybit)

    return (
        <div>
            {keysbybit
                .filter((v) => v.linkedAccount === emisorId)
                .map((item, index) => {
                    return (
                        <ActiveKey
                            key={`active-key-${item.id}`}
                            item={item}
                            main={index === 0}
                        />
                    )
                })}
        </div>
    )
}

export default ActiveKeys
