import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { useMemo } from 'react'

import nomadzLandsAbi from '../../abi/nomadzLandsAbi.json'
import igniiteAbi from '../../abi/IgniiteAbi.json'
import erc20ContractAbi from '../../abi/Erc20ContractAbi.json'
import stakingContractAbi from '../../abi/IgniiteStaking.json'
import PaymentContractAbi from '../../abi/PaymentAbi.json'

const getContract = (abi, address) => {
    return new Contract(address, new utils.Interface(abi))
}

const getERC721Contract = (address, signer) => {
    return getContract(nomadzLandsAbi, address, signer)
}

const getERC721IgniiteContract = (address, signer) => {
    return getContract(igniiteAbi, address, signer)
}

const getERC721StakingContract = (address, signer) => {
    return getContract(stakingContractAbi, address, signer)
}

const getERC20Contract = (address, signer) => {
    return getContract(erc20ContractAbi, address, signer)
}

const getContractPayment = (address, signer) => {
    return getContract(PaymentContractAbi, address, signer)
}

export const useERC721Contract = (address) => {
    return useMemo(() => getERC721Contract(address), [address])
}

export const useERC721IgniiteContract = (address) => {
    return useMemo(() => getERC721IgniiteContract(address), [address])
}

export const useERC721StakingContract = (address) => {
    return useMemo(() => getERC721StakingContract(address), [address])
}

export const useERC20Contract = (address) => {
    return useMemo(() => getERC20Contract(address), [address])
}

export const useContractPayment = (address) => {
    return useMemo(() => getContractPayment(address), [address])
}
