/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Input, Form } from 'antd'
import classNames from 'classnames'

const FormItem = Form.Item

const FormikAntInpuWithButton = ({
    prefix,
    formik,
    name,
    placeholder,
    value,
    icon,
    onClickButton,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched
    const $value = value ?? formik?.values?.[name]

    return (
        <FormItem
            validateStatus={
                submittedError || touchedError ? 'error' : 'success'
            }
            help={submittedError || touchedError ? hasError : false}
        >
            <Input.Group compact className="flex">
                <Input
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    bordered={false}
                    value={$value}
                    {...props}
                    className={classNames(
                        `h-[60px] text-xs  rounded-sm bg-palette-black-725 hover:outline-none hover:ring-1 hover:ring-palette-gray-700 placeholder:text-palette-gray-700`
                    )}
                    style={{ width: 'calc(100% - 95px)', fontSize: '12px' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <button
                    onPointerDown={() => onClickButton()}
                    className="h-[60px] w-[80px] text-2xl text-palette-brown-901 border border-palette-brown-901 rounded-md ml-auto flex justify-center items-center"
                >
                    {icon}
                </button>
            </Input.Group>
        </FormItem>
    )
}

export default FormikAntInpuWithButton
