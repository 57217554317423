/* eslint-disable no-unused-vars */
import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { EpochsSelector } from './EpochsSelector'
import { useFetchEpochsBalances, useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'
import { epochQuantitySelect } from 'src/store/reducers/staffReducer/staff.actions'
import { useDispatch } from 'react-redux'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend )


export const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            position: 'right',
            grid: {
                drawBorder: true,
                color: 'rgba(116, 116, 116, 0.2)',
                opacity: '0.5',
            },
        },
    },

    plugins: {
        growthCounter: false,
        centerText: false,
        tooltip: {
            callbacks: {
                label: ((tooltipItem, data) => {
                    if (tooltipItem.datasetIndex === 0) {
                        const values = tooltipItem.raw
                        return `Negative ROI: ${formatNumberToCurrency(values[0] - values[1])}`
                    }
                    return `${tooltipItem.dataset.label}: ${formatNumberToCurrency(tooltipItem.raw)}`
                })
            }
        },
        legend: {
            position: 'bottom',
            align: 'start',
            labels: {               
                boxWidth: 30,
                boxHeight: 5,
                generateLabels(chart){
                    // Create labels with custom order
                    const lab = []
                    lab.push({
                        text: 'Initial Balance',
                        fillStyle: 'gray',
                        strokeStyle: 'gray',
                    })
                    lab.push({
                        text: 'ROI',
                        fillStyle: 'rgba(141, 121, 63, 0.6)',
                        strokeStyle: 'rgba(141, 121, 63, 0.6)',
                    })
                    lab.push({
                        text: 'Negative ROI',
                        fillStyle: 'rgba(115, 2, 23, 0.7)',
                        strokeStyle: 'rgba(115, 2, 23, 0.7)',
                    })

                    return lab
                },
                font: {
                    family: "'Maven Pro'",
                    weight: '500',
                },
            },
        },
    },
    responsive: true,
}

export const EpochsChart = ({
    epochsBalances = [],
    epochQuantity = [],
    epochSelector,
}) => {
    // const [selected, setSelected] = useState('ALL')
    const dispatch = useDispatch()

    const labels = epochsBalances.map((e) => `Epoch ${e.epoch}`)

    const sets = epochsBalances.reduce(
        (acc, curr) => {
            acc.init.push(curr.initialBalance)
            if (curr.pnl >= 0) {
                acc.posRoi.push(curr.pnl)
                acc.negRoi.push(0)
            } else {
                acc.posRoi.push(0)
                const dif = curr.pnl + curr.initialBalance
                acc.negRoi.push([curr.initialBalance, dif])
            }
            return acc
        },
        { init: [], posRoi: [], negRoi: [] }
    )

    const data = {
        labels,
        datasets: [
            {
                label: 'Negative ROI',
                data: sets.negRoi,
                backgroundColor: 'rgba(115, 2, 23, 0.7)',
                categoryPercentage: 0.7,
                stack: 'Stack 1',
            },
            {
                label: 'Initial Balance',
                data: sets.init,
                backgroundColor: function (context) {
                    const chart = context.chart
                    const { ctx, chartArea } = chart

                    if (!chartArea) {
                        return null
                    }
                    return getGradient(ctx, chartArea)
                },
                grouped: false,
                stack: 'Stack 2',
            },
            {
                label: 'ROI',
                data: sets.posRoi,
                backgroundColor: 'rgba(141, 121, 63, 0.6)',
                grouped: false,
                stack: 'Stack 2',
            },
        ],
    }

    const getGradient = (ctx, chartArea) => {
        const gradiendBg = ctx.createLinearGradient(0, 0, 0, 300)
        gradiendBg.addColorStop(0.5, 'rgba(199, 199, 199, 0.6)')
        gradiendBg.addColorStop(1, 'rgba(100, 100, 100, 0.18) ')
        return gradiendBg
    }

    return (
        <div className="flex flex-col border border-palette-gray-500 rounded p-5">
            <div className="flex flex-row justify-between">
                <div className="font-medium text-palette-gray-100 text-base">
                    EPOCHS
                </div>
                <EpochsSelector
                    selected={epochQuantity}
                    onSelect={(v) => dispatch(epochSelector(v))}
                />
            </div>
            <div className="h-80">
                <Bar options={options} data={data} />
            </div>
        </div>
    )
}
