import React, { useMemo, useState } from 'react'
import Button from '../../Buttons/Button'
import { BiPlus } from 'react-icons/bi'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import { Input } from 'antd'
import { CustomerElement } from './CustomerElement'
import { AddCustomerModal } from 'src/views/AdminView/AddCustomerModal'
import { useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'
import { CustomerCard } from './CustomerCard'
import { AddCustomerToEpochModal } from 'src/views/AdminView/AddCustomerToEpochModal'

const { Search } = Input


const CustomersAdmin = () => {
    const [search, setSearch] = useState('')
    const [visible, setVisible] = useState(false)
    const [userToEpoch, setUserToEpoch] = useState()
    const [visibleEpoch, setVisibleEpoch] = useState(false)
    const buttonSize = useResponsiveValue({
        base: 'small',
        lg: 'normal',
    })
    const [selected, setSelected] = useState(0)
    const {salesStaffMembers} = useStaffState()
    const customers = useMemo(() => {
        return salesStaffMembers?.[selected]?.clients ?? []
    }, [selected, salesStaffMembers])
    
    
    
    const sellers = useMemo(() => {
        return salesStaffMembers.filter(
            (seller) => seller?.name.indexOf(search) !== -1
        )
        
    },[search, salesStaffMembers])

    const handleAddToEpoch = (userId) => {
        setVisibleEpoch(true)
        setUserToEpoch(userId)
    }

    return (
        <div className="flex-1 flex flex-col w-full h-full min-h-full pl-1">
            <AddCustomerModal
                visible={visible}
                onClose={() => setVisible(false)}
                selectedSeller={salesStaffMembers?.[selected]}
            />
            <AddCustomerToEpochModal
                visible={visibleEpoch}
                onClose={() => setVisibleEpoch(false)}
                user={userToEpoch}
            />
            <div className="flex justify-between mb-5">
                <div className="text-palette-gray-100 font-maven text-xl font-normal">
                    CUSTOMERS
                </div>
                <Button
                    size={buttonSize}
                    onClick={() => setVisible(true)}
                    variant="light"
                    className="w-full lg:w-auto text-sm lg:text-lg h-8"
                >
                    <BiPlus />
                    <div className="font-semibold text-base">ADD</div>
                </Button>
            </div>
            <div className="h-full flex-1 flex flex-row ">
                <div className=" flex flex-col h-full bg-black w-60 border-r border-[#444444] mr-6">
                    <div className="mb-3 font-regular text-[#878787] text-base tracking-wide">
                        SELLERS
                    </div>
                    <div className="mb-1 ">
                        <Search
                            placeholder="Search seller"
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-full pr-5"
                        />
                    </div>
                    <div className="flex flex-col flex-1 relative ">
                        <div className="absolute inset-0 overflow-y-auto overflow-x-hidden scroll-generic pt-2">
                            {sellers.map((seller, index) => (
                                <CustomerElement
                                    onSelected={() => setSelected(index)}
                                    key={index}
                                    selected={selected === index}
                                    name={seller.name}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="grow grid grid-cols-2 auto-rows-min gap-4">
                    {customers.map((customer, index) => (
                        <CustomerCard
                            key={`seller-${customer.id}`}
                            name={customer.name}
                            initialBalance={customer.initialBalance}
                            pnl={customer.pnl}
                            handleAddToEpoch={handleAddToEpoch}
                            userId={customer.userId}
                            epochs={customer.epochs}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CustomersAdmin
