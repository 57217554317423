/* eslint-disable no-unused-vars */
import React from 'react'
import { BiDotsVerticalRounded, BiPlus } from 'react-icons/bi'
import {
    formatNumberToCurrency,
    formatNumberToPercentage,
} from 'src/utils/formatNumber'
import { Tag } from 'antd'
import Button from 'src/components/Buttons/Button'

export const CustomerCard = ({
    name,
    initialBalance,
    pnl,
    handleAddToEpoch,
    userId,
    epochs
}) => {
    return (
        <div className="text-white flex flex-col px-5 pb-3 pt-4 rounded-sm border border-[#2B2B2B] bg-[#1f1f1f]">
            <div className="mb-4 relative">
                <div className=" font-medium text-palette-beigde-100 text-lg font-oxanium leading-none ">
                    {name}
                </div>
                <div className="absolute right-0 translate-x-1/2 rounded-full cursor-pointer hover:bg-gray-500/50 text-palette-gray-700  hover:text-white top-1/2 -translate-y-1/2 h-7 w-7 flex transition delay-150 duration-300 ease-in-out">
                    <BiDotsVerticalRounded className="text-xl m-auto" />
                </div>
            </div>
            <div className="flex flex-wrap mb-4">
                {epochs?.map((epoch, index) => (
                    <Tag key={index} color="#716235" className="mb-3">
                        Epoch {epoch}
                    </Tag>
                ))}
                <Tag
                    // size={"small"}
                    // color="#716235"
                    className="mb-3 cursor-pointer flex flex-row w-fit bg-palette-black-700 hover:bg-palette-black-700/80 focus:ring-2 focus:ring-palette-gray-700 focus:ring-opacity-50 text-palette-brown-901 border border-palette-brown-901"
                    onClick={() => handleAddToEpoch(userId)}
                    // variant="light"
                    // className="w-full lg:w-auto text-sm lg:text-lg"
                >
                    <span className="flex items-center text-base">
                        <BiPlus />
                    </span>{' '}
                    ADD
                    {/* <div className="font-semibold text-base">ADD</div> */}
                </Tag>
            </div>
            <div className="flex flex-row w-full gap-4">
                <div className="border-r border-[#2B2B2B] pr-2 flex flex-col">
                    <div className="font-medium font-maven text-xs text-palette-gray-700 mb-3">
                        Initial Amount
                    </div>
                    <div className="tracking-tighter font-medium font-oxanium text-palette-gray-100 mt-auto text-base leading-none">
                        {formatNumberToCurrency(initialBalance)}
                    </div>
                </div>
                <div className="flex-1 flex flex-row">
                    <div className="flex flex-col pr-4">
                        <div className="font-medium font-maven text-xs text-palette-gray-700 mb-3">
                            ROI
                        </div>
                        <div className="text-[#B2955F] text-base font-medium font-oxanium mt-auto leading-none">
                            {formatNumberToCurrency(pnl)}{' '}
                            <span className="text-xs leading-none"> USD</span>{' '}
                        </div>
                    </div>
                    <div className="grow flex items-start justify-center">
                        <div className=" px-5 h-5 flex items-center w-fit rounded-full bg-[#716235] font-oxanium text-xs text-palette-beigde-100">
                            {/* {Number(epochDetails.pnlPerc.toFixed(3))} % */}
                            {formatNumberToPercentage(
                                (Number(pnl) * 100) / Number(initialBalance),
                                false
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
