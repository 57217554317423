import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'

export const getUserExtraInfo = createAsyncThunk(
    'userInfo/getUserInfo',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(`bybit/extrainfouser/`)
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
