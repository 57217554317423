import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state) => state.authReducer

export const selectAuth = createSelector(selectSelf, (self) => self.auth)
export const selectScreenAccess = createSelector(
    selectSelf,
    (self) => self.screen
)
export const selectVerifiedCode = createSelector(
    selectSelf,
    (self) => self.totp.verifiedCode
)
export const selectStaked = createSelector(selectSelf, (self) => self.staked)
export const selectQrBase64 = createSelector(
    selectSelf,
    (self) => self.totp.qrBase64
)
export const selectSignInSteps = createSelector(
    selectSelf,
    (self) => self.signInSteps
)
