/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { CustomersGrid } from 'src/components/SellerComponents/CustomersGrid'
import { SellerComission } from 'src/components/SellerComponents/SellerComission'
import { SellerDetails } from 'src/components/SellerComponents/SellerDetails'
import { Select } from 'antd'
import {
    useFetchClientEpochs,
    useFetchStaffDetails,
    useStaffState,
    useFetchEpochsBalances,
} from 'src/store/reducers/staffReducer/staff.hooks'
import { useDispatch } from 'react-redux'
import { epochQuantitySelect, selectEpoch } from 'src/store/reducers/staffReducer/staff.actions'
import { EpochsChart } from 'src/components/EpochsComponents/EpochsChart'



const SellerView = () => {
    const dispatch = useDispatch()    
    
    useFetchStaffDetails()
    useFetchClientEpochs()
    useFetchEpochsBalances()
    
    const { staffDetails, clientEpochs, epochsBalances, epochQuantity } =
        useStaffState()
    const epochs = [0, ...clientEpochs]
    
    return (
        <div className="flex flex-col mb-10">
            <div className="flex justify-between mb-4">
                <div className="font-oxanium font-bold text-2xl text-[#B2955F]">
                    Seller
                </div>
                <Select
                    defaultValue="All Epochs"
                    style={{
                        width: 120,
                    }}
                    onChange={(val) => dispatch(selectEpoch(val))}
                    options={epochs.map((e) => {
                        return {
                            value: e,
                            label: `${e === 0 ? 'All Epochs' : `EPOCH ${e}`}`,
                        }
                    })}
                />
            </div>
            <SellerDetails details={staffDetails} />
            <div className="grid grid-cols-12 gap-4 auto-rows-min">
                <div className="col-start-1 col-end-4">
                    <SellerComission />
                </div>
                <div className="col-start-4 col-end-13 ">
                    <EpochsChart
                        epochsBalances={epochsBalances}
                        epochQuantity={epochQuantity}
                        epochSelector={epochQuantitySelect}
                    />
                </div>
            </div>
            <CustomersGrid />
        </div>
    )
}
export default SellerView