/* eslint-disable no-unused-vars */
import React, { useRef, useCallback } from 'react'
import CardContainer from 'src/components/Cards/CardContainer'
import { RiLock2Line } from 'react-icons/ri'
import { Progress } from 'antd'
import dayjs from 'dayjs'
import { EpochStatus } from './EpochStatus'
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { selectEpoch } from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import {formatNumberToCurrency} from "../../utils/formatNumber";
import {formatDate} from "../../utils/formatDate";


export const EpochCardCustomer = ({ epoch={} }) => {
    const cardRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const calculateProgress = (initialDate, endDate, id) => {
        const date1 = dayjs(initialDate)
        const date2 = dayjs(endDate)
        const now = dayjs()
        const initialDifference = date1.diff(date2, 'day')
        const actualDifference = date1.diff(now, 'day')
        let progress = Math.abs((actualDifference * 100) / initialDifference)
        if (progress < 0) {
            progress = 100
        }
        return progress
    }
    const handleOnClick = useCallback(
        () => {
            dispatch(selectEpoch(epoch.id))
            navigate('/epochdetails', { replace: true })
        },
        [navigate]
    )
    return (
        <CardContainer
            ref={cardRef}
            className="pt-5 xl:pt-8 cursor-pointer"
            onClick={handleOnClick}
        >
            <div className="flex flex-row w-full px-4 lg:px-5 xl:px-8">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between items-center">
                        <span className="md:text-base lg:text-lg xl:text-2xl font-medium leading-none text-primary font-oxanium text-palette-brown-700 tracking-widest">
                            Epoch {epoch.id}
                        </span>

                        <EpochStatus
                            periodEnd={epoch.period_end}
                            closed={epoch.status}
                        />
                    </div>
                </div>
            </div>
            <div className="px-4 lg:px-5 xl:px-8">
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-t border-[#41403D] flex flex-row justify-between mt-6 py-2  text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        Initial Amount
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.initial_amount).toLocaleString()}
                    </div>
                </div>
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-[#41403D] flex flex-row justify-between py-2 text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        Actual Balance
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.actual_balance).toLocaleString()}
                    </div>
                </div>
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-[#41403D] flex flex-row justify-between py-2 text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        ROI TOTAL(PNL)
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.pnl).toLocaleString()}
                    </div>
                </div>
                <div className="text-xxs md:text-xs lg:text-sm xl:text-md border-b border-[#41403D] flex flex-row justify-between py-2 text-gray-5 dark:text-gray-2">
                    <div className="flex flex-row font-maven font-medium text-[#878787] text-xs">
                        ROI CURRENT PERIOD (PNL)
                    </div>
                    <div className="font-medium text-gray-10 dark:text-gray-3">
                        {formatNumberToCurrency(epoch.pnl_current).toLocaleString()}
                    </div>
                </div>
            </div>
            {epoch.status === false && (
                <div className="h-2/6 relative flex justify-center items-center text-gray-5 dark:text-gray-2 py-3 before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[80%] before:bg-gradient-to-t before:from-palette-gold-100/20 before:to-transparent">
                    <div className="relative mr-2 md:text-base lg:text-lg xl:text-xl text-[#B2955F]">
                        <RiLock2Line />
                    </div>
                    <span className="md:text-sm lg:text-sm xl:text-base leading-5 font-maven font-medium text-[#878787] text-xs">
                        Closed
                    </span>
                </div>
            )}
            {epoch.status && (
                <div className="dark:bg-gray-15 border-t border-[#41403D] pb-3 md:pb-2  xl:pb-3">
                    <div className="px-4 lg:px-5 xl:px-8">
                        <div className="flex flex-row justify-between leading-none mt-4">
                            <div className="flex flex-col">
                                <div className="font-maven font-medium text-[#747474] text-xs mb-2 ">
                                    {dayjs() < dayjs(epoch.period_end)
                                        ? 'Ends on'
                                        : 'Ended on'}
                                </div>
                            </div>
                            <div className="flex flex-col items-end mb-2">
                                <div className="text-xxs md:text-xs xl:text-md text-gray-100 font-oxanium font-medium text-end mb-2">
                                    {formatDate(epoch.period_end)}
                                </div>
                            </div>
                        </div>

                        <div>
                            <Progress
                                percent={calculateProgress(
                                    epoch.period_start,
                                    epoch.period_end,
                                    epoch.id
                                )}
                                showInfo={false}
                                trailColor={'#2A2A2A'}
                                strokeColor={'#716235'}
                            />
                        </div>
                    </div>
                </div>
            )}
        </CardContainer>
    )
}
