import React from 'react'
import classNames from 'classnames'

function Container({ className = '', style, children }) {
    return (
        <div
            className={classNames('bg-palette-black-800', className)}
            style={style || {}}
        >
            {children}
        </div>
    )
}

export default Container
