import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getStaffClients,
    getSalesStaffMembers,
    registerStaff,
    registerClient,
    refreshStaff,
    addUserToEpoch,
    staffDetails,
    staffClientEpochs,
    getEpochBalances,
    getSellerComission,
} from './staff.actions'
import useDeepCompareEffect from "src/hooks/useDeepCompareEffect"
import { sleep } from './../../../utils/promise'
import { notification } from "antd"


const selectSelf = state => state.staff

export const useStaffState = () => {
    return useSelector(selectSelf)
}

export const useFetchSalesStaff = () => {
    const dispatch = useDispatch()
    const { refreshStaff } = useStaffState()
    const fetch = useCallback(async () => {
        try {
            await dispatch(getSalesStaffMembers()).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    useDeepCompareEffect(() => {
        fetch()
    }, [refreshStaff])
}

export const useFetchStaffClients = () => {
    const dispatch = useDispatch()
    
    const fetch = useCallback(async () => {
        try {
            await dispatch(getStaffClients()).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])
    
    useDeepCompareEffect(() => {
        fetch()
    },[])
}

export const useRegisterStaff = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch= useDispatch()

    const action = useCallback(
        async({userName, password, email}) => {
            setLoading(true)
            if(loading) return

            try {
                await sleep(600)
                await dispatch(
                    registerStaff({
                        user: userName,
                        password,
                        email,
                    })
                ).unwrap()
                dispatch(refreshStaff())
                notification.success({
                    // icon: customAntNotifications.success
                    message: 'Create New Account',
                    description: 'You successfully created a new account',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    // icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw error
            } finally {
                setLoading(false)
            }

        },
        [loading, dispatch]
    )

    return { signUp: action, loading, error}
}

export const useRegisterClient = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const action = useCallback(
        async({userName, password, email, seller,epoch, initialBalance}) => {
            setLoading(true)
            if(loading) return

            try {
                await sleep(600)
                await dispatch(
                    registerClient({
                        user: userName,
                        password,
                        email, 
                        seller,
                        epoch, 
                        initialBalance, 
                    })
                ).unwrap()
                dispatch(refreshStaff())
                notification.success({
                    // icon: customAntNotifications.success
                    message: 'Create New Customer',
                    description: 'You successfully created a new customer',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    // icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw error
            } finally {
                setLoading(false)
            }
        },
        [loading, dispatch]
    )
    return {signUp: action, loading, error}
}

export const useAddUserToEpoch = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const action = useCallback(
        async({user, epoch, initialBalance}) => {
            setLoading(true)
            if(loading) return
            try {
                await sleep(600)
                await dispatch(
                    addUserToEpoch({
                        user,
                        epoch,
                        initialBalance
                    })
                ).unwrap()
                dispatch(refreshStaff())
                notification.success({
                    // icon: customAntNotifications.success
                    message: 'Add a User to Epoch',
                    description: 'You successfully added a customer to Epoch',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    // icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw error
            } finally {
                setLoading(false)
            }
        }
    )
    return { addUserToEpoch: action, loading, error }
}

export const useFetchStaffDetails = () => {
    const dispatch = useDispatch()
    const { selectedEpoch } = useStaffState()
    
    const action = useCallback(async () => {
        try {
            await dispatch(staffDetails({ selectedEpoch })).unwrap()
        } catch (err) {
            console.log(err)
        }
    }, [dispatch, selectedEpoch])

    useDeepCompareEffect(() => {
        if(selectedEpoch!== null){
            action()
        }
        
    }, [selectedEpoch])
}

export const useFetchClientEpochs = () => {
    const dispatch = useDispatch()

    const action = useCallback(async () => {
        try {
            await dispatch(staffClientEpochs()).unwrap()
        } catch (err) {
            console.log(err)
        }
    }, [dispatch])

    useDeepCompareEffect(() => {
        action()
    },[])
}

export const useFetchEpochsBalances = () => {
    const dispatch = useDispatch()
    const { epochQuantity } = useStaffState()

    const action = useCallback(async () => {
        try {
            await dispatch(getEpochBalances({epochQuantity})).unwrap()
        } catch (err) {
            console.log(err)
        }
    }, [dispatch, epochQuantity])

    useDeepCompareEffect(() => {
        action()
    }, [epochQuantity])
}

export const useFetchSellerComission = () => {
    const dispatch = useDispatch()
    const { selectedEpoch } = useStaffState()

    const fetch = useCallback(async () => {
        try {
            await dispatch(
                getSellerComission({ selectedEpoch })
            ).unwrap()
        } catch (error) {
            console.log(error)
        }
    }, [dispatch, selectedEpoch])

    useDeepCompareEffect(() => {
        if (selectedEpoch!==null) {
            fetch()
        }
    }, [selectedEpoch])

}