import dayjs from 'dayjs'
import { MONTHLY, DAILY } from '../constants/temporality.constants'

export function getStartDateTemporality(temporality) {
    const current = dayjs()

    let temp = 'weeks'
    if (temporality === MONTHLY) temp = 'months'
    if (temporality === DAILY) temp = 'days'

    return current.subtract(1, temp).startOf('day').format('YYYY-MM-DD')
}
