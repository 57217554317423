import React, { useState, useEffect, useMemo } from 'react'
import { Modal } from 'antd'
import useMediaQuery from './../../../hooks/useMediaQuery'
import MintLoadingVideo from './MintLoadingVideo'
import MintDisplayNft from './MintDisplayNft'

const MintModal = ({ visible, nft, loading, onCancel }) => {
    const [{ showVideo, closable }, setState] = useState({
        showVideo: true,
        closable: false,
    })

    useEffect(() => {
        if (!visible) {
            setState({ showVideo: true, closable: false })
        }
    }, [visible])

    const isLg = useMediaQuery('(min-width: 1024px)')
    const isXl = useMediaQuery('(min-width: 1280px)')

    const width = useMemo(() => {
        if (isXl) return '850px'
        if (isLg) return '70%'
        return '90%'
    }, [isLg, isXl])

    useEffect(() => {
        if (nft && showVideo) {
            setState({ showVideo: false, closable: true })
        }
    }, [nft, showVideo])

    return (
        <Modal
            className="mint-modal"
            centered
            closable={closable}
            visible={visible}
            onCancel={onCancel}
            width={width}
            maskClosable={false}
            footer={null}
        >
            {visible && (
                <React.Fragment>
                    {showVideo ? (
                        <MintLoadingVideo loading={loading} />
                    ) : (
                        <MintDisplayNft {...nft} />
                    )}
                </React.Fragment>
            )}
        </Modal>
    )
}

export default MintModal
