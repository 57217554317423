/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import { HomeOutlined, SettingFilled } from '@ant-design/icons'
import logoIgniite from 'src/assets/images/logo_igniite.png'
import { RiArrowDownSFill } from 'react-icons/ri'
import Collapse from '../Animations/Collapse/index'
import { IoCopyOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useUserInfo } from '../../store/reducers/userInfoReducer/userInfo.hooks'
import { shortenIfAddress } from '@usedapp/core'
import { formatDate, formatDate2 } from '../../utils/formatter'

// const dispatch = useDispatch();
// const setActiveAccountAction = useCallback((account) => dispatch(actions.setActiveAccount(account)), [dispatch])

const GeneralSettingListItem = ({ name, value }) => {
    return (
        <li className=" bg-palette-black-800 border-palette-black-700 px-6 py-3 first:mt-0 mt-1">
            <div className="flex flex-row text-sm">
                <div className="text-gray-100 font-semibold">{name}</div>
                <div
                    className={`ml-auto ${
                        value > 0
                            ? ' text-palette-green-500'
                            : ' text-palette-pink-700'
                    }`}
                >
                    {value > 0 && '+'}
                    {value.toLocaleString('en-US')}%
                </div>
            </div>
        </li>
    )
}

const GeneralSettingList = ({ activekeysbybit, keysbybit }) => {
    return (
        <ul className="list-none">
            {keysbybit.map((item) => {
                const multiplier = (item.id + 1) % 2 === 0 ? 1 : -1
                return (
                    <GeneralSettingListItem
                        key={item.id}
                        name={item.name}
                        value={Math.random() * 500 * multiplier}
                    />
                )
            })}
        </ul>
    )
}

const GeneralSettings = () => {
    const keysbybit = useSelector((state) => state.dashboardReducer.keysbybit)
    const activekeysbybit = useSelector(
        (state) => state.dashboardReducer.activekeysbybit
    )
    const [isExpanded, setExpanded] = useState(true)
    return (
        keysbybit?.length > 0 && (
            <div className="mt-6">
                <div
                    className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none cursor-pointer"
                    onPointerDown={() => {
                        setExpanded((x) => !x)
                    }}
                >
                    <div className="w-7/12">ACCOUNTS SETTINGS</div>
                    <div className="text-lg w-5/12 ml-auto">
                        <RiArrowDownSFill />
                    </div>
                </div>

                <Collapse
                    visible={isExpanded}
                    className="max-h-[285px] overflow-auto scroll-smooth hover:scroll-auto scroll-generic mt-3"
                >
                    <GeneralSettingList
                        keysbybit={keysbybit}
                        activekeysbybit={activekeysbybit}
                    />
                </Collapse>
            </div>
        )
    )
}

const StakingSettings = () => {
    const [isExpanded, setExpanded] = React.useState(false)

    return (
        <div className="mt-6">
            <div className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none">
                STAKING SETTINGS
            </div>

            <div className="bg-palette-black-800 border-palette-black-700 mt-3">
                <ul className="list-none px-6 py-5">
                    <li className="">
                        <div className="flex flex-row text-sm">
                            <div className=" text-palette-gray-700 font-medium">
                                Staked Time
                            </div>
                            <div className="text-gray-100 font-semibold ml-auto">
                                4 Days
                            </div>
                        </div>
                    </li>
                    <li className="mt-6">
                        <div className="flex flex-row text-white text-sm">
                            <div className="text-palette-gray-700 font-medium">
                                Status
                            </div>
                            <div className="ml-auto py-[0.3rem] px-3 font-semibold text-palette-yellow-700 bg-palette-yellow-700 bg-opacity-10 rounded-md">
                                Pending
                            </div>
                        </div>
                    </li>
                    <li className="mt-6">
                        <button className="text-sm w-full py-2 rounded-sm text-palette-brown-901 font-semibold border border-palette-brown-901 flex justify-center items-center">
                            PAY
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const PaymentAndStaking = ({ onClose }) => {
    const navigate = useNavigate()
    const [isExpanded, setExpanded] = React.useState(false)

    return (
        <div className="mt-6">
            <div className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none">
                PAYMENTS AND STAKING
            </div>

            {/* <div
                className="text-sm font-semibold text-palette-beigde-200 cursor-pointer mt-3"
                onPointerDown={() => {
                    navigate('/payments')
                    onClose()
                }}
            >
                GO TO PAYMENTS AND STAKING
            </div> */}
        </div>
    )
}

const MyReferal = () => {
    return (
        <div className="mt-6">
            <div className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none">
                MY REFERRALS
            </div>
            <div className="bg-palette-black-800 border-palette-black-700 p-6 mt-3">
                <div className="text-sm text-palette-gray-700">REFERAL ID</div>
                <div className="flex flex-row">
                    <div className=" text-palette-gray-100 font-bold text-xl">
                        ASD4DFSD
                    </div>
                    <div className="ml-auto text-palette-brown-901 text-xl cursor-pointer">
                        <IoCopyOutline />
                    </div>
                </div>
            </div>
        </div>
    )
}

const SideBar = ({ onClose, logout }) => {
    const { username, address, dateJoined } = useUserInfo()
    const keysbybit = useSelector((state) => state.dashboardReducer.keysbybit)
    const activekeysbybit = useSelector(
        (state) => state.dashboardReducer.activekeysbybit
    )
    const navigate = useNavigate()

    return (
        <div className="w-full">
            <div className="">
                <div
                    className="text-sm font-semibold text-palette-beigde-200 cursor-pointer"
                    onPointerDown={() => {
                        navigate('/profile')
                        onClose()
                    }}
                >
                    GO TO MY PROFILE
                </div>
            </div>
            <div className="flex flex-row mt-6">
                <div className="relative text-xs text-palette-gray-700 text-left font-semibold p-0 mt-1 mr-4 select-none">
                    USERNAME:
                </div>
                <div className="font-oxanium font-bold text-xl text-palette-brown-700">
                    {username}
                </div>
            </div>
            <div className="flex flex-row space-x-1 items-end mt-3 gap-2">
                <div className=" text-sm font-semibold text-center bg-palette-black-800 border-palette-black-700 py-2 text-gray-100">
                    {address ? shortenIfAddress(address) : ''}
                </div>
                <div className="relative flex-1 text-xs text-palette-gray-700 font-semibold select-none mb-[0.20rem] text-right">
                    Joined {dateJoined && formatDate2(dateJoined)}
                </div>
            </div>
            {/* <GeneralSettings /> */}
            {/* <PaymentAndStaking onClose={onClose} /> */}
            {/* <StakingSettings /> */}
            {/* <MyReferal /> */}
            {/* <div className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none mt-6">
                SETTINGS
            </div> */}
            <div
                className="text-xs text-palette-gray-700 text-left font-semibold flex flex-row w-full p-0 m-0 select-none mt-6 cursor-pointer"
                onPointerDown={() => {
                    logout()
                }}
            >
                LOG OUT
            </div>
            {/* <ul className="relative px-1">
                {keysbybit.map((item) => {
                    return (
                        <li
                            key={item.id}
                            onClick={() => {
                                alert(
                                    'Active account: ' +
                                        item.id +
                                        '-' +
                                        item.name
                                )
                            }}
                            className="my-2"
                        >
                            <a
                                className="flex items-center text-sm py-4 px-6 overflow-hidden
                                text-palette-black-400 text-ellipsis 
                                whitespace-nowrap rounded 
                                hover:bg-red-600
                                hover:text-yellow-500
                                active:bg-violet-700 focus:outline-none 
                                transition duration-300 ease-in-out"
                            >
                                {item.id === activekeysbybit ? (
                                    <HomeOutlined className="mr-3" />
                                ) : (
                                    <SettingFilled className="mr-3" />
                                )}

                                <span
                                    className={
                                        item.id === activekeysbybit
                                            ? 'text-palette-brown-800 hover:text-blue-600 '
                                            : 'text-palette-black-400   hover:text-green-800 '
                                    }
                                >
                                    {item.id === activekeysbybit
                                        ? item.name + ' *'
                                        : item.name}
                                </span>
                            </a>
                        </li>
                    )
                })}
            </ul>

            <ul className="relative px-1">
                <li className="relative ">
                    <a
                        className=" flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-100 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                        href="#!"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            className="w-3 h-3 mr-3"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                            ></path>
                        </svg>
                        <span>Non-collapsible link</span>
                    </a>
                </li>
            </ul>

            <ul className="relative px-1">
                <li className="relative">
                    <a
                        className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-100 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                        href="#!"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            className="w-3 h-3 mr-3"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"
                            ></path>
                        </svg>
                        <span>Non-collapsible link</span>
                    </a>
                </li>
            </ul> */}
        </div>
    )
}

export default SideBar
