import React from 'react'
import { Outlet } from 'react-router-dom'
// import AlertManager from '../AlertManager'
import Header from '../header'

const LogedLayout = () => {
    return (
        <div className="bg-palette-black-700 h-screen overflow-auto min-w-[375px]">
            <Header />
            <main className="mx-auto mt-8 max-w-[1340px] px-5 2xl:px-0">
                {/* <AlertManager /> */}
                <div className="mt-10">
                    <Outlet />
                </div>
            </main>
        </div>
    )
}

export default LogedLayout
