/* eslint-disable no-unused-vars */
import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Title } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { formatNumberToCurrency, formatNumberToPercentage } from 'src/utils/formatNumber'

const centerText = {
    id: 'centerText',
    afterDatasetsDraw(chart, args, options) {
        const {
            ctx,
            chartArea: { left, right, top, bottom, width, height },
        } = chart
        ctx.save()

        ctx.font = '600 14px Maven Pro'
        ctx.fillStyle = 'rgb(116,116,116)'
        ctx.textAlign = 'center'
        ctx.fillText(`MY CONTRIBUTION %`, width / 2, height / 2 + top)
        ctx.restore()

        ctx.font = '500 24px Oxanium'
        ctx.fillStyle = 'rgb(191,191,191)'
        ctx.textAlign = 'center'
        // const text = Number(chart.data.datasets[0].data[0].tofixed(3))
        const text = formatNumberToPercentage(chart.data.datasets[0].data[0])
        ctx.fillText(text, width / 2, height / 2 + top + 30)
    },
}

ChartJS.register(ArcElement, Tooltip, Title, centerText)

const options = {
    maintainAspectRatio: false,
    // responsive: true,
    plugins: {
        centerText,
        growthCounter: false
    },
}

export const UserContributionMobile = () => {
    const { epochsByUser, tradesHistory, dailyPnL, details, epochDetails } = useHistoryTradesState()

    const myContribution = epochDetails.userContributionPerc.toFixed(3)
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: [
                    myContribution,
                    100 - myContribution,
                ],
                backgroundColor: ['rgba(141,121,63)', 'rgb(42,42,42)'],

                borderWidth: 0,
                cutout: '90%',
            },
        ],
    }
    return (
        <div className="relative md:hidden col-span-12 md:col-span-3 p-5 rounded	md:h-full border-palette-black-725 border-2 bg-palette-black-600 before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[15%] before:bg-gradient-to-t before:from-palette-gold-200 ">
            {/* <div>ROI{epochDetails.pnl}</div>
            <div>ROI% {epochDetails.pnlPerc}</div>
            <div>My contribution{epochDetails.userContributionPerc}</div>
            */}
            
            <div className="relative flex flex-col items-center space-y-10">
                <div className='h-44'>
                    <Doughnut options={options} data={data} />
                </div>
                <div className='flex flex-row w-full justify-between md:flex-col'>
                    <div className='flex w-1/2 flex-col items-center space-y-3'>
                        <div className='font-maven font-medium text-xs md:text-sm text-palette-gray-700'>
                            MY CONTRIBUTION
                        </div>
                        <div className='font-oxanium font-normal text-lg md:text-3xl text-palette-gray-100'>
                            {formatNumberToCurrency(epochDetails.userContribution)} USD
                        </div>
                    </div>
                    <div className='flex w-1/2 flex-col space-y-3'>
                        <div className='font-maven font-medium text-xs md:text-sm text-palette-gray-700'>
                            MY POTENTIAL ROI
                        </div> 
                        <div className='font-oxanium font-normal text-lg md:text-3xl text-palette-gold-100'>
                            {formatNumberToCurrency(epochDetails.userPotentialROI)} USD
                        </div>
                        <div className="px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-sm text-palette-beigde-100">
                            {formatNumberToPercentage(epochDetails.pnlPerc)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
