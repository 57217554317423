import { createReducer } from '@reduxjs/toolkit'
import {
    login,
    updateScreenAccess,
    createQr2fa,
    updateStaked,
    verify2fa,
    updateSignInSteps,
    setAuth,
} from './auth.actions'

export const SIGN_IN_SCREEN = 1
export const LOG_IN_SCREEN = 2
export const FORGOT_PASSWORD_SCREEN = 3

export const initialState = {
    token: '',
    auth: false,
    screen: 0,
    staked: false,
    isSigningUp: false,
    isLogingIn: false,
    referalCode: '',
    signInSteps: {
        stake: false,
        scan: false,
    },
    totp: {
        qrBase64: '',
        verifiedCode: false,
    },
}

const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(login.fulfilled, (state, { payload }) => {
            localStorage.setItem('token', payload.token)
            state.token = payload.token
        })
        .addCase(setAuth, (state) => {
            state.auth = true
        })

        .addCase(updateScreenAccess, (state, { payload }) => {
            state.screen = payload
        })
        .addCase(
            createQr2fa.fulfilled,
            (state, { payload: { imageBase64 } }) => {
                const data = JSON.parse(imageBase64)

                state.totp.qrBase64 = data.res
            }
        )
        .addCase(createQr2fa.pending, (state) => {
            state.totp.qrBase64 = ''
        })
        .addCase(updateStaked, (state, { payload }) => {
            state.staked = payload
        })
        .addCase(verify2fa.fulfilled, (state) => {
            localStorage.setItem('verified', true)
            state.totp.qrBase64 = ''
            state.totp.verifiedCode = true
            state.screen = 0
            state.signInSteps = {
                stake: false,
                scan: false,
            }
        })
        .addCase(updateSignInSteps, (state, { payload }) => {
            const incomingUpdate = Object.keys(payload).reduce((acc, key) => {
                if (typeof state.signInSteps[key] !== 'undefined') {
                    return { ...acc, [key]: payload[key] }
                }
                return acc
            }, {})

            state.signInSteps = {
                ...state.signInSteps,
                ...incomingUpdate,
            }
        })
})

export default authReducer
