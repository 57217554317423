import { createSlice } from '@reduxjs/toolkit'
import { getTradesByUserId } from './referralUserTrades.actions'

const referralUserTradesSlice = createSlice({
    name: 'admintUserTrades',
    initialState: {
        id: '',
        userId: '',
        userUsername: '',
        userEmail: '',
        statusPayment: '',
        activeTrades: [],
        closedTrades: [],
        pnl: '',
        profitFee: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            getTradesByUserId.fulfilled,
            (
                state,
                {
                    payload: {
                        active_Trades: activeTrades,
                        closed_Trades: closedTrades,
                        user_Id: userId,
                        user_Username: userUsername,
                        user_Email: userEmail,
                        status_Payment: statusPayment,
                        profit_Fee: profitFee,
                        ...v
                    },
                }
            ) => {
                return {
                    ...state,
                    activeTrades,
                    closedTrades,
                    userId,
                    userUsername,
                    userEmail,
                    statusPayment,
                    profitFee,
                    ...v,
                }
            }
        )
    },
})

export default referralUserTradesSlice.reducer
