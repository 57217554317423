import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useMemo, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { WEEKLY } from 'src/constants/temporality.constants'
import { DAILY } from '../../constants/temporality.constants'
import dayjs from 'dayjs'
import { formatNumberToPercentage } from 'src/utils/formatNumber'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip)



const DashboardDailyPnL = ({ graphicData = [], temporality }) => {
    const [growthPerc, setGrowthPerc] = useState(0)
    // change growth Percentage each time data changes
    useEffect(() => {
        if (graphicData.length > 0) {
            setGrowthPerc(graphicData[graphicData.length - 1].growth)
        } else {
            setGrowthPerc(0)
        }
    }, [graphicData])

    const $graphicData = useMemo(() => {
        const labels = graphicData.map((v) => {
           const date = dayjs(v.date)

           if (temporality === WEEKLY) {
               return date.format('dddd')
           }
           if (temporality === DAILY) {
               return date.format('hh')
           }
           return date.format('MMMM D')
        })
        // Object with with win and losses
        const sets = graphicData.reduce(
            (acc, v) => {
                
                if (v.pnlUsd >= 0) {
                    acc.win.push(v.pnlUsd)
                    acc.losses.push(0)
                } else {
                    acc.win.push(0)
                    acc.losses.push(v.pnlUsd)
                }
                return acc
            },
            { win: [], losses: [] }
        )

        return {
            labels,
            datasets: [
                {
                    label: 'Wins',
                    data: sets.win,
                    // borderColor: 'rgb(0, 109, 65)',
                    backgroundColor: 'rgb(0, 109, 65)',
                    borderWidth: 1,
                },
                {
                    label: 'Losses',
                    data: sets.losses,
                    // borderColor: 'rgb(244, 78, 103)',
                    backgroundColor: 'rgb(162,50,66)',
                    borderWidth: 1,
                },
            ],
        }
    }, [graphicData, temporality])

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            centerText: false,
            title: {
                text: 'DAILY PnL',
                display: true,
                align: 'start',
                color: '#BFBFBF',
                padding: {
                    bottom: 50,
                },
                font: {
                    family: "'Maven Pro', 'sans-serif'",
                    size: 16,
                    weight: 400,
                },
            },
            growthCounter: {
                text:
                    formatNumberToPercentage(growthPerc),                    
                // moves the perc to the left and right
                off: 30,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                position: 'right',
                grid: {
                    drawBorder: true,
                    color: 'rgba(116, 116, 116, 0.2)',
                    opacity: '0.5',
                },
            },
        },
    }

    return (
        <div className="col-span-12 h-96 md:col-start-1 md:col-end-13 p-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <Bar options={options} data={$graphicData} />
        </div>
    )
}

DashboardDailyPnL.propTypes = {}

export default DashboardDailyPnL
