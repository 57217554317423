import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { sleep } from '../../../utils/promise'
import useAuth from '../../../hooks/useAuth'
import useStakesNFtId from 'src/hooks/web3/nftsHooks/useStakesNFtId'
import { useUserInfo } from '../userInfoReducer/userInfo.hooks'
import {
    selectScreenAccess,
    selectSignInSteps,
    selectAuth,
    // selectStaked,
    selectVerifiedCode,
    selectQrBase64,
} from './auth.selectors'
import {
    createQr2fa,
    verify2fa,
    updateScreenAccess,
    sendEmail,
    updateSignInSteps,
    updateStaked,
} from './auth.actions'

export const useScreenAccess = () => useSelector(selectScreenAccess)
export const useSignInSteps = () => useSelector(selectSignInSteps)

export const useIsLoggedIn = () => {
    return useSelector(selectAuth)
}

export const useIsStaked = () => {
    // const staked = useSelector(selectStaked)

    return true
}

export const useIsAuth = () => {
    const verifiedCode = useSelector(selectVerifiedCode)
    const auth = useSelector(selectAuth)

    return useMemo(() => {
        return auth && !verifiedCode
    }, [verifiedCode, auth])
}

export const useQrBase64 = () => useSelector(selectQrBase64)

export const useGenerate2faQR = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const base64 = useQrBase64()
    const dispatch = useDispatch()

    const createQr = useCallback(async () => {
        setLoading(true)
        if (loading) return
        try {
            await sleep(1000)
            await dispatch(createQr2fa()).unwrap()
        } catch (error) {
            console.log('Error Generate QR:', error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }, [dispatch])

    useEffect(() => {
        createQr()
    }, [])

    return { base64, loading, error, setError, reload: createQr }
}

export const useVerifyCode = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const verifyCode = useCallback(
        async (code) => {
            setLoading(true)
            if (loading) return
            try {
                await sleep(1000)
                await dispatch(verify2fa(code)).unwrap()
            } catch (error) {
                console.log('Error Generate QR:', error)
                setError('Invalid Code')
            } finally {
                setLoading(false)
            }
        },
        [dispatch]
    )

    return { loading, error, verifyCode }
}

export const useUpdateScreenAccess = () => {
    const dispatch = useDispatch()

    return useCallback(
        (screen) => dispatch(updateScreenAccess(screen)),
        [dispatch]
    )
}
export const useSendEmail = () => {
    const dispatch = useDispatch()

    return useCallback((payload) => dispatch(sendEmail(payload)), [dispatch])
}

export const useResetScreenAccess = (cancelReset) => {
    const screen = useScreenAccess()
    const { logout } = useAuth()

    useEffect(() => {
        if (screen > 0 && !cancelReset) {
            logout()
        }
    }, [screen, cancelReset])
}

export const useUpdateSignInSteps = () => {
    const dispatch = useDispatch()

    return useCallback(
        (payload) => dispatch(updateSignInSteps(payload)),
        [dispatch]
    )
}

export const useUpdatedStaked = () => {
    const dispatch = useDispatch()

    return useCallback((payload) => dispatch(updateStaked(payload)), [dispatch])
}

export const useCheckIfStaked = () => {
    const { address, isSuperuser } = useUserInfo()

    const stakedTokenId = useStakesNFtId(address)
    const updateStaked = useUpdatedStaked()
    const isStaked = useIsStaked()

    const handleUpdate = useCallback(() => {
        if (isSuperuser) return
        if (!isStaked && stakedTokenId > 0) {
            updateStaked(true)
        }
        if (isStaked && stakedTokenId === 0) {
            updateStaked(false)
        }
    }, [stakedTokenId, isStaked, isSuperuser])

    useEffect(() => {
        handleUpdate()
    }, [stakedTokenId, isStaked])
}
