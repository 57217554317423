import React from 'react'
import ConfirmModal from '../Modals/GenericModals/ConfirmModal'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

const ClaimConfirm = ({
    visible,
    onClose,
    onConfirm,
    loading = false,
    epoch,
    amount
}) => {
    return (
        <ConfirmModal
            visible={visible}
            onClose={onClose}
            onConfirm={onConfirm}
            loading={loading}
        >
            <div className="flex flex-col gap-2 text-center">
                <div className="mx-auto text-xl text-palette-gray-700 font-semibold ">
                    Are you sure you want to claim {formatNumberToCurrency(amount)} from Epoch {epoch}?
                </div>
            </div>
        </ConfirmModal>
    )
}

export default ClaimConfirm
