import React from 'react'

const StatsLegend = ({ value }) => {
    return (
        <div className="font-medium text-[10px] lg:text-xs text-palette-brown-901">
            {value} TRADES
        </div>
    )
}

export default StatsLegend
