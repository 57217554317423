import React from 'react'

export const PreviewTable = ({headers, rows}) => {
  return (
    <div className='mb-20'>

      <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium tracking-widest text-sm mb-3">
          PREVIEW
      </div>
      <table className="table-auto border border-collapse border-palette-black-700 w-full rounded-t-lg overflow-hidden">
                <tbody>
                    <tr className="">
                        {headers.map((element) => {
                            return (
                                <th
                                    className="text-palette-gray-700 text-[11px] text-center align-middle py-5 bg-palette-black-725 px-3 "
                                    key={element}
                                >
                                    {element}
                                </th>
                            )
                        })}
                    </tr>                    
                    {rows.map((item, index) => {
                            return (
                                <tr
                                    key={index}
                                    className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 "
                                >
                                    {Object.values(item).map((val, index) => (
                                        <td
                                            key={index}
                                            className="py-8 font-semibold"
                                        >
                                            {val}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                </tbody>
            </table>
    </div>
  )
}
