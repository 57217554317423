import React from 'react'
import NoLoggedHeader from '../noLoggedHeader'

const noLoggedLayout = ({ children, enableNavbar = false }) => {
    return (
        <div className="bg-palette-black-700 h-screen min-w-[375px] overflow-auto pb-16">
            {enableNavbar && <NoLoggedHeader />}
            <div className="flex relative justify-center pt-16 px-5 2xl:px-0">
                {children}
            </div>
        </div>
    )
}

export default noLoggedLayout
