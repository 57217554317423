/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { Checkbox, Modal, Steps } from 'antd'

import Button from 'src/components/Buttons/Button'
import { FaCheck } from 'react-icons/fa'
import { useEthers } from '@usedapp/core'
import {
    useLoadBackup,
    useResetMintRequirements,
    useRequirementsUpdateAccount,
    useUpdateMintRequirements,
} from 'src/store/reducers/mintRequirementsReducer/mintRequirementsReducer'
import useAuthW3 from './../../../hooks/web3/useAuthW3'
import { useMintRequirement } from './../../../store/reducers/mintRequirementsReducer/mintRequirementsReducer'
import { useSaveBackUp } from 'src/store/reducers/mintRequirementsReducer/mintRequirementsBackup'
import classNames from 'classnames'

const { Step } = Steps

const MintRequirementMetamask = ({ account, loginMetamask }) => {
    return (
        <div className="">
            <div className="mb-12">
                <div className="font-bold text-palette-brown-901">
                    1. METAMASK INSTALLED
                </div>
                <div className="ml-[5%] mt-3">
                    <div>
                        <div className="font-medium text-palette-gray-100 tracking-wide">
                            Install MetaMask from your browser
                        </div>
                        <a
                            href="https://metamask.io/download/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-palette-brown-901 font-medium text-sm mt-2 tracking-wide"
                        >
                            https://metamask.io/download/
                        </a>
                    </div>
                    <div className="mt-6">
                        <div className="font-medium text-palette-gray-100 tracking-wide">
                            Learn how to install metamask step-by-step with our
                            manual:
                        </div>
                        <a
                            href="https://igniite.fra1.digitaloceanspaces.com/4-%20HOW%20TO%20INSTALL%20METAMASK.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-palette-brown-901 font-medium text-sm mt-2 tracking-wide"
                        >
                            HOW TO INSTALL METAMASK
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <Button
                    variant="light"
                    size="normal"
                    className="rounded-none w-48"
                    onClick={() => loginMetamask()}
                    disabled={account}
                >
                    {!account ? 'CONNECT' : 'CONNECTED'}
                </Button>
            </div>
        </div>
    )
}

const MintRequirementAccount = ({ createdBitso }) => {
    // const [] = useState()
    const updateMintRequirements = useUpdateMintRequirements()
    const handleUpdate = ({ target }) => {
        const { name, checked } = target
        updateMintRequirements({ [name]: checked })
    }

    return (
        <div className="">
            <div className="mb-12">
                <div className="font-bold text-palette-brown-901">
                    2. CREATE EXCHANGE ACCOUNT
                </div>
                <div className="ml-[5%] mt-3">
                    <div>
                        <div className="font-medium text-palette-gray-100 tracking-wide">
                            Bitso homepage:
                        </div>
                        <a
                            href="https://bitso.com/register?ref=klxyd"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-palette-brown-901 font-medium text-sm mt-2 tracking-wide"
                        >
                            https://bitso.com/
                        </a>
                    </div>
                    <div className="mt-6">
                        <div className="font-medium text-palette-gray-100 tracking-wide">
                            Learn how to create your Bitso account step-by-step
                            with our manual:
                        </div>
                        <a
                            href="https://igniite.fra1.digitaloceanspaces.com/1-%20HOW%20TO%20CREATE%20A%20BITSO%20ACCOUNT.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-palette-brown-901 font-medium text-sm mt-2 tracking-wide"
                        >
                            HOW TO CREATE YOUR BITSO ACCOUNT
                        </a>
                    </div>{' '}
                    <div className="flex flex-row mt-8">
                        <div className="flex flex-row gap-3 items-center">
                            <Checkbox
                                name="createdBitso"
                                checked={createdBitso}
                                onChange={handleUpdate}
                            />
                            <div className=" font-medium text-palette-gray-700 text-sm">
                                I already have an account
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MintRequirementFunds = ({
    bitsoAccountFunds,
    usdtTransfered,
    ethTransfered,
}) => {
    const [step, setStep] = useState(0)
    const updateMintRequirements = useUpdateMintRequirements()
    const handleUpdate = ({ target }) => {
        const { name, checked } = target
        updateMintRequirements({ [name]: checked })
    }

    return (
        <div>
            {' '}
            <div className="">
                <div className="font-bold text-palette-brown-901">3. FUNDS</div>
                <div className="mt-5 flex flex-row">
                    <div className="w-3/12 flex flex-col gap-7 pr-8 pt-[5%]">
                        <div
                            onClick={() => setStep(0)}
                            className="flex flex-row gap-4 hover:cursor-pointer"
                        >
                            <div
                                className={`w-4 h-4 rounded-full box-border ${
                                    bitsoAccountFunds
                                        ? 'bg-palette-brown-901'
                                        : 'border border-palette-gray-700'
                                }  flex justify-center items-center`}
                            >
                                {bitsoAccountFunds && (
                                    <FaCheck className=" text-palette-black-700" />
                                )}
                            </div>
                            <div
                                className={classNames({
                                    'text-palette-brown-901 underline':
                                        step === 0,
                                })}
                            >
                                Bitso
                            </div>
                        </div>
                        <div
                            onClick={() => setStep(1)}
                            className="flex flex-row gap-4 hover:cursor-pointer"
                        >
                            <div
                                className={`w-4 h-4 rounded-full box-border ${
                                    usdtTransfered && ethTransfered
                                        ? 'bg-palette-brown-901'
                                        : 'border border-palette-gray-700'
                                }  flex justify-center items-center`}
                            >
                                {usdtTransfered && ethTransfered && (
                                    <FaCheck className=" text-palette-black-700" />
                                )}
                            </div>
                            <div
                                className={classNames({
                                    'text-palette-brown-901 underline':
                                        step === 1,
                                })}
                            >
                                Bitso to MetaMask
                            </div>
                        </div>
                    </div>
                    <div className="w-[1px] bg-palette-gray-100"></div>
                    {step === 0 ? (
                        <div className="flex-1 pl-8 pt-2 pb-4">
                            <div className="">
                                <div className="font-medium text-palette-gray-100 tracking-wide">
                                    Bitso homepage:
                                </div>
                                <a
                                    href="https://bitso.com/register?ref=klxyd"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-4 text-palette-brown-901 font-medium text-sm tracking-wide"
                                >
                                    https://bitso.com/
                                </a>
                            </div>{' '}
                            <div className="mt-6 flex flex-col gap-4">
                                <div className="font-medium text-palette-gray-100 tracking-wide">
                                    Learn how to fund your Bitso account
                                    step-by-step with our manual:
                                </div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://igniite.fra1.digitaloceanspaces.com/2-%20HOW%20TO%20FUND%20YOUR%20BITSO%20ACCOUNT.pdf"
                                    className="text-palette-brown-901 font-medium text-sm tracking-wide"
                                >
                                    HOW TO FUND YOUR BITSO ACCOUNT
                                </a>
                            </div>
                            <div className="flex flex-row mt-7">
                                <div className="flex flex-row gap-3 items-center">
                                    <Checkbox
                                        name="bitsoAccountFunds"
                                        checked={bitsoAccountFunds}
                                        onChange={handleUpdate}
                                    />
                                    <div className=" font-medium text-palette-gray-700 text-sm">
                                        I already have funds on my Bitso account
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex-1 pl-8 pt-2 pb-4">
                            {' '}
                            <div className="">
                                <div className="font-medium text-palette-gray-100 tracking-wide">
                                    Bitso homepage:
                                </div>
                                <a
                                    href="https://bitso.com/register?ref=klxyd"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-4 text-palette-brown-901 font-medium text-sm tracking-wide"
                                >
                                    https://bitso.com/
                                </a>
                            </div>{' '}
                            <div className="mt-6 flex flex-col gap-4">
                                <div className="font-medium text-palette-gray-100 tracking-wide">
                                    Learn how to transfer USDT and ETH from
                                    Bitso to Metamask account step-by-step with
                                    our manual:
                                </div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://igniite.fra1.digitaloceanspaces.com/5-%20HOW%20TO%20TRANSFER%20USDT%20FROM%20BITSO%20TO%20METAMASK.pdf"
                                    className="text-palette-brown-901 font-medium text-sm tracking-wide"
                                >
                                    HOW TO TRANSFER USDT FROM BITSO TO METAMASK
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://igniite.fra1.digitaloceanspaces.com/6-%20HOW%20TO%20TRANSFER%20ETH%20FROM%20BITSO%20TO%20METAMASK.pdf"
                                    className="text-palette-brown-901 font-medium text-sm tracking-wide"
                                >
                                    HOW TO TRANSFER ETH FROM BITSO TO METAMASK
                                </a>
                            </div>
                            <div className="flex flex-row mt-7">
                                <div className="flex flex-row gap-3 items-center">
                                    <Checkbox
                                        name="usdtTransfered"
                                        checked={usdtTransfered}
                                        onChange={handleUpdate}
                                    />
                                    <div className=" font-medium text-palette-gray-700 text-sm">
                                        I already have USDT on my MetaMask
                                        account
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="flex flex-row gap-3 items-center">
                                    <Checkbox
                                        name="ethTransfered"
                                        checked={ethTransfered}
                                        onChange={handleUpdate}
                                    />
                                    <div className=" font-medium text-palette-gray-700 text-sm">
                                        I already have ETH on my MetaMask
                                        account
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const steps = [
    {
        title: 'Metamask',
        content: MintRequirementMetamask,
    },
    {
        title: 'Account',
        content: MintRequirementAccount,
    },
    {
        title: 'Funds',
        content: MintRequirementFunds,
    },
]

const MintRequirementsSteps = ({ current }) => {
    useEffect(() => {}, [])

    return (
        <Steps current={current} labelPlacement="vertical">
            {steps.map((v) => (
                <Step key={v.title} title={v.title} />
            ))}
        </Steps>
    )
}

const MintRequirementsModal = ({ visible, onClose, onFinish }) => {
    const currentAccount = useRef(null)

    const [current, setCurrent] = useState(0)

    const next = () => setCurrent((state) => state + 1)
    const prev = () => setCurrent((state) => state - 1)

    const Content = steps[current].content

    const { account } = useEthers()
    const { loginMetamask } = useAuthW3()

    useRequirementsUpdateAccount(account)
    useSaveBackUp()
    useResetMintRequirements(account)
    useLoadBackup(account)

    const {
        account: address,
        createdBitso,
        bitsoAccountFunds,
        usdtTransfered,
        ethTransfered,
    } = useMintRequirement()

    const handleMoveToStepFromSaved = useCallback(() => {
        if (currentAccount.current !== address) {
            currentAccount.current = address

            let currentStep = 0
            if (address) currentStep = 1
            if (createdBitso) currentStep = 2

            setCurrent(currentStep)
        }
    }, [
        address,
        createdBitso,
        bitsoAccountFunds,
        usdtTransfered,
        ethTransfered,
    ])

    useEffect(() => {
        handleMoveToStepFromSaved()
    }, [address])

    let propsContents = {}

    if (current === 0) {
        propsContents = {
            account,
            loginMetamask,
        }
    }
    if (current === 1) {
        propsContents = { createdBitso }
    }
    if (current === 2) {
        propsContents = { bitsoAccountFunds, usdtTransfered, ethTransfered }
    }

    const enableContinueButton = useMemo(() => {
        if (current === 0) {
            return !!account
        }
        if (current === 1) {
            return createdBitso
        }
        if (current === 2) {
            return bitsoAccountFunds && bitsoAccountFunds && ethTransfered
        }
        return true
    }, [
        account,
        current,
        createdBitso,
        bitsoAccountFunds,
        usdtTransfered,
        ethTransfered,
    ])

    return (
        <Modal
            centered
            closable={true}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={'1050px'}
        >
            <div className="px-8">
                <div className=" font-oxanium  font-medium text-lg text-palette-gray-100 mb-10">
                    Before starting the mint process, make sure you have
                    completed the next requirements
                </div>
                <MintRequirementsSteps current={current} />
                <div className="bg-palette-black-700 p-8 mt-6">
                    <Content {...propsContents} />
                </div>{' '}
                <div className="flex flex-row gap-2 mt-6">
                    {current > 0 && (
                        <Button
                            variant="light"
                            className="w-32 rounded-none"
                            onClick={() => prev()}
                        >
                            BACK
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button
                            disabled={!enableContinueButton}
                            variant="light"
                            className="w-32 rounded-none ml-auto"
                            onClick={() => next()}
                        >
                            CONTINUE
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button
                            disabled={!enableContinueButton}
                            className="w-32 rounded-none ml-auto"
                            onClick={onFinish}
                        >
                            Mint
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default MintRequirementsModal
