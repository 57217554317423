import axiosInstance from 'src/store/services/axiosConfig'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

export const saveKeys = createAsyncThunk(
    'dashboard/savekeys',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('bybit/keys/', {
                name: payload.name,
                key: payload.key,
                secret: payload.secret,
                trades: payload.maxTrades,
                highCapLev: payload.btcEth,
                lowCapLev: payload.altcoin,
                maxRisk: payload.riskPorc,
                linkedAccount: payload.linkedAccount,
            })

            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const getKeys = createAsyncThunk(
    'dashboard/getkeys',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('bybit/keys/')
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getBybitTrades = createAsyncThunk(
    'dashboard/getBybitTrades',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const id = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.get(`bybit/getusertrades/${id}`)
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getBybitOrders = createAsyncThunk(
    'dashboard/getBybitOrders',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const id = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.get(`bybit/getorders/${id}`)

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getActiveTrades = createAsyncThunk(
    'dashboard/getActiveTrades',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const id = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.get(`bybit/trade/${id}`)
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const subscribePosition = createAsyncThunk(
    'dashboard/subscribePosition',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const idkey = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.post('bybit/subscribemain/', {
                symbol: payload.symbol,
                side: payload.side,
                id: idkey,
            })

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsubscribePosition = createAsyncThunk(
    'dashboard/unsubscribePosition',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.delete(
                `bybit/unsubscribeposition/${payload}`
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getKeyEmisors = createAsyncThunk(
    'dashboard/keyemisors',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(`bybit/config/keysemisor`)
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteClosePosition = createAsyncThunk(
    'dashboard/deleteClosePosition',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const idkey = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.delete(
                `bybit/deleteClosePosition/`,
                {
                    symbol: payload.symbol,
                    id: idkey,
                }
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAccountBalance = createAsyncThunk(
    'dashboard/accountBalance',
    async (payload, { rejectWithValue, getState }) => {
        const state = getState()
        const idkey = state.dashboardReducer.activekeysbybit
        try {
            const res = await axiosInstance.get(
                `bybit/getwalletbalance/${idkey}`
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const setSubscribed = createAction('dashboard/setSubscribed')
export const resetSubscribed = createAction('dashboard/resetSubscribed')

export const subscribingBybit = createAsyncThunk(
    'dashboard/subscribingBybit',
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/subscribemain/', {
                id: payload,
            })

            dispatch(setSubscribed(payload))
            return res.data
        } catch (error) {
            if (!error.response) throw error

            if (error.response.data.status === 'duplicated thread')
                dispatch(setSubscribed(payload))
            if (error.response.data.status !== 'duplicated thread') {
                dispatch(resetSubscribed(payload))
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsubscribingBybit = createAsyncThunk(
    'dashboard/unsubscribingBybit',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.delete('bybit/unsubscribemain/')
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const subscribechildren = createAsyncThunk(
    'dashboard/subscribeClients',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('bybit/subscribechildren/')
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsubscribeChildren = createAsyncThunk(
    'dashboard/unsubscribeClients',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.delete(
                'bybit/unsubscribechildaccount/'
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateKey = createAction('dashboard/updateKey')

export const subscribe = createAsyncThunk(
    'dashboard/subscribekeybykey',
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/subscribekeybykey/', {
                id: payload,
            })
            dispatch(updateKey(payload))
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsuscribe = createAsyncThunk(
    'dashboard/unsubscribekeybykey',
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/unsubscribekeybykey/', {
                id: payload,
            })
            dispatch(updateKey(payload))
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const subscribeAll = createAsyncThunk(
    'dashboard/subscribekeyall',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.post('bybit/subscribekeyall/')
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsuscribeAll = createAsyncThunk(
    'dashboard/unsubscribekeyall',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.post('bybit/unsubscribekeyall/')
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const setActiveAccount = createAction('dashboard/setActiveAccount')
