/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import { useFetchSalesStaff, useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'
import Button from '../../Buttons/Button'
import { BiPlus } from 'react-icons/bi'
import { SellerCard } from './SellerCard'
import AddSellerModal from 'src/views/AdminView/AddSellerModal'

export const SellersAdmin = () => {
  useFetchSalesStaff()
  const { salesStaffMembers } = useStaffState()
  const [visible, setVisible] = useState(false)

  return (
      <div className="w-full h-full pl-14">
          <AddSellerModal visible={visible} onClose={() => setVisible(false)} />
          <div className="flex justify-between mb-5">
              <div className="text-palette-gray-100 font-maven text-xl font-normal">
                  SELLERS
              </div>
              <Button
                  // size={buttonSize}
                  onClick={() => setVisible(true)}
                  variant="light"
                  className="w-full lg:w-auto text-sm lg:text-lg"
              >
                  <BiPlus />
                  <div className="font-semibold text-base px-3">ADD</div>
              </Button>
          </div>
          <div className="grid grid-cols-3 gap-4">
              {salesStaffMembers.map((seller) => (
                  <SellerCard
                      key={`seller-${seller.id}`}
                      name={seller.name}
                      customers={seller.clients.length}
                      initialBalance={seller.clients.reduce((acc, client) => {
                          return acc + Number(client.initialBalance)
                      }, 0)}
                      roi={seller.clients.reduce((acc, client) => {
                          return acc + Number(client.pnl)
                      }, 0)}
                  />
              ))}
          </div>
      </div>
  )
}
