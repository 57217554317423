import React from 'react'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'

const TermsView = (props) => {
    return (
        <NoLoggedLayout enableNavbar>
            <div className="w-full max-w-[800px] mx-auto flex flex-col xl:flex-row gap-4 pb-8 bg-palette-black-725 rounded-md px-7 py-9">
                <div>
                    <div className="font-oxanium text-3xl font-semibold">
                        IGNIITE
                    </div>
                    <div className="mt-4 mb-3 font-oxanium text-lg italic">
                        Terms & Conditions
                    </div>
                    <p className="mb-0 text-sm">
                        By accessing this website, and continuing to access
                        Igniite, we assume you indicate your consent to be bound
                        by these terms and conditions in full. If you do not
                        agree with the terms and conditions, you should not
                        access or use Igniite.
                    </p>
                    <p className="text-sm">
                        {' '}
                        You agree to review this agreement periodically to
                        ensure awareness of any changes and modifications. Your
                        continued access to and/or use of Igniite shall be
                        indicate your consent and conclusive acceptance of the
                        modified agreement.
                    </p>
                    <div className="font-oxanium text-lg italic mt-12">
                        {' '}
                        CHANGE OF SERVICES AND TERMINATION OF THE USER AGREEMENT
                    </div>
                    <p className="mt-2 text-sm">
                        You acknowledge and agree that IGNIITE may modify part
                        or all of its Services at any moment without prior
                        notice. We reserve the right to modify any terms and
                        conditions of this agreement and/or any features of
                        Igniite from time to time without prior notice. Such
                        modification shall be effective and replace any previous
                        terms and conditions of this agreement and/or any
                        previous features of Igniite immediately upon posting of
                        the modified agreement and/or features of the Igniite
                        without notifying you.
                    </p>
                    <p className="text-sm mt-8">
                        By using the service provided by us, you also
                        acknowledge and agree that:
                    </p>{' '}
                    <p className="text-sm ">
                        {' '}
                        You are at least eighteen (18) years old, and have full
                        capacity to accept the User Agreement, to use the
                        Services and to enter into a transaction involving
                        Digital Asset and are acting on your own behalf.
                    </p>
                    <p className="text-sm">
                        {' '}
                        You may use the services only if you are not a person
                        barred from receiving such services under the applicable
                        legislation.
                    </p>
                    <p className="text-sm">
                        {' '}
                        We reserve the right to change, alter and/or modify the
                        design, impose limits on certain features and/or any
                        other aspects or features of the Igniite from time to
                        time. We reserves the right to discontinue all or part
                        of the Igniite from time to time without notifying you.
                    </p>
                    <p className="text-sm">
                        {' '}
                        You may use the services only in compliance with this
                        agreement and all applicable local, national, and
                        international laws, rules and regulations.
                    </p>
                    <p className="text-sm">
                        You may not use our Services for any unlawful purposes
                        or in furtherance of illegal activities including
                        breaching the financial markets legislation.
                        International Users agree to comply with all local laws
                        regarding financial markets and online conduct. You may
                        not aggregate, display or make derivative use of the
                        Content, nor may use any data mining, robots, spiders,
                        or similar data gathering and extraction tools for any
                        purpose unless expressly permitted by us in writing.
                    </p>
                    <div className="mt-12 mb-3 font-oxanium text-lg italic">
                        ACCOUNT
                    </div>
                    <p className="text-sm">
                        {' '}
                        The Accounts are restricted to individuals, legal
                        entities are not allowed.
                    </p>
                    <p className="text-sm">
                        It is forbidden to use or attempt to use another{' '}
                        {"user's"} account, service or system without
                        authorization from the Company, or to create a false
                        identity on the Service.
                    </p>
                    <p className="text-sm">
                        Depending on the country of residence or the country
                        from which User accesses the Website and/or Services,
                        User may not be able to use the Website or Services.
                        User is responsible to comply with the rules and
                        regulations applicable in his country of residence
                        and/or the country from which the User accesses the
                        Website.
                    </p>
                    <p className="text-sm">
                        User acknowledges and agrees to provide IGNIITE with
                        current, accurate and complete information during the
                        registration and verification process. Additionally,
                        User agrees to keep IGNIITE updated if any of the
                        provided information changes.
                    </p>
                    <p className="text-sm">
                        {' '}
                        IGNIITE reserves the right to refuse Accounts without
                        reason and to not further process the verification.
                    </p>
                    <p className="text-sm">
                        {' '}
                        The Account and the Services are not allowed to be used
                        in relation with criminal activities of any sort,
                        including but not limited to money laundering, terrorist
                        financing, fraud or any other criminal or illegal
                        activity.
                    </p>
                    <p className="text-sm">
                        You may only use the Services on your own account and
                        discretion and at your personal risk.
                    </p>
                    <p className="text-sm">
                        You will be fully responsible for any use of your
                        account and for any actions taken by using your account
                    </p>
                    <div className="mt-12 mb-3 font-oxanium text-lg italic">
                        REGISTRATION
                    </div>
                    <p className="text-sm">
                        {' '}
                        If You want to use our Services, You have to create an
                        account with Igniite by registering on the Igniite site.
                        During the registration process, You may be asked for
                        some information about your identity and are required to
                        provide accurate, current and complete information.
                    </p>
                    <p className="text-sm">
                        You acknowledge and agree that you are responsible for
                        safely storing any password, for choosing complex and
                        difficult to decipher passwords and to ensure
                        inviolability of the Account.
                    </p>
                    <p className="text-sm">
                        We will not be liable for any loss or damage arising
                        from your failure to comply with the above.
                    </p>
                    <p className="text-sm">
                        IGNIITE will never ask FOR MORE FUNDS, OR FOR the User
                        to provide authentication data (passwords and/or two
                        factor authentication) other than when logging in to the
                        Account. User must never respond to a request to provide
                        this data, even if the request appears to originate from
                        IGNIITE.
                    </p>
                    <p className="text-sm">
                        In the event that User knows or should know that his
                        authentication data has been stolen or may be misused,
                        User must contact IGNIITE immediately.
                    </p>
                    <div className="mt-7 mb-3 font-oxanium text-lg italic">
                        USING IGNIITE
                    </div>
                    <p className="text-sm">
                        For allowing Igniite to use artificial intelligence or
                        indicators to send Trade Signals to the Exchange on
                        behalf of the User, the User is required to state his
                        API-Keys for his account on the relevant Exchange
                        (“Exchange Account”). With the provision of the API-Keys
                        the User enables Igniite to execute trades via the
                        User’s Exchange Account. The Users have to enable the
                        API-Keys to check the Balance of the User on the
                        relevant Exchange and to place buy- and/or sell-orders
                        on the relevant Exchange. Igniite assumes no liability
                        for the User’s Exchange Account allowing Bots to trade
                        on behalf of the User or the user manually trading.
                    </p>
                    <p className="text-sm">IGNIITE resumes no liability for:</p>
                    <ol type="A" className="ml-8 list-[upper-alpha]">
                        <li>
                            Igniite’s indicators or artificial intelligence.
                        </li>
                        <li>
                            Third party trading bots connected to the exchange
                            by the user and/or the user manually trading.
                        </li>
                        <li>
                            Potential fees for executing trades asked by the
                            Exchange or fees for accessing the Exchange. These
                            fee are paid by the User.
                        </li>
                    </ol>
                    <div className="mt-10 mb-3 font-oxanium text-lg italic">
                        TRADING
                    </div>
                    <p className="text-sm">
                        The User expressly states that he is aware of the risks
                        of trading in financial markets and/or Cryptocurrencies.
                        The User declares that he never risks more money in
                        Igniite than he can afford to lose. Igniite expressly
                        recommends consulting a qualified financial advisor
                        before using Igniite. The User understands trading in
                        Cryptocurrencies as highly volatile asset classes
                        involves the risk of a partial or complete loss of the
                        User’s funds. Any content on the Igniite Website shall
                        not considered a trading strategy or any other type of
                        financial advice.
                    </p>
                    <p className="text-sm">
                        The Website allows Igniite to submit trading
                        instructions to the linked Exchange on the Users behalf.
                    </p>
                    <p className="text-sm">
                        IGNIITE may apply minimum and maximum order amounts
                        which can vary for each trading pair.
                    </p>
                    <p className="text-sm">
                        {' '}
                        User is aware of the risk that the execution price of a
                        market order may differ significantly
                    </p>
                    <p className="text-sm">
                        User acknowledges and accepts that orders are not
                        guaranteed executable at the price or amount specified
                        and that they are subject to (automated) cancellation.
                    </p>
                    <div className="mt-12 mb-7 font-oxanium text-lg italic">
                        {' '}
                        IGNIITE NFT’S, PRICES, AND PAYMENT
                    </div>
                    <p className="text-sm mb-0">
                        Access to the Igniite service will be provided by an
                        Igniite NFT. There will be a limited initial supply of
                        Igniite NFT’s.
                    </p>
                    <p className="text-sm">
                        The initial supply of Igniite NFT’s are known as
                        GENESIS.
                    </p>
                    <div className="mt-12 mb-2 font-oxanium text-lg font-semibold">
                        GENESIS
                    </div>
                    <div>
                        Availability: <strong>222 Genesis NFT’s</strong>
                    </div>
                    <div>
                        Price: The genesis NFT will have a minting price of{' '}
                        <strong>$2000 USD.</strong>
                    </div>
                    <p className="text-sm mt-10 mb-0">
                        {' '}
                        Subsequent Igniite NFT’s after the Genesis minting, will
                        have a higher minting price and a higher success fee.{' '}
                    </p>
                    <div>
                        {' '}
                        Prices and availability of the subsequent NFT’s will be
                        announced when ready.
                    </div>
                    <div className="mt-12 mb-3 font-oxanium text-lg italic">
                        ACCESS TO IGNIITE VIA THE NFT
                    </div>
                    <p className="text-sm">
                        Access to Igniite is accepted by the use of the Igniite
                        NFT that has to be staked into the Igniite system.
                        Continued use of Igniite is accepted by staking the
                        Igniite NFT and by paying the agreed success fees on a
                        weekly basis.
                    </p>
                    <div className="mt-12 mb-3 font-oxanium text-lg italic">
                        {' '}
                        SUCCESS FEES
                    </div>
                    <p className="text-sm">
                        {' '}
                        The User agrees to pay Igniite 30% of the profit
                        generated by the Igniite system to the users account,
                        and will be payable to Igniite on a weekly basis.
                    </p>
                    <p className="text-sm">
                        Failure to pay the success fee amount on the due date
                        will limit the ability to receive more trades via
                        Igniite until the fees have been paid in full.
                    </p>
                    <p className="text-sm">
                        As a collection of the debt, if full payment is not
                        received by the 21st day after the due date, the User’s
                        NFT will be removed and placed into the secondary market
                        and the user will not have access to Igniite, the
                        Igniite NFT or any of Igniite’s systems or benefits.
                    </p>
                    <p className="text-sm">
                        {' '}
                        You agree to pay all amounts due on your Account upon
                        demand, and/or (ii) you agree that Igniite may either
                        terminate or suspend your account which will result in
                        loss of access to the Igniite NFT.
                    </p>
                    <div className="mt-16 mb-3 font-oxanium text-lg italic">
                        Termination of Services by Igniite.
                    </div>
                    <p className="text-sm">
                        {' '}
                        Except as set forth above, the Igniite NFT minting cost
                        and the Igniite success Fees shall be non-refundable.
                    </p>
                    <p className="text-sm">
                        {' '}
                        If you have materially breached any provision of the
                        Terms, or if Igniite is required to do so by law (e.g.,
                        where the provision of the Application, the Software or
                        the Services is, or becomes, unlawful), Igniite has the
                        right to, immediately and without notice, suspend, or
                        terminate any Services provided to you. You agree that
                        all terminations for cause shall be made in{' '}
                        {"Igniite's"} sole discretion and that Igniite shall not
                        be liable to you or any third party for any termination
                        of your Account.
                    </p>
                    <div className="mt-12 mb-3 font-oxanium text-lg italic">
                        Limited Liability
                    </div>
                    <p className="text-sm">
                        {' '}
                        IGNIITE does not provide any investment advice in
                        connection with the Services. IGNIITE may provide
                        information on the offered price, range, and volatility
                        of the Digital Asset that are offered via the Website.
                        However, this must not be considered as investment
                        advice but as information or a personal opinion from
                        which no rights can be derived. User is in any event
                        responsible for its Orders.
                    </p>
                    <p className="text-sm">
                        User acknowledges and agrees that, although IGNIITE
                        intends to provide accurate and timely information on
                        the Site, the content may not always be entirely
                        accurate, complete or current and may also include
                        technical inaccuracies or typographical errors.
                    </p>
                    <p className="text-sm mt-4">
                        {' '}
                        By agreeing to use the artificial intelligence and
                        indicators that Igniite provides, You have expressly
                        agreed for Igniite to trade on your behalf, on the
                        understanding that Igniite is is not providing financial
                        advice.
                    </p>
                    <p className="text-sm">
                        You also acknowledge that have done your due diligence,
                        understand the risks and are not trading more than you
                        are willing to risk.
                    </p>
                </div>
            </div>
        </NoLoggedLayout>
    )
}

export default TermsView
