import React, { useState } from 'react'
import { Checkbox, Select } from 'antd'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import { InlineWidget } from 'react-calendly'
import Button from './../components/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { Option } from 'antd/lib/mentions'

const Terms = ({ onClickConfirm }) => {
    const { t, i18n } = useTranslation('help')
    const [{ minutes, laptop, smartphone, cardId }, setCheckBox] = useState({
        minutes: false,
        laptop: false,
        smartphone: false,
        cardId: false,
    })

    const handleUpdate = ({ target }) => {
        const { name, checked } = target
        setCheckBox((state) => ({ ...state, [name]: checked }))
    }
    return (
        <div className="w-full max-w-[850px] mx-auto bg-palette-black-725 rounded-md flex flex-col py-6 px-12 border border-palette-gray-600">
            <div className="xl:h-[34px] flex items-end ">
                <span className="text-lg xl:text-2xl font-bold font-oxanium text-palette-brown-901 tracking-widest">
                    {t('title')}
                </span>
                <div className="ml-auto">
                    <Select
                        size="small"
                        defaultValue="all"
                        className="text-palette-brown-901 py-1"
                        onChange={(v) => {
                            i18n.changeLanguage(v)
                            // setSelectedAccount(v)
                        }}
                        bordered={false}
                        value={i18n.language.split('-')[0]}
                    >
                        <Option value="es">ES</Option>{' '}
                        <Option value="en">EN</Option>{' '}
                    </Select>
                </div>
            </div>
            <div className="mt-6 text-sm">{t('description1')}</div>
            <div className="text-sm mt-5">{t('description2')}</div>
            <div className="text-sm mt-3">{t('requirement')}</div>
            <div className="mt-4 ml-4">
                <div className="flex flex-col gap-2 text-sm">
                    <div className="flex flex-row gap-3 items-center">
                        <Checkbox
                            name="minutes"
                            checked={minutes}
                            onChange={handleUpdate}
                        />
                        <div className=" font-medium text-palette-gray-700 text-xs">
                            {t('requirements.minutes')}
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <Checkbox
                            name="laptop"
                            checked={laptop}
                            onChange={handleUpdate}
                        />
                        <div className=" font-medium text-palette-gray-700 text-xs">
                            {t('requirements.pc')}
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <Checkbox
                            name="smartphone"
                            checked={smartphone}
                            onChange={handleUpdate}
                        />
                        <div className=" font-medium text-palette-gray-700 text-xs">
                            {t('requirements.phone')}
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <Checkbox
                            name="cardId"
                            checked={cardId}
                            onChange={handleUpdate}
                        />
                        <div className=" font-medium text-palette-gray-700 text-xs">
                            {t('requirements.id')}
                        </div>
                    </div>
                </div>
            </div>
            <Button
                disabled={!(minutes && laptop && smartphone && cardId)}
                className="mt-8"
                variant="light"
                onClick={onClickConfirm}
            >
                {t('Confirm')}
            </Button>
        </div>
    )
}

const HelpView = (props) => {
    const [confirmed, setConfirmed] = useState(false)
    return (
        <NoLoggedLayout enableNavbar>
            <div className="w-full max-w-[1250px] mx-auto flex flex-col gap-4 pb-8">
                {!confirmed ? (
                    <Terms onClickConfirm={() => setConfirmed(true)} />
                ) : (
                    <div className="w-full">
                        <InlineWidget
                            url="https://calendly.com/igniitesupport/igniite-setup?background_color=1c1c1c&text_color=747474&primary_color=8d793f"
                            styles={{ height: '700px' }}
                        />
                    </div>
                )}
            </div>
        </NoLoggedLayout>
    )
}

export default HelpView
