import dayjs from 'dayjs'
import React from 'react'
import { formatNumberToCurrency, formatNumberToPercentage } from 'src/utils/formatNumber'

export const SellerDetails = ({details}) => {
    return (
        <div className="flex flex-row divide-x divide-palette-gray-700 mb-4">
            <div className="flex-1">
                <div className="text-[#B2955F] pr-4 font-oxanium font-medium text-2xl tracking-wide whitespace-nowrap mb-4">
                    {details.name}
                </div>
                <div className="text-palette-gray-900 font-semibold text-xs">
                    START DATE:{' '}
                    <span className="text-palette-white font-semibold">
                        {dayjs(details.dateJoined).format('DD/MM/YYYY')}
                    </span>
                </div>
            </div>
            <div className="flex-1 pl-4">
                <div className="text-palette-gray-900 font-medium text-base mb-4">
                    Customers
                </div>
                <div className="text-palette-white text-lg font-semibold font-oxanium">
                    {details.customers?.length}
                </div>
            </div>
            <div className="flex-1 pl-4">
                <div className="text-palette-gray-900 font-medium text-base mb-4">
                    Estimated Balance
                </div>
                <div className="text-palette-white font-oxanium font-medium text-2xl">
                    {formatNumberToCurrency(details.estimatedBalance)}
                </div>
            </div>
            <div className="flex-1 pl-4 h-1/2 mb-0 mt-auto">
                <div className="font-medium text-xs text-palette-gray-900 mb-2">
                    Initial Balance
                </div>
                <div className="font-oxanium text-lg font-medium">
                    {formatNumberToCurrency(details.initialBalance)}
                </div>
            </div>
            {/* <div className="flex-1 pl-4 h-1/2 mb-0 mt-auto">
                <div className="font-medium text-xs text-palette-gray-900 mb-2">
                    In Orders
                </div>
                <div className="font-oxanium text-lg font-medium">
                    {formatNumberToCurrency(details.inOrders)}
                </div>
            </div> */}
            <div className="flex-1 pl-4 h-1/2 mb-0 mt-auto">
                <div className="font-medium text-xs text-palette-gray-900 mb-2">
                    Total ROI (PNL)
                </div>
                <div className="font-oxanium text-lg font-medium text-[#B2955F]">
                    {formatNumberToCurrency(details.totalROI)}
                </div>
            </div>
            <div className="flex-1 pl-4 h-1/2 mb-0 mt-auto">
                <div className="font-medium text-xs text-palette-gray-900 mb-3">
                    % ROI
                </div>
                <div className=" px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-xs text-palette-beigde-100">
                    {formatNumberToPercentage(details.roi)}
                </div>
            </div>
        </div>
    )
}
