import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'

export const getTradesByUserId = createAsyncThunk(
    'admin/referral/gettradesbyuserid',
    async (idUser, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/referral/gettradesbyuserid/${idUser}`
            )

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
