import { sortTextAlpha } from 'src/utils/tableAntsUtils'
import CustomTable from './CustomTable'
import { sortNumberAnt } from './../../utils/tableAntsUtils'

// import { useState } from 'react'

const TableUsers = ({ dataSource, hideColumns = [], onSelect }) => {
    const columns = [
        {
            title: 'Trader',
            dataIndex: 'trader',
            key: 'trade',
            sorter: sortTextAlpha('trader'),
            enableSearch: true,
        },

        {
            title: 'Account Name',
            dataIndex: 'keyname',
            key: 'keyname',
            sorter: sortTextAlpha('user'),
        },
        {
            title: 'Open Trades',
            dataIndex: 'openTrades',
            key: 'openTrades',
            sorter: sortNumberAnt('openTrades'),
        },
        {
            title: 'Open Orders',
            dataIndex: 'openOrders',
            key: 'openOrders',
            sorter: sortNumberAnt('openOrders'),
        },
    ].map((v) => ({ ...v, align: 'center' }))

    return (
        <CustomTable
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        onSelect(record)
                    }, // click row
                }
            }}
            columns={columns.filter((v) => !hideColumns.includes(v.dataIndex))}
            dataSource={dataSource}
        />
    )
}

export default TableUsers
