/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import CustomersAdmin from 'src/components/AdminComponents/CustomersAdmin'
import EpochsAdmin from 'src/components/AdminComponents/EpochsAdmin'
import { DashboardAdmin } from 'src/components/AdminComponents/DashboardAdmin'
import { MailAdmin } from 'src/components/AdminComponents/MailAdmin'
import { SellersAdmin } from 'src/components/AdminComponents/SellersAdmin'
import TradesAdmin from 'src/components/AdminComponents/TradesAdmin'
import { VerticalTabs } from 'src/components/AdminComponents/VerticalTabs'
const Admin = () => {
   const adminPages = [
       { index: 'dashboard', component: DashboardAdmin },
       { index: 'sellers', component: SellersAdmin },
       { index: 'customers', component: CustomersAdmin },
       { index: 'trades', component: TradesAdmin },
       { index: 'epochs', component: EpochsAdmin },
       { index: 'mail', component: MailAdmin },
   ]

    const [selectedTab, setSelectedTab] = useState(adminPages[0].index)

    return (
        <div className="flex flex-row h-[calc(100vh_-_108px)]">
            <VerticalTabs onSelect={(index) => setSelectedTab(index)} />
            <div className="flex-1 flex w-full h-full relative">
                <div className='absolute inset-0 flex'>
                {adminPages.map((p) => {
                    return (
                        <div
                            key={`page-${p.index}`}
                            className={`flex-1 ${
                                p.index === selectedTab ? 'block' : 'hidden'
                            }`}
                        >
                            <p.component />
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    )
}

export default Admin
