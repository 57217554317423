import React from 'react'
import { EpochCardCustomer } from '../EpochsComponents/EpochCardCustomer'

export const CustomerEpochsGrid = ({ clientEpochDetails }) => {
    
    return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 auto-cols-fr my-6">
            {clientEpochDetails?.map((epoch, index) => (
                <EpochCardCustomer key={index} epoch={epoch} />
            ))}
        </div>
    )
}
