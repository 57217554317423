/* eslint-disable no-unused-vars */
// import React, { useEffect, useState, useCallback } from 'react'
import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    accountman,
    allday,
    card,
    celphone,
    compounding,
    ethleft,
    ethright,
    laptop,
    laptop2,
    support,
    trading,
} from 'src/assets/images'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import axios from 'axios'

import graphic from 'src/assets/images/landing/4-GRAPHIC.png'
import { InlineWidget } from 'react-calendly'

// import { useNavigate } from 'react-router-dom'

const RenderImageCard = ({ icon, title, text }) => {
    return (
        <div className="flex flex-col gap-4 xl:gap-6 lg:flex-row w-full">
            <div className="w-20 lg:w-16 mx-auto lg:mx-0">
                <img className="w-full h-auto" src={icon} />
            </div>
            <div className="w-full lg:w-10/12 mt-2 lg:mt-0">
                <div className="text-lg lg:text-sm xl:text-lg font-bold text-white text-center lg:text-left">
                    {title}
                </div>
                <p className="mt-3 lg:mt-0 text-base lg:text-xs xl:text-base font-normal text-palette-gray-700 text-justify">
                    {text}
                </p>
            </div>
        </div>
    )
}

const Section1 = () => {
    return (
        <div className="flex h-[calc(100vh_-_56px)] w-full max-w-[1500px]  mx-auto">
            <div className="flex flex-col w-full justify-center items-center my-auto">
                <div className="w-full md:w-1/2 lg:w-72 px-12 xl:px-0">
                    <img className="w-full h-auto" src={card} />
                </div>
            </div>
        </div>
    )
}

const Section2 = () => {
    return (
        <div className="xl:h-[80vh] flex flex-col lg:flex-row w-full max-w-[1500px] mx-auto px-0 md:px-12 lg:px-0  py-8 md:py-12 lg:py-0">
            <div className="w-full lg:w-1/2 mx-auto flex xl:pt-20">
                <div className="w-full xl:-mx-[10%]">
                    <img src={laptop} className="w-full h-auto " />
                </div>
            </div>
            <div className="flex flex-col w-full lg:w-1/2 mt-8 xl:mt-16 gap-8 xl:gap-16">
                <div className="tracking-widest text-xl sm:text-[32px] lg:text-[40px]  text-center lg:text-left text-palette-brown-700 font-semibold opacity-70">
                    WHAT IS IGNIITE?
                </div>
                <div className="text-left w-full xl:w-8/12 lg:text-lg xl:text-2xl xl:pr-7 tracking-wide text-palette-gray-700">
                    <p>
                        Igniite is an artificial intelligence (AI) system
                        developed by Ingniite engineers to automate and mirror
                        trades for its users.
                    </p>
                    <p>
                        These trades are made by Igniites trading team using
                        different strategies and indicators.
                    </p>
                    <p>Igniite only makes profits when you make profits.</p>
                </div>
            </div>
        </div>
    )
}
const Section3 = () => {
    return (
        <div className="flex flex-col lg:flex-row w-full max-w-[1500px] my-12 xl:my-32 mx-auto py-8 xl:py-0 px-0 md:px-12 lg:px-0 ">
            <div className="w-full lg:w-1/2 lg:mt-16 flex flex-col xl:items-end">
                <div className="tracking-widest text-xl lg:text-[40px] sm:text-[32px] text-center lg:text-left text-palette-brown-700 font-semibold opacity-70">
                    WHY CHOOSE IGNIITE?
                </div>
                <div className=" mt-8 lg:mt-16 flex flex-col gap-8 lg:gap-12">
                    <RenderImageCard
                        key={0}
                        icon={trading}
                        title={'TRADING'}
                        text={
                            'Igniites trading team has created and backtested multiple and different strategies, giving us the opportunity to trade in all types of markets with different strategies and indicators.'
                        }
                    />
                    <RenderImageCard
                        key={1}
                        icon={accountman}
                        title={'ACCOUNT MANAGEMENT'}
                        text={
                            'Account management is one of the most important things in trading, which is why Igniite has given you default settings that have been tested to maintain a healthy trading account with the correct risk management.'
                        }
                    />
                    <RenderImageCard
                        key={2}
                        icon={accountman}
                        title={'HANDS OFF'}
                        text={
                            'Using the artificial intelligence created by us you will NOT have to worry about doing anything in your trades but check them at any time you please to see what is happening in your account.'
                        }
                    />
                    <RenderImageCard
                        key={3}
                        icon={accountman}
                        title={'222 IGNIITE MEMBERS'}
                        text={
                            'There will only be a tight group of 222 individuals, making it a very exclusive service.'
                        }
                    />
                </div>
            </div>
            <div className="w-full lg:w-1/2 flex mt-6 lg:mt-0 lg:pb-4 xl:pb-10">
                <div className="w-full mt-auto">
                    <img className="w-full h-auto" src={celphone} />
                </div>
            </div>
        </div>
    )
}
const Section4 = () => {
    const navigate = useNavigate()
    return (
        <div className="relative flex px-4 py-36 -mx-5 xl:mx-auto w-auto xl:w-full customshadow bg-ethback bg-no-repeat bg-cover">
            <div className="hidden lg:block absolute left-0 top-[20%] xl:top-0 w-[20%] xl:w-auto">
                <div className="w-full">
                    <img className="w-full h-auto" src={ethleft} />
                </div>
            </div>
            <div className="flex flex-col lg:w-[768px] lg:px-20 xl:px-0 items-center mx-auto">
                <div className="tracking-widest text-xl lg:text-2xl xl:text-[40px] sm:text-[32px] text-center xl:text-left text-palette-brown-700 font-semibold opacity-70">
                    YOUR MONEY CANNOT BE ACCESSED OR TOUCHED BY US
                </div>
                <p className="text-palette-gray-700 tracking-wide text-lg lg:text-base xl:text-2xl text-center mt-16 lg:mt-8 xl:mt-16 opacity-100">
                    You will always be in full control of your funds to do what
                    you please with them whenever you please.
                </p>
                <a onClick={() => navigate('/learn')} className="text-base">
                    {'DISCOVER HOW 🡪'}
                </a>
            </div>
            <div className="hidden lg:block absolute right-0 top-[20%] xl:top-0 w-[20%] xl:w-auto">
                <div className="w-full">
                    <img className="w-full h-auto" src={ethright} />
                </div>
            </div>
        </div>
    )
}
const Section5 = () => {
    return (
        <div className="flex flex-col lg:flex-row py-16 xl:pt-0 xl:mt-10 2xl:w-[1400px] mx-auto pb-28 px-0 md:px-12 lg:px-0">
            <div className="w-full lg:w-1/2 flex flex-col relative text-left">
                <div className="flex flex-col xl:mt-20 lg:mr-6 xl:mr-10">
                    <div className="flex flex-row">
                        <div className="w-2 h-full bg-gradient-to-t from-palette-brown-901"></div>
                        <div className="flex-1 py-3 px-4 bg-[#0D0D0D]">
                            <div className="relative tracking-widest font-oxanium font-semibold lg:text-2xl xl:text-[40px] sm:text-[32px] text-[25px] md:text-[32px] text-palette-brown-700 w-full opacity-70">
                                WHAT DO WE OFFER?
                            </div>
                            <p className="text-palette-gray-700 tracking-wide text-lg lg:text-base xl:text-2xl font-normal pt-2 opacity-100">
                                Igniite is offering returns in profits from 5 to
                                10% monthly using the strategies and indicators
                                created by our trading team and mirroring them
                                to its users. As well as using automated trades
                                created by our indicators.
                            </p>
                            <p className="text-palette-gray-700 tracking-wide text-lg font-normal pt-2 opacity-100">
                                Subject to change depending on market
                                conditions. Past results are not guarantees of
                                future results.
                            </p>
                        </div>
                    </div>
                    <div className="h-2 w-full  bg-gradient-to-r from-palette-brown-901"></div>
                </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col gap-8 lg:gap-12 items-end mt-8 xl:mt-32 xl:ml-10">
                <RenderImageCard
                    key={4}
                    icon={compounding}
                    title={'COMPOUNDING'}
                    text={
                        'Each profitable transaction will be reinvested to keep generating more funds and earn higher profits.'
                    }
                />
                <RenderImageCard
                    key={5}
                    icon={allday}
                    title={'TRADE 24/7'}
                    text={
                        'With our trading team being worldwide we will always have someone searching for trades and opportunities for us to generate profit, as well as our automated indicators that run nonstop.'
                    }
                />
                <RenderImageCard
                    key={6}
                    icon={support}
                    title={'CONCIERGE TYPE SUPPORT'}
                    text={
                        'With a limit of 222 users for Igniite, making it a highly exclusive product and service.'
                    }
                />
            </div>
        </div>
    )
}
const Section6 = () => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-row relative w-full  xl:mx-auto">
            <div className="absolute right-0 w-[69%] xl:-top-[37%]">
                <img src={graphic} className="w-full h-auto" />
            </div>
            <div className="relative flex flex-col w-full lg:w-[768px] items-center mx-auto">
                <div className="w-full md:w-[80%] lg:w-full">
                    <img className="w-full h-auto" src={laptop2} />
                </div>
                <div className="tracking-widest font-oxanium font-semibold lg:text-[40px] sm:text-[32px] text-[25px] md:text-[32px] text-palette-grey-700 mt-3 xl:mt-32 text-center w-full opacity-70">
                    {"LET'S GET STARTED"}
                </div>
                <button
                    onClick={() => navigate('/mint')}
                    className="mt-8 mb-6 h-[68px] w-80 rounded-sm text-palette-brown-901 bg-palette-black-700 text-sm font-semibold border border-palette-brown-901 flex justify-center items-center"
                >
                    GO TO MINT
                </button>
                <a
                    onClick={() => navigate('/learn')}
                    className="text-base pt-16 text-palette-gray-700"
                >
                    {'DISCOVER WHAT DO YOU NEED TO BEGGIN 🡪'}
                </a>
            </div>
        </div>
    )
}

const LandingView = (props) => {
    // const [selected, setSelected] = useState(1)
    // const [loading, setLoading] = useState(false)

    return (
        <NoLoggedLayout enableNavbar>
            <div></div>

            <div className=" flex flex-col w-full mx-auto -mt-16 max-w-[1920px] pb-20">
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
            </div>
        </NoLoggedLayout>
    )
}

export default LandingView
