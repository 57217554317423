// import useInterval from './../../../hooks/useInterval'
import { deleteArrayPosition } from 'src/utils/arrays'
import { addToArray } from '../../../utils/arrays'
import dayjs from 'dayjs'
import { createReducer } from '@reduxjs/toolkit'
import {
    registerPaymentMethod,
    getUserPaymentPending,
    getPaymentOrderHistory,
    getPaymentsMethods,
    updateTime,
    setOverDue,
    removePaymentMethod,
} from './payments.actions'

export const initialState = {
    activePayment: null,
    orderHistory: [],
    methods: [],
    overdue: false,
    lastTimeShowedModalPayment: null,
}

const PAYMENT_STATUS = {
    PENDING: 'PENDING',
}

const paymentsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setOverDue, (state) => {
            return {
                ...state,
                overdue: true,
                lastTimeShowedModalPayment: undefined,
            }
        })
        .addCase(updateTime, (state, { payload }) => {
            return {
                ...state,
                lastTimeShowedModalPayment: dayjs().toString(),
            }
        })
        .addCase(registerPaymentMethod.fulfilled, (state, { payload }) => {
            return { ...state, methods: addToArray(state.methods, payload) }
        })
        .addCase(getPaymentsMethods.fulfilled, (state, { payload }) => {
            state.methods = payload ?? []
        })
        .addCase(removePaymentMethod, (state, { payload }) => {
            const index = state.methods.findIndex((v) => v.id === payload)
            return {
                ...state,
                methods: deleteArrayPosition(state.methods, index),
            }
        })
        .addCase(getUserPaymentPending.fulfilled, (state, { payload }) => {
            if (payload) {
                let newState = { ...state }
                const overdueDate = dayjs(payload.paymentOrderCreate).add(
                    2,
                    'days'
                )

                console.log({ payload })
                const overdue = dayjs() >= overdueDate
                const activePayment = {
                    ...payload,
                    overdueDate: overdueDate.toString(),
                }

                if (overdue && !state.overdue)
                    newState = {
                        ...newState,
                        overdue: true,
                        lastTimeShowedModalPayment: undefined,
                    }
                if (!overdue && state.overdue)
                    newState = {
                        ...newState,
                        overdue: false,
                    }
                if (
                    !state.activePayment ||
                    (state.activePayment &&
                        JSON.stringify(state.activePayment) !==
                            JSON.stringify(activePayment))
                )
                    newState = {
                        ...newState,
                        activePayment,
                    }
                return newState
            }
            return {
                ...state,
                activePayment: undefined,
                overdue: false,
                lastTimeShowedModalPayment: null,
            }
        })
        .addCase(getPaymentOrderHistory.fulfilled, (state, { payload }) => {
            state.orderHistory = payload.filter(
                (v) => v.status.toUpperCase() !== PAYMENT_STATUS.PENDING
            )
        })
})

export default paymentsReducer
