/* eslint-disable no-unused-vars */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
// import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { WEEKLY } from 'src/constants/temporality.constants'
import { DAILY, YEARLY } from '../../constants/temporality.constants'
import { useEffect, useState, useRef } from 'react'
import {
    useHistoryTradesState,
    useGetTradesHistory,
} from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import Temporality from '../Temporality'
import { useTemporality2 } from 'src/hooks/useTemporality2'
import { useDebounceState } from 'src/hooks/useDebounceState'
import { MobileTemporality } from '../Temporality/MobileTemporality'
import dayjs from 'dayjs'
import { formatNumberToCurrency, formatNumberToPercentage } from 'src/utils/formatNumber'

// create Chart JS Plugin to render % growth at the top right corner
const growthCounter = {
    id: 'growthCounter',
    beforeDraw(chart, args, options) {
        const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
            scales: { x, y },
        } = chart

        ctx.save()
        ctx.font = '500 18px Oxanium'
        ctx.fillStyle = '#8D793F'
        ctx.fillText(options.text, 120, 15)
        ctx.restore()
    },
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    growthCounter
)

const DashboardGrowthChart = ({ graphicData = [] }) => {
    const { epochDetails } = useHistoryTradesState()
    const [growthPerc, setGrowthPerc] = useState(0)
    const chartRef = useRef(null)
    const [chart, setChart] = useState(null)

    const [rangeDate, setDateRange] = useState([])

    const { setTemporality, ...temporalityData } = useTemporality2(
        YEARLY,
        rangeDate
    )
    
    const { range, temporality } = useDebounceState(temporalityData, 500)
    useGetTradesHistory({
        temporality,
        rangeDate: {
            startDate: range.startDateString,
            endDate: range.endDateString,
        },
    })

    // change growth Percentage each time data changes
    useEffect(() => {
        setChart(chartRef.current)
        if (graphicData.length > 0) {
            setGrowthPerc(graphicData[graphicData.length - 1].growth)
        } else {
            setGrowthPerc(0)
        }
    }, [graphicData])

    const labels = graphicData.map((v) => {
        const date = dayjs(v.date)

        if (temporality === WEEKLY) {
            return date.format('dddd')
        }
        if (temporality === DAILY) {
            return date.format('hh')            
        }
        return date.format('MMMM D')
    })

    const growth = graphicData.map((v) => {
        return v.growth
    })

    const data = {
        labels,
        datasets: [
            {
                label: 'Growth',
                fill: true,
                // draw a point if data is a single value
                pointRadius: graphicData.length === 1 ? 4 : 0,
                data: growth,
                borderColor: 'rgb(141, 121, 63)',
                backgroundColor: 'rgba(141,121,63, 0.2)',
            },
        ],
    }

    const options = {
        maintainAspectRatio: false,
        // responsive: true,
        plugins: {
            centerText: false,
            title: {
                display: true,
                align: 'start',
                color: '#BFBFBF',
                text: 'GROWTH',
                padding: {
                    bottom: 50,
                },
                font: {
                    family: "'Maven Pro', 'sans-serif'",
                    size: 16,
                    weight: 400,
                },
            },
            growthCounter: {
                // text: growthPerc.toFixed(3).toString() + '%',
                text: formatNumberToPercentage(growthPerc),
                // moves the perc to the left and right
                off: 40,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                // center a point if data is a single value
                offset: graphicData.length === 1 && true,
            },
            y: {
                stacked: true,
                position: 'right',
                grid: {
                    drawBorder: true,
                    color: 'rgba(116, 116, 116, 0.2)',
                    opacity: '0.5',
                },
            },
        },
    }
    return (
        <div className="col-span-12 md:col-span-9 p-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <div className="flex flex-wrap md:divide-x md:divide-palette-black-725 md:flex-row justify-between border-b border-palette-black-725 mb-5">
                <div className="flex items-center w-full justify-center h-24 border-b mb-3 space-y-3 md:border-b-0 border-palette-black-725 md:flex-1 flex-col">
                    <div className="text-palette-gray-700 md:mr-auto text-xs font-medium">
                        INITIAL BALANCE
                    </div>
                    <div className="text-palette-gray-100 md:mr-auto font-oxanium font-medium text-lg md:text-2xl whitespace-nowrap	">
                        {/* {formatter.format(epochDetails.initialBalance)}{' '} */}
                        {formatNumberToCurrency(epochDetails.initialBalance)}{' '}
                        <span className="text-base">USD</span>
                    </div>
                </div>
                <div className="w-1/2 flex justify-center items-center md:items-start h-24 space-y-3 md:flex-1 flex-col pl-5">
                    <div className="text-palette-gray-700 text-xs font-medium">
                        ACTUAL BALANCE
                    </div>
                    <div className="text-palette-gray-100 font-oxanium font-medium text-lg md:text-2xl whitespace-nowrap">
                        {formatNumberToCurrency(epochDetails.actualBalance)}{' '}
                        <span className="text-base">USD</span>
                    </div>
                </div>

                <div className="w-1/2 flex justify-center items-center md:items-start h-24 space-y-3 md:flex-1 flex-col pl-5">
                    <div className="text-palette-gray-700 text-xs font-medium">
                        ROI YTD
                    </div>
                    <div className="text-palette-gold-100 font-oxanium font-medium text-lg md:text-2xl whitespace-nowrap">
                        {formatNumberToCurrency(epochDetails.pnl)}{' '}                        
                        <span className="text-palette-gold-100 font-oxanium text-base">
                            USD
                        </span>
                        <div className=" px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-xs text-palette-beigde-100">
                            {/* {Number(epochDetails.pnlPerc.toFixed(3))} % */}
                            {formatNumberToPercentage(epochDetails.pnlPerc)}
                        </div>
                    </div>
                </div>
                
                <div className="w-1/2 flex justify-center items-center md:items-start h-24 space-y-3 md:flex-1 flex-col pl-5">
                    <div className="text-palette-gray-700 text-xs font-medium">
                        ROI CURRENT PERIOD
                    </div>
                    <div className="text-palette-gold-100 font-oxanium font-medium text-lg md:text-2xl whitespace-nowrap">
                        {formatNumberToCurrency(epochDetails.pnlCurrentPeriod)}{' '}                        
                        <span className="text-palette-gold-100 font-oxanium text-base">
                            USD
                        </span>
                        <div className=" px-3 py-1 w-fit rounded-full bg-palette-brown-800 font-oxanium text-xs text-palette-beigde-100">
                            {/* {Number(epochDetails.pnlPerc.toFixed(3))} % */}
                            {formatNumberToPercentage(epochDetails.pnlPercCurrentPeriod)}
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden md:flex md:justify-items-end">
                <Temporality
                    rangeDate={rangeDate}
                    selected={temporalityData.temporality}
                    onSelect={(v, range) => {
                        setTemporality(v)
                        if (range) setDateRange(range)
                    }}
                />
            </div>
            <div className="md:hidden">
                <MobileTemporality
                    rangeDate={rangeDate}
                    selected={temporalityData.temporality}
                    onSelect={(v, range) => {
                        setTemporality(v)
                        if (range) setDateRange(range)
                    }}
                />
            </div>
            <div className="flex items-center h-80">
                <Line ref={chartRef} options={options} data={data} />
            </div>
        </div>
    )
}

DashboardGrowthChart.propTypes = {}

export default DashboardGrowthChart
