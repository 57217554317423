import React, {useState} from 'react'

export const VerticalTabs = ({
    tabs = [
        { index: 'dashboard', label: 'DASHBOARD' },
        { index: 'sellers', label: 'SELLERS' },
        { index: 'customers', label: 'CUSTOMERS' },
        { index: 'trades', label: 'TRADES' },
        { index: 'epochs', label: 'EPOCHS' },
        { index: 'mail', label: 'MAIL' },
    ],
    onSelect,
}) => {
    const [selected, setSelected] = useState(tabs[0]?.index)
    return (
        <div className="border-r border-[#444444] pr-8 w-fit h-full space-y-9 pt-5 ">
            {tabs?.map((tab, index) => {
                return (
                    <div
                        key={`tab-${tab.index}`}
                        className={`relative w-[138px] max-w-[130px] pr-2 pb-1 break-words cursor-pointer text-sm h-12 flex items-center pl-8 font-semibold text-[#878787] ${
                            selected === tab.index
                                ? "before:absolute bg-[#D3B895]/20 before:content-[''] before:h-full before:w-1 before:top-0 before:left-0 before:bg-palette-brown-901"
                                : 'font-semibold'
                        }`}
                        onClick={() => {
                            setSelected(tab.index)
                            onSelect(tab.index)
                        }}
                    >
                        {tab.label}
                    </div>
                )
            })}
        </div>
    )
}
